import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {config} from "../Config";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {withAppCtx} from "../Helpers/withAppCtx";
import qs from "qs";
import axios from "axios";
import {confirmAlert} from "react-confirm-alert";
import {delSetContextData, postApi, setContextData, toFormData, getLogo} from "../Helpers/Helper";
import BlockUi from 'react-block-ui';


//
const ActivateAccount = ({ context }) => {




    //
    const navigate = useNavigate();
    const {register, setValue, getValues, handleSubmit, formState: { errors }} = useForm();
    const [is_loading, setIsLoading] = useState(false);
    const [section_title, setSectionTitle] = useState(null);
    const [section_info, setSectionInfo] = useState(null);
    const [activation_url, setSectionUrl] = useState(null);








    //
    useEffect(() => {


        //
        let lang = context.getLang();
        console.log("---lang: ", lang);
        console.log("---ActivateAccount Context: ", context.data.reg);



        // Seccion que si esta auth redirecciona
        if (context.getAuth()){ navigate("/account");}



        //
        let {reg} = context.data;


        //
        if (reg && reg.id){

            //
            var str_section_title, str_section_info, str_section_url;

            //
            if ( reg.type === "reg-custmr"){
                //
                str_section_title = "Activacion de Cuenta";
                str_section_info = (<><strong> {reg.contact_name} </strong> te hemos enviado un codigo de activacion al correo <strong> {reg.email} </strong></>)
                str_section_url = "/activate";
            }
            //
            else if ( reg.type === "reco-custmr" ){
                //
                str_section_title = "Recuperar Cuenta";
                str_section_info = (<><strong> {reg.contact_name} </strong> te hemos enviado un codigo de recuperacion al correo <strong> {reg.email} </strong></>)
                str_section_url = "/recover-activate";
            }

            // set defaults
            setValue('id', reg.id);
            setValue('request_identifier', context.visitorId);
            //
            setSectionTitle(str_section_title);
            setSectionInfo(str_section_info);
            setSectionUrl(str_section_url);

            console.log(str_section_title);

            //
            document.title = config.AppName + " " + str_section_title;

        } else {
            navigate("/");
        }

    }, []);








    //
    const onSubmit = (data) => {
        //
        if (is_loading){
            console.log("is loading");
            return;
        }
        //
        setIsLoading(true);
        //
        postApi("/auth" + activation_url, toFormData(data), function(success, data){
            //
            if (success){
                //
                let {reg} = context.data;
                //
                if ( reg.type === "reg-custmr" ){
                    //
                    delSetContextData(context, "reg", 'auth', data)
                    window.location.href = "/account";
                }
                //
                else if ( reg.type === "reco-custmr" ){
                    //
                    setContextData(context, 'activation_code', data.activation_code);
                    //
                    setTimeout(function(){
                        navigate("/update-password");
                    }, 500);
                }
            }
            //
            setIsLoading(false);
        });
    };



    const postRequestActivationCode = () => {
        //
        if (is_loading){
            console.log("is loading");
            return;
        }
        //
        setIsLoading(true);
        //
        let {reg} = context.data;
        //
        let postData = {
            request_identifier: getValues('request_identifier'),
            email: reg.email
        }
        //
        axios.post(config.api_url + "/auth/req-ac", postData)
            .then(response => {
                //
                setIsLoading(false);
                //
                console.log("---register data: ", response.data);
                if (response.data && response.data.id){
                    alert("Codigo Enviado Exitosamente");
                }
                //
                else if (response.data.error){
                    alert(response.data.error);
                }
                //
                else {
                    alert("Error al intentar activar la cuenta");
                }
            })
            .catch(error => {
                console.error(error);
                //
                setIsLoading(false);
                //
                alert("Error al intentar activar la cuenta");
            });
    }

    const sendActivationCode = () => {
        //
        confirmAlert({
            message: 'Solicitar nuevo codigo de activacion?',
            buttons: [
                {
                    label: 'Si',
                    onClick: function(){
                        postRequestActivationCode();
                    }
                },
                {
                    label: 'No',
                    onClick: function(){

                    }
                }
            ]
        });
    }



    //
    return (<>


        <NavLink className="login-back" to="/"><span className="mdi mdi-chevron-left"></span></NavLink>

        <div className="text-center mt-5 mb-5 login-main-left-header pt-5">
            <NavLink to="/"><img src={process.env.PUBLIC_URL + getLogo()} className="sect-logo"
                                 alt="LOGO" /></NavLink>
            <h5 className="mt-0 mb-3"> {section_title} </h5>
            <p> {section_info} <NavLink to="/create-account">Cambiar</NavLink></p>
        </div>

        <BlockUi blocking={is_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> sending... </div>)}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">
                <div className="form-group otp-form">
                    <div className="row">
                        <div className="col-12 pl-12 pr-2 text-center">
                            <label className="">Codigo de Activacion</label>
                            <input type="number" style={{fontSize:"30px", fontWeight:"bold", textAlign:"center"}}
                                   className="form-control no-spinners input-lg" name="activation_code" id="activation_code"
                                   placeholder=""
                                   {...register('activation_code', { required: 'introduce el codigo de activacion' })}
                            />
                            {errors.activation_code && <label className="error">{errors.activation_code.message}</label>}
                        </div>
                    </div>
                </div>


                <button type="submit" className="btn btn-primary btn-block btn-lg mt-4">Activar Cuenta</button>

            </form>
        </BlockUi>

        <div className="text-center mt-5">
            <p className="light-gray">No recibiste codigo de activacion? <a href="#" onClick={sendActivationCode}>Reenviar</a>
            </p>
        </div>


    </>)

}


export default withAppCtx(ActivateAccount);