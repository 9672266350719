import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//
const RatingStars = ({ initialValue, viewOnly, onChangeValue }) => {


    //
    const [selectedValue, setSelectedValue] = useState(0);




    // initialValue
    useEffect(() => {

        //
        setSelectedValue(initialValue);

    }, [initialValue]);



    //
    const handleClick = (event) => {
        const value = parseInt(event.target.dataset.value);
        setSelectedValue(value);
        onChangeValue(value);
    };



    let rateNoPointer = (viewOnly) && "rate-no-pointer";

    //
    return (<>
        <a href="#!" style={{fontSize:"20px"}} className={"text-warning " + rateNoPointer}>
        <span
            className={selectedValue >= 1 ? "fas fa-star" : "far fa-star"}
            data-value="1"
            onClick={viewOnly ? null : handleClick}
        ></span>
            <span
                className={selectedValue >= 2 ? "fas fa-star" : "far fa-star"}
                data-value="2"
                onClick={viewOnly ? null : handleClick}
            ></span>
            <span
                className={selectedValue >= 3 ? "fas fa-star" : "far fa-star"}
                data-value="3"
                onClick={viewOnly ? null : handleClick}
            ></span>
            <span
                className={selectedValue >= 4 ? "fas fa-star" : "far fa-star"}
                data-value="4"
                onClick={viewOnly ? null : handleClick}
            ></span>
            <span
                className={selectedValue >= 5 ? "fas fa-star" : "far fa-star"}
                data-value="5"
                onClick={viewOnly ? null : handleClick}
            ></span>
        </a>
    </>)

}


export default RatingStars;