import React, { useEffect } from 'react';
import {config} from "../Config";
import { NavLink } from 'react-router-dom';




const Notfound = ({ context, geo }) => {



    //
    useEffect(() => {
        //
        document.title = config.AppName + " - Sin Conexion";
    }, []);







    //
    return (<>


        <div className="mt-5 py-5 text-center">


                <h2 className="text-dark"><span className="fa fa-info-circle"></span> Sin Conexion </h2>


                <div style={{display: "flex", alignItems: "center"}}>

                    <img src={process.env.PUBLIC_URL + '/images/gpig-small.png'} style={{marginRight: "10px"}} />
                    <p>
                        Parece que no cuentas con conexion a internet
                    </p>
                </div>

            <a href="#!" onClick={() => window.location.reload()}  className="btn btn-primary"><i
                className="icofont-refresh"></i> Reintentar
            </a>



        </div>




    </>)


}


export default Notfound;