import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import RatingStars from "./RatingStars";
import {validateRating} from "../../Helpers/Helper";

//
const CalificarOrder = ({ onSubmitData }) => {


    //
    const {register, handleSubmit, setFocus, trigger, getValues, setValue, formState: { errors }} = useForm();



    //
    useEffect(() => {

        //
        setValue("calific", 0);

    }, []);




    //
    const handleClick = (value) => {
        //
        setValue("calific", value);
        trigger();
    };


    //
    const onSubmit = (data) => {
        //console.log("---- submit data: ", data);
        if  (onSubmitData){
            onSubmitData(data);
        }
    };






    //
    return (<>


        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">



            <div className="row">
                <div className="col-12">
                    <div className="form-group">

                        <label className=""> Calificacion:  </label>

                        {/*{getValues("calific")}*/}
                        &emsp;

                        <RatingStars
                            initialValue={0}
                            onChangeValue={handleClick}
                        />

                        <input type={"hidden"} className="form-control" name="calific" id="calific"
                               {...register('calific', {
                                   required: "Se require la calificacion",
                                   validate: validateRating
                               })}
                        />
                        <br />
                        {errors.calific && <label className="error">{errors.calific.message}</label>}

                    </div>
                </div>
            </div>



            <div className="row">
                <div className="col-12">
                    <div className="form-group">

                        <label className="">Comentarios (opcional)</label>
                        <textarea className="form-control" name="comments" id="comments"
                               minLength="2" maxLength="128" placeholder="" autoComplete="off"
                               {...register('comments', { })}
                        />

                    </div>
                </div>
            </div>




            <div className="mt-2 mb-2">
                <div className="row">
                    <div className="col-12 pl-2 text-center">

                        <button type="submit" className="btn btn-primary"> Enviar </button>


                    </div>
                </div>
            </div>


        </form>



    </>)

}


export default CalificarOrder;