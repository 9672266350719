import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

//
const CancelOrder = ({ onSubmitData }) => {


    //
    const {register, handleSubmit, setFocus, formState: { errors }} = useForm();

    //
    useEffect(() => {
        //
        setFocus("status_notes");
    }, []);




    //
    const onSubmit = (data) => {
        //console.log("---- submit data: ", data);
        if  (onSubmitData){
            onSubmitData(data);
        }
    };




    //
    return (<>


        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">

            <div className="row">
                <div className="col-12">
                    <div className="form-group">

                        <label className="">Motivo de Cancelacion</label>
                        <textarea className="form-control" name="status_notes" id="status_notes"
                               data-rule-required="true" data-rule-minlength="2"
                               data-rule-maxlength="128" placeholder="" autoComplete="off"
                               {...register('status_notes', { required: 'introduce el motivo' })}
                        />

                        {errors.status_notes && <label className="error">{errors.status_notes.message}</label>}
                    </div>
                </div>
            </div>




            <div className="mt-2 mb-2">
                <div className="row">
                    <div className="col-12 pl-2 text-center">

                        <button type="submit" className="btn btn-primary"> Cancelar </button>


                    </div>
                </div>
            </div>


        </form>



    </>)

}


export default CancelOrder;