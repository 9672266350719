import React, { useContext, useImperativeHandle, useState, useRef, useEffect } from 'react';
import axios from "axios";
import {AppCtx} from "../AppCtx";
import {LoadingImg} from "./Helper";


//
const PageScroller = React.forwardRef(({num_recs, url, initialQueryString, isDivElement, prevent_first_load, zero_records_text, no_more_records_text, onFetchReady, parseScrollContent, is_reverse, containerClassName, containerPadding, listClassName, height}, ref) => {


    //
    const [is_loading, setLoading] = useState(false);
    const [total_records, setTotalRecords] = useState(0);
    const [arr_records, setArrRecords] = useState([]);
    const [qty_recs, setQuantytyRecords] = useState(num_recs || 6);
    const [next_recs, setNextRecs] = useState(1);
    const [reached_end, setEndReached] = useState(false);
    const [query_string_value, setQueryStringValue] = useState(initialQueryString || '');
    //
    const [fetch_count, setFetchCount] = useState(0);
    const [last_scroll_height, setLastScrollHeight] = useState(0);
    const [scroll_to_bottom, setScrollToBottom] = useState(true);
    const [scroll_to_top, setScrollToTop] = useState(true);



    //
    const scrollRef = useRef(null);
    //
    const app_ctx = useContext(AppCtx);



    // Function to reset the component's state
    const resetItems = () => {
        console.log("doing reset...");
        setArrRecords([]);
        setNextRecs(1);
        setEndReached(false);
        setFetchCount(fetch_count + 1);
        //
        if (is_reverse){
            setScrollToBottom(true);
        } else {
            setScrollToTop(true);
        }
    };



    //
    const appendItem = (new_item) => {
        //
        if (is_reverse){
            setArrRecords(prevItems => [...prevItems, new_item]);
        } else {
            setArrRecords(prevItems => [new_item, ...prevItems]);
        }
        //
        setTimeout(function(){
            //
            if (is_reverse){
                scrollToBottom();
            } else {
                scrollToTop();
            }
        }, 50);
    }


    const setQueryString = (qs_value) => {
        console.log("setting query string values: ", qs_value);
        //
        setQueryStringValue(qs_value);
    };



    //
    useImperativeHandle(ref, () => ({
        setQueryString: setQueryString,
        resetItems: resetItems,
        appendItem: appendItem
    }));



    // Función para obtener la nueva lista de elementos con axios.get
    const fetchData = async () => {

        if (is_loading) {
            console.log("esta cargando, espera .... ");
            return;
        }

        try {

            //console.log("...fetching data ....");

            //
            setLoading(true);


            //
            let options = {}

            //
            let { auth } = app_ctx.data;
            options.headers = {
                'Authorization': auth.token,
                'Utype': auth.sale_type_id
            }


            //
            let query_string = "?s=" + next_recs + "&n=" + qty_recs;
            //
            if (query_string_value){
                query_string += query_string_value;
            }


            //
            options.timeout = 5000;
            const response = await axios.get(url + query_string, options);



            //
            const newItems = response.data.data;
            const total_records = response.data.total_records;
            if (newItems && newItems.length){
                //
                if (is_reverse){
                    setArrRecords((prevItems) => [...newItems.reverse(), ...prevItems]);
                } else {
                    setArrRecords((prevItems) => [...prevItems, ...newItems]);
                }


                if (total_records>0)
                    setTotalRecords(total_records);

                //
                setNextRecs(response.data.next_start_record);
            } else {
                setEndReached(true)
            }

            //
            setLoading(false);


            //
            setTimeout(function(){

                //
                if (is_reverse){
                    if ( scroll_to_bottom ){
                        scrollToBottom();
                    }
                    else {
                        scrollToDiffHeight();
                    }
                } else {
                    if ( scroll_to_top ){
                        scrollToTop();
                    }
                    else {
                        scrollToDiffHeight();
                    }
                }
            }, 50);



            //
            if (onFetchReady){
                onFetchReady();
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };








    //
    const scrollToTop = () => {
        //
        if (scrollRef && scrollRef.current){
            const scroll_ref_elem = scrollRef.current;
            scroll_ref_elem.scrollTop = 0;
            //
            setScrollToTop(false);
        }
    };
    //
    const scrollToBottom = () => {
        //
        if (scrollRef && scrollRef.current){
            const scroll_ref_elem = scrollRef.current;
            scroll_ref_elem.scrollTop = scroll_ref_elem.scrollHeight;
            //
            setScrollToBottom(false);
        }
    };
    //
    const scrollToDiffHeight = () => {
        //
        if (scrollRef && scrollRef.current){
            const scroll_ref_elem = scrollRef.current;
            console.log("last height: ", last_scroll_height, ", new height: ", scroll_ref_elem.scrollHeight)
            //
            if ( last_scroll_height > 0 ){
                scroll_ref_elem.scrollTop = (scroll_ref_elem.scrollHeight - last_scroll_height);
            }
        }
    };





    useEffect(() => {
        console.log("------------fetch_count: ", fetch_count);

        //
        if ( prevent_first_load && fetch_count === 0 ){

            /* prevent first load do nothing */

        } else {
            fetchData();
        }



    }, [fetch_count]);





    //
    const handleScroll = () => {

        //
        const scroll_ref_elem = scrollRef.current;
        //console.log(scroll_ref_elem.scrollTop, scroll_ref_elem.clientHeight, scroll_ref_elem.scrollHeight)

        //
        if (is_reverse){

            //
            if (scroll_ref_elem.scrollTop === 0 && !is_loading && !reached_end) {
                setLastScrollHeight(scroll_ref_elem.scrollHeight);
                setFetchCount(fetch_count + 1);
            }

        } else {

            //
            let qty_offset = 5;
            let height_reached = (scroll_ref_elem.scrollTop + scroll_ref_elem.clientHeight) + qty_offset;
            //
            if ( height_reached >= scroll_ref_elem.scrollHeight && !is_loading && !reached_end) {
                console.log("has llegadl al fin");
                setFetchCount(fetch_count + 1);
            }
        }

    };



    const getLoadingInfo = () => {
        if (!is_reverse){

            if (is_loading){
                return (
                    <div className="py-3 text-primary text-center"><LoadingImg /> loading records... </div>
                )
            } else {

                if (total_records > 0){

                    //
                    if (reached_end){
                        //
                        return(
                            <div className="py-3 text-secondary text-center"> {(no_more_records_text) ? no_more_records_text : "-- no more records --"} </div>
                        )
                    } else {
                        //
                        /* CARGANDO MAS REGISTROS */
                        //
                        /*
                        return(
                            <div className="py-3 text-secondary text-center"> {(no_more_records_text) ? no_more_records_text : "-- no more records --"} </div>
                        )
                         */
                    }

                } else {

                    //
                    return (
                        <div className="py-3 text-secondary text-center"> {(zero_records_text) ? zero_records_text : "-- no records --"} </div>
                    )
                }

            }

        }
    }


    const getListBody = () => {
        return (
            <>

                { arr_records.map(parseScrollContent) }

                { getLoadingInfo() }


            </>
        )
    }



    return (<>

            {(isDivElement) ? (

                <div className={listClassName || ''}>

                    {getListBody()}

                </div>

            ) : (
                <div className={containerClassName || ''} style={{height:height, padding:containerPadding || 10, backgroundColor:"#fff", overflowY: "auto", border:"1px solid #eee"}} ref={scrollRef} onScroll={handleScroll}>
                    <ul className={listClassName || ''}>

                        {getListBody()}

                    </ul>
                </div>
            )}

        </>
    )



});



export default PageScroller;



