import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {fmtAmount, calcularMontoAndDiscounts, ucFirst, getP} from "../../../Helpers/Helper";
import {NotificationManager} from "react-notifications";
import {withAppCtx} from "../../../Helpers/withAppCtx";
import {config} from "../../../Config";
import BlockUi from "react-block-ui";
import {useNavigate} from "react-router-dom";


const SelectPeriodicidad = ({sel_subscription, onSubmit, onClose}) => {

    //
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, setFocus, trigger, formState: { errors }} = useForm();
    const [cant_meses, setPeriodicidad] = useState('');
    const [selected_periodo, setPeriodo] = useState('');
    const [calc_data, setCalcData] = useState(null);


    //
    useEffect(() => {

        setFocus("cant_meses")


    }, []);




    const localSubmit = (data) => {
        //
        calc_data.cant_meses = parseInt(cant_meses);
        calc_data.selected_periodo = selected_periodo;
        //
        console.log("---onSubmit: ", calc_data);
        onSubmit(calc_data);
    };


    const handleSelectPeriodo = (event) => {
        //
        let evt_targ = event.target;
        //
        setPeriodicidad(evt_targ.value);
        setPeriodo(evt_targ.options[event.target.selectedIndex].getAttribute('data-periodo'));

        //
        let new_calc_data = calcularMontoAndDiscounts(evt_targ.value, sel_subscription.precio);
        setCalcData(new_calc_data);
    };





    //
    return (
        <BlockUi blocking={loading}>
            <div className="row">
                <div className="col-sm-6 col-12">
                    <h5 className={"mt-2"}> Seleccionar Periodo </h5>
                </div>
                <div className="col-sm-6 col-12 text-right">

                    <button type="button" className="btn btn-secondary" onClick={onClose} style={{marginLeft:"10px"}}>
                        <span className="fa fa-times"></span> Salir
                    </button>

                </div>
            </div>

            <hr className="mt-2 mb-4 text-center" />



            <form onSubmit={handleSubmit(localSubmit)} className={"p-3 text-center"}>



                <h4> Plan - {ucFirst(sel_subscription.nombre)} <small>{fmtAmount(sel_subscription.precio)}</small> </h4>


                <div className="form-group mt-4">
                    <select
                        className="form-control input-lg"
                        name="cant_meses"
                        value={cant_meses}
                        id="cant_meses"
                        onChange={handleSelectPeriodo}
                        {...register('cant_meses', {
                            required: 'selecciona el periodo',
                            onChange: handleSelectPeriodo
                        })}
                    >
                        <option value="">--selecciona</option>
                        <option value={1} data-periodo={"Mensual"}>Mensual</option>
                        <option value={3} data-periodo={"Trimestral"}>Trimestral</option>
                        <option value={6} data-periodo={"Semestral"}>Semestral</option>
                        <option value={12} data-periodo={"Anual"}>Anual</option>
                    </select>
                    {errors.cant_meses && <label className="error">{errors.cant_meses.message}</label>}
                </div>


                {(calc_data && calc_data.final_price) ? (
                    <>

                        {(calc_data.orig_price === calc_data.final_price) ? (
                            <div className={"mt-4"} style={{color:"orangered"}}> {selected_periodo} No Aplica Descuento </div>
                        ) : (
                            <>
                            <h5 className={"mt-4"}> Descuento de <span style={{color:"green"}}> %{calc_data.disc_perc} </span> ( Ahorra {fmtAmount(calc_data.disc_amt)} )  </h5>
                            <div className={""}> Antes: <span style={{textDecoration:"line-through", color:"orangered"}}> {fmtAmount(calc_data.orig_price)} </span> </div>
                            </>
                        )}

                        <hr />

                        <h3 className={"mb-5"}> Precio: {fmtAmount(calc_data.final_price)} </h3>

                        <button type="submit" className="btn btn-primary"><span className={"fas fa-arrow-right"}></span> Continuar </button>
                    </>
                ) : null}



            </form>




        </BlockUi>
    )




};



export default withAppCtx(SelectPeriodicidad);