import React, {createContext, useState, useEffect, useContext} from 'react';
import {config} from "./Config";
import axios from "axios";
import {AppCtx} from "./AppCtx";
import {getSeconds} from "./Helpers/Helper";
//
const GeoLocationCtx = createContext();


//
const GeoLocationCtxProvider = ({ children }) => {

    //
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    const context = useContext(AppCtx);


    //
    const gooApiKey = "AIzaSyAnefoaTTKkeSdSfD-s8vP1rkniL3ePBi8";



    //
    const getQponeaLocationInfo = (user_lat, user_lng) => {
        //console.log("---getQponeaLocationInfo: ", user_lat, user_lng);
        //
        axios.post(config.api_url + "/auth/l", {
            lat: user_lat,
            lng: user_lng,
            vid: context.visitorId
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => {
                //console.log("---getQponeaLocationInfo response: ", response.data);
                setLoaded(true);
                setData(response.data);
            })
            .catch(error => {
                getGoogleLocationInfo();
            });
    }


    //
    const getGoogleLocationInfo = () => {
        //console.log("---using google geolocation: ");
        //
        let g_api_url = 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + gooApiKey;
        //
        axios.post(g_api_url)
            .then(response => {
                //console.log("---google geolocation response: ", response.data);
                //
                let location = {};
                location.city = null;
                //
                if (response.data && response.data.location){
                    //console.log(response.data.location);
                    location = response.data.location;
                }
                //
                setLoaded(true);
                setData(location);
            })
            .catch(error => {
                //
                setLoaded(true);
                setData(null);
            });
    }




    //
    const requestLocation = () => {

        //
        let requestedLocation = localStorage.getItem('requestedLocation');

        //
        if(navigator.geolocation && !requestedLocation) {

            //
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
            //
            function successCallback(position) {
                //console.log("*navigator.geolocation.watchPosition changed: ", position.coords.latitude, position.coords.longitude);
                getQponeaLocationInfo(position.coords.latitude, position.coords.longitude);
            }
            //
            function errorCallback(error) {
                //console.log("geolocation denied ", error);
                getQponeaLocationInfo();
            }

            //
            localStorage.setItem('requestedLocation', '1');
        }
        //
        else {
            //console.log("Your browser does not support geolocation.");
            getQponeaLocationInfo();
        }
    };




    /*
    * INITIAL TOKEN CALL
    * */
    useEffect(() => {

        //console.log("---Geolocation context data: ", context);

        //
        requestLocation();

        // actualiza cada 2 mins ( 120 secs )
        setInterval(requestLocation, getSeconds(120));


    }, []);



    //
    if( loaded ){
        //console.log("GeoLocationCtx loaded Ok!");
    } else {
        //console.log("Loading GeoLocationCtx...");
        return (<div>...</div>);
    }


    //
    return <GeoLocationCtx.Provider value={data}>{children}</GeoLocationCtx.Provider>;
};

export { GeoLocationCtx, GeoLocationCtxProvider };
