import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {withAppCtx} from "../../Helpers/withAppCtx";
import {config} from "../../Config";
import {
    arrContains,
    IsCustomerLogged,
    IsStoreLogged,
    postBAuth,
    postCAuth
} from "../../Helpers/Helper";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import Notific from "../../Helpers/Notific";
import {withSocket} from "../../Helpers/withSocket";
import {withNavigate} from "../../Helpers/withNavigate";
import {isMobile} from "react-device-detect";
import CustomModal from "./modals/CustomModal";
import TerminosYCondiciones from "./TerminosYCondiciones";
import PoliticaDePrivacidad from "./PoliticaDePrivacidad";


class PageFooter extends Component {




    //
    constructor(props) {
        super(props);
        this.state = {
            display_notifications: false,
            modal_politicas_privacidad: false,
            modal_terminos_condiciones: false
        };
    }



    //
    componentDidMount() {
        //console.log("---AutoParts header props: ", this.props);
        //
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('click', this.handleOutsideClick);
    }


    //
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('click', this.handleOutsideClick);
    }




    /*
    * Si da click en un lugar que no sea el nc (notificationsContainer)
    * */
    handleOutsideClick = (event) => {
        const prevent_close = document.getElementsByClassName('prev_close');
        //console.log(prevent_close);
        if ( prevent_close && prevent_close[0] && !prevent_close[0].contains(event.target) ) {
            //
            this.setState({
                display_notifications: false
            });
        }
    };
    /*
    * Si presiona Scape
    * */
    handleKeyDown = (event) => {
        if (event.keyCode === 27) {
            //
            this.setState({
                display_notifications: false
            });
        }
    };



    //
    onNotificationsClick = () => {
        //
        let self = this;

        //
        this.setState({
            display_notifications: !this.state.display_notifications
        }, function(){

            //
            if (IsStoreLogged(this.props.context)){
                //
                postBAuth(this.props.context.data.auth, "/n/so", {}, function(success, data){
                    console.log("---post store notification set as openned: ", data);
                    //
                    if (success){
                        //
                        self.props.token.tokenUserData();
                    }
                });
            }
            //
            else if (IsCustomerLogged(this.props.context)){
                //
                postCAuth(this.props.context.data.auth, "/n/so", {}, function(success, data){
                    console.log("---post customers notification set as openned: ", data);
                    //
                    if (success){
                        //
                        self.props.token.tokenUserData();
                    }
                });
            }

        });
    }



    markAsViewed(notification_id){
        //
        let self = this;
        //
        postBAuth(this.props.context.data.auth, "/n/sv", {id: notification_id}, function(success, data){
            console.log("---post notification set as viewed: ", data);
            //
            if (success){
                //
            }
        });
    }



    NotificationsMenu = () => {

        //
        let {userData} = this.props.token;
        //
        let badge_count = null;
        //
        if (userData && userData.cno && userData.cno > 0){
            badge_count = (<>
                <span style={{position:"absolute", top:"-5px", }} className={"badge badge-danger"}> {userData.cno} </span>
            </>)
        }
        //
        return (
            <a href="#!" style={{position:"relative", borderRadius: "20px"}} className="btn btn-sm btn-primary ml-auto mr-1 prev_close" onClick={this.onNotificationsClick}>
                <i className="fas fa-bell"></i> {badge_count}
            </a>
        );
    }



    isMenuActive = (arr_paths) => {
        let {pathname} = this.props.location;
        console.log("-----------isMenuActive: ", pathname, arr_paths, arrContains(arr_paths, pathname) ? 1 : 0);
        //
        if (arrContains(arr_paths, pathname)){
            return "active"
        }
        return '';
    }



    getUsersMenus(){
        //
        if ( this.props.context.data && this.props.context.data.auth && isMobile ){
            //
            let {auth} = this.props.context.data;

            //
            if ( auth.sale_type_id === config.PROD_TYPE_STORE_ID ){
                //
                return (<>
                    <div className="col-4 text-center">
                        <NavLink to={"/account"} className={() => 'f-nav-m-cont text-center ' + this.isMenuActive(["/account"])}>
                            <span className={"fas fa-rocket"}></span> <div>Solicitudes</div>
                        </NavLink>
                    </div>
                    <div className="col-4 text-center">
                        <NavLink to={"/account/subscriptions"} className={() => 'f-nav-m-cont text-center ' + this.isMenuActive(["/account/subscriptions"])}>
                            <span className={"icofont-badge"}></span> <div>Mi Suscripcion</div>
                        </NavLink>
                    </div>
                    <div className="col-4 text-center">
                        <NavLink to={"/account/config-negocio"} className={() => 'f-nav-m-cont text-center ' + this.isMenuActive(["/account/config-negocio"])}>
                            <span className={"fas fa-cog"}></span> <div>Configuracion</div>
                        </NavLink>
                    </div>
                </>)
            } else if ( auth.sale_type_id === config.PROD_TYPE_CUSTOMER_ID ){
                //
                return (<>
                    <div className="col-4 text-center">
                        <NavLink to={"/account"} className={() => 'f-nav-m-cont text-center ' + this.isMenuActive(["/account"])}>
                            <span className={"fas fa-search"}></span> <div>Buscar</div>
                        </NavLink>
                    </div>
                    <div className="col-4 text-center">
                        <NavLink to={"/account/searches"} className={() => 'f-nav-m-cont text-center ' + this.isMenuActive(["/account/searches"])}>
                            <span style={{fontSize:16}} className={"fas fa-list"}></span> <div>Resultados</div>
                        </NavLink>
                    </div>
                    <div className="col-4 text-center">
                        <NavLink to={"/account/subscriptions"} className={() => 'f-nav-m-cont text-center ' + this.isMenuActive(["/account/subscriptions"])}>
                            <span className={"icofont-badge"}></span> <div>Mi Suscripcion</div>
                            {/*<div>Suscripciones</div>*/}
                        </NavLink>
                    </div>
                </>)
            }

        }
        return null;
    }





    //
    render(){


        //
        let auth = null, order = null;
        //
        if (this.props.context.data && this.props.context.data.auth){
            auth = this.props.context.data.auth;
        }

        //
        return(
            <>


                <div className="container mt-5" style={{marginBottom:80}}>
                    <div className="row">
                        <div className={"col-12 text-center"}>
                            <div style={{fontSize:14}}>
                                <NavLink onClick={() => this.setState({modal_politicas_privacidad:true})} className={"text-decoration-underline"}> &raquo; Politica de Privacidad  </NavLink> <small>&emsp;|&emsp;</small> <NavLink onClick={() => this.setState({modal_terminos_condiciones: true})} className={"text-decoration-underline"}> &raquo; Terminos y Condiciones </NavLink>
                            </div>
                        </div>
                    </div>
                </div>




                {(isMobile && auth) ? (
                    <footer className="footer mt-auto py-2 fixed-bottom">
                        <div className="container">
                            <div className="row" id={"navLinksContainer"}>

                                {this.getUsersMenus()}

                            </div>
                        </div>
                    </footer>
                ) : null}





                { (this.props.context.getAuth() &&
                        this.state.display_notifications) &&
                    (
                        <Notific
                            onDataReady={(qty) => {
                                //console.log(qty);
                            }}
                        />
                    )
                }



                <CustomModal
                    showModal={this.state.modal_politicas_privacidad}
                    modalSize={"modal-lg"}
                    modalHeader={true}
                    title={(<div><span className={"icofont-badge"}></span> Política de Privacidad </div>)}
                    content={<PoliticaDePrivacidad />}
                    onClose={() => this.setState({modal_politicas_privacidad: false})}
                />


                <CustomModal
                    showModal={this.state.modal_terminos_condiciones}
                    modalSize={"modal-lg"}
                    modalHeader={true}
                    title={(<div><span className={"icofont-badge"}></span> Terminos y Condiciones </div>)}
                    content={<TerminosYCondiciones />}
                    onClose={() => this.setState({modal_terminos_condiciones: false})}
                />



            </>
        )
    }

}



export default withAppCtx(withAuthCtx(withSocket(withNavigate(PageFooter))));