import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {fmtAmount, getEmail, post, postBAuth, toFormData, ucFirst} from "../../../Helpers/Helper";
import {NotificationManager} from "react-notifications";
import {withAppCtx} from "../../../Helpers/withAppCtx";
import {useStripe, useElements, Elements, CardElement} from '@stripe/react-stripe-js';
import BlockUi from "react-block-ui";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {config} from "../../../Config";
import CustomModal from "../modals/CustomModal";


const OxxoLink = ({app_context, eventLoading, eventCloseModal, eventOpenModalLink, subscription_id, cant_meses, customer_name, customer_email}) => {


    const [submited, setSubmitted] = useState(false);
    //
    const stripe = useStripe();
    const elements = useElements();




    //
    async function oxxoDataReady(pi_cs){

        //
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        //
        const result = await stripe.confirmOxxoPayment(pi_cs);

        // Stripe.js will open a modal to display the OXXO voucher to your customer
        // This async function finishes when the customer closes the modal
        if (result.error) {
            NotificationManager.info(result.error.message);
        }

        //
        eventLoading(false);
        eventCloseModal();
    }


    //
    const postVenta = (paymentMethodId) => {
        //
        let postData = {
            id: subscription_id,
            cant_meses: cant_meses,
            type: 'strp_oxxo',
            payment_method_id: paymentMethodId
        }
        //console.log("---postData 2: ", postData);

        //setLoading(true);
        //NotificationManager.success('Suscripcion exitosa');
        //navigate("/store/account/subscriptions");

        //
        let { auth } = app_context.data;


        //
        axios.post(config.api_url + "/strp/subscribe", postData, {
            headers: {
                'Authorization': auth.token,
                'Utype': auth.sale_type_id
            },
        })
            .then(response => {
                let results = response.data;
                //
                console.log("--- post oxxo subscribe: ", results);

                //
                if ( results && results.oxxo_voucher_url ){
                    //
                    eventOpenModalLink(results.oxxo_voucher_url);
                    //window.open(results.oxxo_voucher_url, "_blank");

                }
                //
                else if ( results && results.payment_results && results.payment_results.requiresAction ){
                    //
                    oxxoDataReady(results.payment_results.clientSecret);
                }
                //
                else if ( results && results.id ){

                    //
                    eventLoading(false);
                    eventCloseModal();
                    NotificationManager.success("Suscrito Ok");


                } else {

                    //
                    eventLoading(false);

                    //
                    let err = (results.error) ? results.error: "No se pudieron obtener los datos";
                    NotificationManager.info(err);

                }

            })
            .catch(error => {
                console.error(error);
                let err = (error) ? error: "Network error or no internet connection";
                NotificationManager.info(err);
                //
                eventLoading(false);
            });

    }


    //
    const handleOxxoLink = async (data) => {
        console.log("---handle stripe payment: ", data);

        //
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        //
        eventLoading(true);

        //
        var billingDetails = {
            name: customer_name,
            email: customer_email
        }

        //
        const result = await stripe.createPaymentMethod({
            type: 'oxxo',
            billing_details: billingDetails
        });
        console.log("---result: ", result);


        //
        if (result.error) {
            //console.log("---card_errors: ", result.error.message);
            NotificationManager.info(result.error.message);
            eventLoading(false);
        }
        //
        else if (result && result.paymentMethod && result.paymentMethod.id){
            //
            postVenta(result.paymentMethod.id);
        }
    };

    //
    return (
        <div className="mt-4 mb-4">

            <a href="#!" onClick={handleOxxoLink} style={{marginLeft:'10px'}} className="btn btn-primary">
                <span className='fa fa-arrow-right'></span> Obtener Voucher
            </a>
        </div>
    );
};






//
const PayOxxo = ({ context, stripePromise, sel_subscription, sel_periodo, onClose }) => {


    //
    const [loading, setLoading] = useState(false);
    const [voucher_modal_link, setVoucherModalLink] = useState(false);
    //
    const [auth_data, setAuthData] = useState(null);




    //
    useEffect(() => {


        console.log("---modal pay stripe oxxo: ", sel_subscription);
        console.log("---PayOxxo: ", context);


        //
        setAuthData(context.data.auth);



    }, []);




    //
    const eventCloseModal = () => {
        onClose();
    }


    //
    const eventOpenModalLink = (modal_link) => {
        setLoading(false);
        setVoucherModalLink(modal_link);
    }


    const handleCloseModal = () => {
        setVoucherModalLink(false);
        onClose();
    }




    //
    const eventLoading = (is_loading) => {
        if (is_loading){
            setLoading(true);
        } else {
            setTimeout(function(){
                setLoading(false);
            }, 1000);
        }

    }


    //
    return (
        <BlockUi blocking={loading}>
            <div className="row">
                <div className="col-sm-6 col-12">
                    <h4 style={{margin:"0", padding:"0"}}>
                        Deposito Oxxo
                    </h4>
                </div>
                <div className="col-sm-6 col-12 text-right">

                    <button type="button" className="btn btn-secondary" onClick={onClose} style={{marginLeft:"10px"}}>
                        <span className="fa fa-times"></span> Salir
                    </button>

                </div>
            </div>

            <hr className="mt-2 mb-4"/>

            <div className="p-4">

                <div className="form-group">
                    <h4>
                        Plan {ucFirst(sel_subscription.nombre)} {ucFirst(sel_periodo.selected_periodo)} <small>{fmtAmount(sel_periodo.final_price)}</small>

                        {(sel_periodo.orig_price === sel_periodo.final_price) ? (
                            <small style={{color:"orangered", fontSize:12}}> ( No Aplica Descuento ) </small>
                        ) : (
                            <small style={{fontSize:14}}><br /> Descuento de <span style={{color:"green"}}> %{sel_periodo.disc_perc} </span> ( Ahorra {fmtAmount(sel_periodo.disc_amt)} )
                                Antes: <span style={{textDecoration:"line-through", color:"orangered"}}> {fmtAmount(sel_periodo.orig_price)} </span>
                            </small>
                        )}

                    </h4>
                </div>

                { (auth_data && auth_data.id) && (
                    <div className="form-group">
                        <label>Depositante:</label>
                        <h4>
                            {auth_data.first_name} {auth_data.last_name} - <small style={{color:"#333"}}> {auth_data.email} </small>
                        </h4>
                    </div>
                )}

                {/* NOTA: Solo se puee usar useStripe y useElements bajo <Elements */}
                {/* https://stackoverflow.com/questions/64109065/stripe-reactjs-could-not-find-elements-context */}

                { (auth_data && auth_data.id) && (
                    <Elements stripe={stripePromise}>
                        <OxxoLink
                            app_context={context}
                            subscription_id={sel_subscription.id}
                            cant_meses={sel_periodo.cant_meses}
                            customer_name={auth_data.first_name + ' ' + auth_data.last_name}
                            customer_email={auth_data.email}
                            eventLoading={eventLoading}
                            eventCloseModal={eventCloseModal}
                            eventOpenModalLink={eventOpenModalLink}
                        />
                    </Elements>
                )}


                <small style={{display:"block"}} className="mt-4 mb-2 text-gray"> Al realizar tu pago mandanos una foto
                    de tu comprobante a <strong>{getEmail()}</strong> </small>

            </div>


            <CustomModal
                modalHeader={true}
                title={"Voucher de Pago"}
                modalSize={"modal-lg"}
                showModal={voucher_modal_link}
                onClose={handleCloseModal}
                content={(
                    <div className="">
                        <iframe src={voucher_modal_link} title="Oxxo Voucher" style={{}} width="100%" height="500px" />
                    </div>
                )}
            />


        </BlockUi>
    )

}


export default withAppCtx(PayOxxo);