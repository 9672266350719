import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {NotificationManager} from "react-notifications";
import InputMask from 'react-input-mask';
import {withAppCtx} from "../../Helpers/withAppCtx";
import {
    DivAlert,
    getLogo,
    IsCustomerLogged,
    postCAuth,
    postApi,
    postPublic,
    mDateFormat,
    setContextData,
    socketSend,
    toFormData
} from "../../Helpers/Helper";
import {config} from "../../Config";
import BlockUi from "react-block-ui";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import SelectLoader from "../../Helpers/SelectLoader";
import AsyncSelect from 'react-select/async';


//
const FormSearchSalidas = ({ context, onBeforeSent, onResults }) => {

    //
    const [loading, setLoading] = useState(false);
    const [is_loading, setIsLoading] = useState(false);
    const [origen_ciudad, setOrigenCiudad] = useState('');
    const [destino_ciudad, setDestinoCiudad] = useState(null);
    const [destino_error, setDestinoErrors] = useState(null);
    const [fecha_hora_busqueda, setFechaHoraBusqueda] = useState(new Date(2024, 5, 27));


    //
    const {register, setFocus, setValue, getValues, setError, handleSubmit, formState: { errors }} = useForm();
    const navigate = useNavigate();


    //
    useEffect(() => {
        

        //
        var data = {
            origen_ciudad_id : 15,
            destino_ciudad_id : 73,
            fecha_hora_busqueda : '2024-06-27',
        }
        
        //doSearch(data);

    }, []);


    const loadOptions = (inputValue, callback) => {
    
        if (inputValue.length < 3) {
          // Si el valor de entrada tiene menos de 3 caracteres, no hacer la solicitud
          callback([]);
          return;
        }
      
        //
        fetch(config.public_url + `/utils/${origen_ciudad.id}/destinos?q=${inputValue}`)
          .then(response => response.json())
          .then(data => {
            if (data.length){

                const options = data.map(item => {
                    const str_ext = item.ext_origen_ciudad_id ? ` (Extension de ${item.ext_origen_ciudad_nombre})` : "";
                    item.value = item.id;
                    item.label = item.ciudad_nombre + str_ext;
                    return item;
                });

                callback(options);
            } else {
                callback([]);
            }
          })
          .catch(error => {
            console.error('Error fetching options:', error);
            callback([]);
          });
    };
    
    
    //
    const defaultColourOptions = [];

   
    
    const handleSelectDestino = (selectedOption) => {
        //console.log(selectedOption);
        setDestinoCiudad(selectedOption);
      };

    //
    const handleSelectOrigen = (event) => {                
        const selectedOption = event.target.options[event.target.selectedIndex];
        const data_info = JSON.parse(selectedOption.dataset.info);
        //console.log("---data_info: ", data_info);
        setOrigenCiudad(data_info);
    };




    

    const doSearch = (post_data) => {
        //
        setIsLoading(true);
        onBeforeSent();
        // 
        setTimeout(function(){

            postPublic(`/utils/buscar`, toFormData(post_data), function(success, data){
                //console.log("---post do search: ", data);
                //
                if (success){
                    onResults(data);    
                } else {
                    onResults([]);
                }
                //
                setIsLoading(false);
            }, {
                conteTypeForm: true
            });

        }, 1000);

        
    }




    //
    const onSubmit = (data) => {
        //
        if (!destino_ciudad){
            setDestinoErrors("Busca el destino");
            return;
        }
        if (!fecha_hora_busqueda){
            setDestinoErrors("Busca la fecha de busqueda");
            return;
        }
        //
        data.destino_ciudad_id = destino_ciudad.id;
        data.fecha_hora_busqueda = mDateFormat(fecha_hora_busqueda, "YYYY-MM-DD");
        //
        doSearch(data);
    };




    //
    return (
        <BlockUi blocking={is_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> Enviando Solicitud </div>)}>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">


                <h5 className="mt-5 mb-3 border-title"><span className={"fas fa-sign-in"}></span> Acceso a tu Cuenta <small>(recibiras un mensaje de
                    texto)</small></h5>
                <br/>


                <div className="row">
                    <div className="col-md-4 col-6">
                        <div className="form-group">

                            <label className="mb-2">Origen</label>
                            <SelectLoader
                                url={config.public_url + "/utils/origenes"}
                                name_id={"origen_ciudad_id"}
                                empty_value={"--selecciona"}
                                value={(origen_ciudad && origen_ciudad.id) ? origen_ciudad.id : ''}
                                onChange={handleSelectOrigen}
                                parse={(item) => {
                                    var str_ext = (item.ext_destino_ciudad_id) ? " (Extension a " + item.ext_destino_ciudad_nombre + ") " : "";
                                    return item.ciudad_nombre + str_ext;
                                }}
                                register={{...register("origen_ciudad_id", {
                                        required: 'selecciona el pais',
                                        onChange: handleSelectOrigen
                                    })}}
                            />
                            {errors.origen_ciudad_id && <label className="error">{errors.origen_ciudad_id.message}</label>}

                        </div>
                    </div>
                    <div className="col-md-4 col-6">
                        <div className="form-group">
                            
                            <label className="mb-2">Destino</label>
                            <AsyncSelect 
                                cacheOptions={true} 
                                loadOptions={loadOptions} 
                                defaultOptions={defaultColourOptions}
                                onChange={handleSelectDestino} 
                            />
                            {destino_error && <label className="error">{destino_error}</label>}                                    
                            
                        </div>
                    </div>
                    <div className="col-md-4 col-6">
                        <div className="form-group">
                            
                            <label className="mb-2">Fecha</label>
                            <br />

                            <DatePicker
                                dateFormat="Y-MM-d"
                                className="form-control input-lg" 
                                name="fecha_hora_busqueda" 
                                id="fecha_hora_busqueda"
                                selected={fecha_hora_busqueda} 
                                onChange={(date) => setFechaHoraBusqueda(date)}
                                />

                            {errors.fecha_hora_busqueda && <label className="error">{errors.fecha_hora_busqueda.message}</label>}
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="offset-4 col-md-4 col-6">                                
                        
                        {/*
                        <div className="">
                            <input type="checkbox" id="agree_terms" name="agree_terms"
                                {...register('agree_terms', { required: 'debes de aceptar los terminos para continuar' })}
                            />
                            <label htmlFor="agree_terms"> &nbsp; Acepto los
                                <NavLink onClick={() => setModalTerminosCondiciones(true)}>
                                    Terminos y Condiciones
                                </NavLink>
                            </label>
                            <br />
                            {errors.agree_terms && <label className="error">{errors.agree_terms.message}</label>}
                        </div>
                        */}
                        
                    </div>
                    <div className="col-md-4 col-6 text-end">
                        
                        <button type="submit" className="btn btn-primary">Buscar</button>

                    </div>
                </div>
                


                
            </form>
        </BlockUi>
    )

}


export default withAppCtx(FormSearchSalidas);