import React, { useContext, useImperativeHandle, useState, useEffect } from 'react';
import {config} from "../Config";
import axios from "axios";
import {IsCustomerLogged, IsStoreLogged} from "./Helper";
import {AppCtx} from "../AppCtx";



//
const SelectLoader = React.forwardRef(({ url, name_id, isCustomer, isStore, empty_value, className, value, onChange, disabled, parse, register }, ref) => {
//const SelectLoader = ({ url, name_id, isCustomer, isStore, context, empty_value, className, value, onChange, disabled, parse, register }) => {


    //
    const context = useContext(AppCtx);
    const [options, setOptions] = useState([]);



    useEffect(() => {

        //
        getList();


    }, []);




    //
    useImperativeHandle(ref, () => ({
        loadData: getList
    }));




    const handleSelectChange = (event) => {
        onChange(event);
    };



    //
    const getList = () => {
        
        //
       var header = {};
        if ( isCustomer && IsCustomerLogged(context) ){
            let { auth } = context.data;
            //
            //console.log("---SelectLoader auth customer call to: ", url);
            // config.api_url + 
            // config.auth_cust_url +             
            header = {
                headers: {
                    'Authorization': auth.token,
                    'Utype': auth.sale_type_id
                } 
            }
        }

        //        
        axios.get(url, header)
            .then(response => {
                //console.log(response);
                if (response && response.data){
                    //
                    if (empty_value){
                        //
                        let arr_items = [];
                        arr_items.push({
                            id: '',
                            default: empty_value
                        });
                        //
                        if (response.data.length){
                            response.data.forEach((item) => {
                                arr_items.push(item);
                            });
                        }                        
                        //
                        setOptions(arr_items);
                    } else {
                        //
                        setOptions(response.data);
                    }
                    //console.log(value);
                    //onChange(value || '');
                    //setSelectedValue(value || '');
                }
            })
            .catch(error => {
                console.error(error);
            });

    };






    //
    return (<select
        className={className ? className : "form-control"}
        id={name_id}
        name={name_id}
        value={value || ''}
        onChange={handleSelectChange}
        {...(register ? register : {})}
        disabled={disabled ? true : ""}
    >
        {options.map((item, index) => (
            <option key={index} value={item.id} data-info={JSON.stringify(item)}>
                { ( empty_value && index === 0 ) ? item.default : parse(item)}
            </option>
        ))}

    </select>)



})

export default  SelectLoader;
