import React, { useContext, useState, useRef, useEffect } from 'react';
import {config} from "../Config";
import {withAppCtx} from "../Helpers/withAppCtx";
import {withGeoLocationCtx} from "../Helpers/withGeoLocationCtx";
import {NavLink, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {withResDetails} from "../Helpers/withResDetails";
import {confirmAlert} from "react-confirm-alert";
import {
    DivAlert,
    getLogo,
    allowCredit,
    getDurationText,
    IsCustomerLogged,
    postCAuth,
    postApi,
    getCurrentDateObj,
    postPublic,
    preloaderPlaceholder,
    updateSaleItem,
    clearSaleItems,
    getSaleTotals,
    getSaleItems,
    delSaleItem,
    mDateObj,
    mDateFormat,
    setContextData,
    socketSend,
    toFormData,
    getCAuth
} from "../Helpers/Helper";

import { VentasParser } from "../Helpers/FormatParser";

import {NotificationManager} from "react-notifications";
import {SocketCtx} from "../SocketCtx";
import {withAuthCtx} from "../Helpers/withAuthCtx";
import CustomModal from "./Common/modals/CustomModal";
import ConfirmRequest from "./AuthCustomer/Forms/ConfirmRequest";
import PoliticaDePrivacidad from "./Common/PoliticaDePrivacidad";
import TerminosYCondiciones from "./Common/TerminosYCondiciones";
import BlockUi from 'react-block-ui';

import DatePicker from "react-datepicker";
import PayCardStripe from "./Forms/PayCardStripe";
import PayCardANet from "./Forms/PayCardANet";
import PayCardSquare from "./Forms/PayCardSquare";
import {loadStripe} from '@stripe/stripe-js';

import PageScroller from "../Helpers/PageScroller";
import SeatLoader from "../Helpers/SeatLoader";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Button from 'react-bootstrap/Button';

import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import FormRegisterPassanger from './Forms/FormRegisterPassanger';
import FormSearchSalidas from './Forms/FormSearchSalidas';
import moment from "moment";
  




/** https://primereact.org/datatable */

//
const Index = ({ context, geo, token }) => {



    //
    const socketContext = useContext(SocketCtx);
    const navigate = useNavigate();
    //    
    const [arr_local_items, setArrLocalItems] = useState([]);
    const [expiry_mins_limite, setExpirtyMinsLimite] = useState(3);
    const [step_results, setStepResults] = useState(false);
    const [step_selected_salida, setStepSelectedSalida] = useState(false);
    const [show_price, setShowPrice] = useState(false);
    
    //
    const [modal_search_results, setModalSearchResults] = useState(false);
    const [modal_passanger, setModalPassanger] = useState(false);
    const [modal_pagar, setModalPagar] = useState(false);
    const [modal_mi_venta, setModalMiVenta] = useState(false);
    const [modal_terminos_condiciones, setModalTerminosCondiciones] = useState(false);

    //    
    const [stripe_promise, setStripePromise] = useState(null);
    const [anet_config, setANetConfig] = useState(null);
    const [square_config, setSquareConfig] = useState(null);

    //
    const [is_loading, setIsLoading] = useState(false);
    const [is_bus_data_loading, setIsBusDataLoading] = useState(false);    


    /* AQUI ESTAN LOS PASOS DE CADA CONTAINER */
    const [arr_results, setResults] = useState([]);
    const [selected_salida, setSelectedSalida] = useState([]);
    const [bus_data, setBusData] = useState(null);
    const [selected_seat, setSelectedSeat] = useState(null);

    //
    const [inc_load_bus, setIncLoadBus] = useState(0);
    const [customer_data, setCustomerData] = useState(null);
    
    //
    const [currentDatetime, setCurrentDatetime] = useState(moment());


    

   

    //
    useEffect(() => {

        //
        document.title = config.AppName + " - Tu MarketPlace de Partes";
        console.log("------------Index Context: ", context);

        //
        loadPlatformConfig();


        let arr_local_items = getSaleItems();
        setArrLocalItems(arr_local_items); 
        //console.log("***getSaleItems: ", arr_local_items);

        // 
        loadServerItems();
        

        
        //
        let auth = context.getAuth();
        if (auth){
            loadCustomerData(auth);
        }


    }, []);


    //
    useEffect(() => {
        //
        if (selected_salida && selected_salida.id){
            loadBusSeats();
        }
    }, [selected_salida, inc_load_bus]);

    
    



    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDatetime(moment());
        }, 1000); // Actualizar cada segundo

        return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
    }, []);



     //
     const loadCustomerData = (auth) => {
        //
        getCAuth(auth, "",  function(success, data){
            console.log("---customer data: ", data);
            if (success){
                //
                setCustomerData(data);
            }
        });
    }




    const loadPlatformConfig = () => {
        //
        // MissionExpress = 11, Plabuz = 13, Tickets4Buses = 16
        //
        fetch(config.public_url + "/pconfig?appid="+config.appId)
          .then(response => response.json())
          .then(data => {

            //
            if ( data.stripe_config && data.stripe_config.pk ){
                let stripe_promise = loadStripe(data.stripe_config.pk);
                setStripePromise(stripe_promise);
            }
            else if ( data.authorizenet ){
                setANetConfig(data.authorizenet);
            }
            else if ( data.square_config && data.square_config.applicationId && data.square_config.locationId ){
                setSquareConfig(data.square_config);
            }

            if ( data.expiry_mins_limite ){
                setExpirtyMinsLimite(data.expiry_mins_limite)
            }
            

          })
          .catch(error => {
            console.error('Error fetching options:', error);
          });
    }
    

    
    const loadBusSeats = () => {

        setIsBusDataLoading(true);

        //
        //url: app.public_url + "" + selected_seat. + "/" +  selected_seat. + "/" + selected_seat. + "/" + selected_seat. + "/?bi=1",
        fetch(config.public_url + `/utils/results/${selected_salida.ruta_id}/${selected_salida.id}/${selected_salida.origen_ciudad_id}/${selected_salida.destino_ciudad_id}/?bi=1`)
          .then(response => response.json())
          .then(data => {

            setIsBusDataLoading(false);

            //console.log("BUS RESULTS---------: ", data.bus_results);
            if (data.bus_results && data.bus_results.bus_info  && data.bus_results.bus_info.id ){
                setBusData(data.bus_results);
            }
            

          })
          .catch(error => {
            setIsBusDataLoading(false);
            console.error('Error fetching options:', error);
          });
    }




    const getMinutosLimite = () => {
        return ( expiry_mins_limite * 60 );
    }




    const getDiffSeconds = (item) => {
        
        const itemDatetime = moment(item.datetime_created);
        const diffInSeconds = currentDatetime.diff(itemDatetime, 'seconds');

        return {
            itemDatetime, diffInSeconds
        }
    }


    //
    const parseDate = (item) => {

        //
        const {itemDatetime, diffInSeconds} = getDiffSeconds(item);
        
        //
        let expired_text = null;
        const durationText = getDurationText(diffInSeconds);
        // expiry_mins_limite
        if ( diffInSeconds > getMinutosLimite() || item.is_expired ) {
            //            
            expired_text = (<>
                <small style={{ color: 'orangered' }}> {(item.is_expired) ? "**" : null} Expiro hace {durationText} </small><br />
                <small>({currentDatetime.format("DD MMM YY h:mm:ss A")})</small>
            </>);
        } else {
            expired_text = (<>
                <small style={{ color: 'green' }}>Van {durationText}</small>
            </>);            
        }
        
        //
        return (<div>
            <span> {itemDatetime.format("DD MMM YY h:mm:ss A")} </span> <br /> {expired_text}
        </div>);
    }





    /*
    //
    useEffect(() => {
        //
        console.log("------------msg_data: ", socketContext.msg_data);
        //onMessages(socketContext.msg_data);

        //config.resetTempSaleId();
        console.log("***temp_sale_id: ", config.temp_sale_id);
    }, [socketContext.msg_data]);
    */ 





    


    const parseResultActions = (item) => {
        //
        return (<>
            
            <button type="button" onClick={() => {                
                //console.log("***selected salida: ", item)

                //
                setBusData(null);
                setStepResults(false);
                //
                setSelectedSalida(item);
                setStepSelectedSalida(true);
                

            }} className="btn btn-success"> Select <span className="fa fa-arrow-right"></span> </button>

            </>);
    };

    const parseGotoResultsActions = (item) => {
        //
        return (<>
            
            <button type="button" onClick={() => {
                
                //
                goToStep("results");


            }} className="btn btn-primary"> <span className="fa fa-arrow-left"></span> Back </button>

            </>);
    };


    const saleActions = (item) => {

        //
        const item_id = item.id;

        //
        const {diffInSeconds} = getDiffSeconds(item);        
        let str_refresh_btn = null;
        //
        if ( diffInSeconds > getMinutosLimite() || item.is_expired ) {
        
            //
            str_refresh_btn = (
                <button type="button" onClick={() => {
                    //
                    var post_data = {
                        visitor_id: context.visitorId,
                        temp_sale_id: config.temp_sale_id,
                        ruta_id: item.ruta_id,
                        salida_id: item.salida_id,                    
                        lugar_id: item.lugar_id,
                        origen_ciudad_id: item.origen_ciudad_id,
                        destino_ciudad_id: item.destino_ciudad_id,
                        passanger_name: item.passanger_name,
                        passanger_dob: item.passanger_dob,
                    }
                    //                    
                    console.log("refresh item: ", post_data);
                    postPublic("/utils/apartar/?appId="+config.appId, post_data, function(success, data){
                        //
                        if (success){
                            
                            //
                            updateSaleItem(item_id, data);
                            loadServerItems();

                        }
                        //
                        setIsLoading(false);
                    }, {
                        
                    });

                            
                }} className="btn btn-primary"> Refresh </button>
            );

        }

        return (<>
            
            {str_refresh_btn}

            <button 
                type="button" 
                className="btn btn-warning"
                onClick={() => {
                    //
                    if (window.confirm("Liberar Asiento #" + item.num_asiento + "?")){
                        //
                        var post_data = {
                            visitor_id: context.visitorId,
                            id: item_id,
                        }
                        //                    
                        console.log("del item: ", post_data);
                        postPublic("/utils/del-item", post_data, function(success, data){                            
                            
                            //
                            delSaleItem(item_id);
                            loadServerItems();

                        }, {
                        });
                    }
                }}
            > Delete </button>

        </>)
    };


    
    


    


    const handleSelectSeat = (is_available, item_seat) => {
        //console.log(is_available, item_seat);
        if (is_available){

            //
            setIsBusDataLoading(true);

            //
            setTimeout(function(){
                //
                setIsBusDataLoading(false);
            }, 250);


            //
            setModalPassanger(true);
            setSelectedSeat(item_seat);
            

        } else {
            NotificationManager.info("Asiento no disponible");
        }        
    };

    



    
    //
    const setDateTimeOrExpirated = (arr_data) => {
        // 
        let arr_sale_items = getSaleItems();
        //console.log("--------------------START--------------------");
        //
        arr_sale_items.map((item, idx) => {
            //console.log("***item idx: ", item.passanger_name + " - " + item.id, idx);
            //
            let item_found = arr_data.find(the_item => the_item.id === item.id);
            //
            if (item_found && item_found.id){
                //console.log("***Item Index "+idx+" with Id: "+item.id+" MATCH OK", item, item_found);
                arr_sale_items[idx].datetime_created = item_found.datetime_created;
            } else {
                //console.log("***Item Index "+idx+" with Id: "+item.id+" DO NOT MATCH", item);
                arr_sale_items[idx].is_expired = true;
            }
        });
        //console.log("--------------------END--------------------");
        //
        setArrLocalItems(arr_sale_items);
    }

    



    /*
        LOAD SERVER ITEMS
    */
    const loadServerItems = () => {

        //
        setArrLocalItems([]);
        // 
        fetch(config.public_url + "/utils/temp-ocupacion/"+config.temp_sale_id)
        .then(response => response.json())
        .then(data => {
            //console.log("***server data: ", data);
            
            //
            setDateTimeOrExpirated(data);

        })
        .catch(error => {
            //
            console.error('Error fetching options:', error);
        });
    }


    


    //
    const goToStep = (step_name) => {
        
        //
        if ( step_name === "results" ){
            //
            setSelectedSalida([]);
            setBusData(null);
            //        
            setStepSelectedSalida(false);
            setStepResults(true);
        }
        
   }



    

    const resetAll = () => {
         //
         setResults([]);
         setSelectedSalida([]);
         setBusData(null);
         //
         setStepResults(false);
         setStepSelectedSalida(false);
    }





    const onPaymentSuccess = () => {
        
        //
        //clearSaleItems();
        //
        setModalPagar(false);
        setModalMiVenta(false);
        //
        NotificationManager.success('Pago completado, se ha enviado la informacion a tu cuenta de correo');
        //navigate("/account/subscriptions");

        
    }
    

   

    const getCustomerData = () => {
        //
        if (customer_data && customer_data.id){
            //
            var company_name = (customer_data.company_name) ? customer_data.company_name + " - " : "";
            //
            return (<>
                {company_name} {customer_data.first_name}
            </>)
        }
        return null;
    }




    //
    return (<div className={"text-center mt-4"}>

        
        <h3 style={{color:"#005ea1"}}>
                Select your travel location
        </h3>


        <div style={{color:"#666"}} >
            search & find locations
        </div>


        <div className={"mt-5 col-md-6 col-12 mx-auto"}>
            <NavLink to={"/create-account"} className="btn btn-link text-decoration-underline font-weight-bolder"> <span className={"fas fa-user-plus"}></span> Crea tu cuenta para comenzar tu busqueda de piezas </NavLink>            
        </div>


       
       

        {(getSaleItems().length) ? (<>
            
            <Button variant="success" className="" onClick={() => {
                
                //
                resetAll();
                setModalMiVenta(true);
                loadServerItems();

            }}>
                <span className="fa fa-shopping-cart"></span> Mi Venta
            </Button>

            &emsp;

            <Button variant="warning" className="" onClick={() => {
                if (window.confirm("clear sale items?")){
                    resetAll();
                    clearSaleItems();
                }
            }}>
                <span className="fa fa-trash"></span>
            </Button>

        </>) : null}




        
        <h3> {getCustomerData()} </h3>



        {/**
         * 
         * FORMULARIO DE BUSQUEDA
         * 
         */}
        <FormSearchSalidas
            onBeforeSent={(results) => {
                
                //
                resetAll();

                //
                setTimeout(function(){

                    //
                    setIsLoading(true);                    
                    setModalSearchResults(true);

                }, 500);

            }}
            onResults={(results) => {
                //
                setStepResults(true);
                setResults(results);
                //
                setStepSelectedSalida(false);
                //
                setIsLoading(false);
            }} />








        {/**
         * 
         * MODAL RESULTADOS DE BUSQUEDA Y SELECCION DE SALIDA
         * 
         */}
        <CustomModal
            modalId="modal1"
            showModal={modal_search_results}
            modalSize={"modal-xl"}
            modalHeader={true}
            title={"Resultados"}
            headerRight={(<>
            
                {(getSaleItems().length) ? (
                    <Button variant="success" className="mx-2" onClick={() => {
                        setModalSearchResults(false);
                        resetAll();
                        setModalMiVenta(true);
                    }}>
                        <span className="fa fa-shopping-cart"></span> Mi Venta
                    </Button>
                ) : null}

                <Button variant="outline-secondary" onClick={() => {
                    setModalSearchResults(false);
                    resetAll();
                }}>
                    <span className="fa fa-times"></span>
                </Button>
            </>)}
            content={(<>

                
                <BlockUi blocking={is_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> Cargando resultados... </div>)}>
                    {(step_results) && (<>

                        {(arr_results && arr_results.length) ? (<>

                            

                            <div className="card">
                                <DataTable value={arr_results} size="small" paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="id" header="Salida/Ruta/Bus" body={VentasParser().parseSalida}></Column>
                                    <Column field="origen_ciudad_info" header="Origen" body={VentasParser().parseOrigen}></Column>
                                    <Column field="destino_ciudad_info" header="Destino" body={VentasParser().parseDestino}></Column>
                                    {(show_price) && (<Column field="total" header="Precio" body={VentasParser().parsePrice}></Column>)} 
                                    <Column header="" body={parseResultActions}></Column>
                                </DataTable>
                            </div>

                            </>) : (preloaderPlaceholder())}


                        </>)}
                </BlockUi>




                {(step_selected_salida && selected_salida && selected_salida.id) ? (<>
                

                    <div className="card">
                        <DataTable value={[selected_salida]} size="small" tableStyle={{ minWidth: '50rem' }}>
                            <Column field="id" header="Salida/Ruta/Bus" body={VentasParser().parseSalida}></Column>
                            <Column field="origen_ciudad_info" header="Origen" body={VentasParser().parseOrigen}></Column>
                            <Column field="destino_ciudad_info" header="Destino" body={VentasParser().parseDestino}></Column>
                            {(show_price) && (<Column field="total" header="Precio" body={VentasParser().parsePrice}></Column>)} 
                            <Column field="id" header="" body={parseGotoResultsActions}></Column>
                        </DataTable>
                    </div>


                    <BlockUi blocking={is_bus_data_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> loading... </div>)}>
                        { (bus_data && bus_data.bus_info && bus_data.bus_info.id) ? (<>

                            <div className="row mt-4">
                                <div className="col-sm-4 col-12 text-start">                        
                                    {(bus_data.spacios_info && bus_data.spacios_info.length) && (<>
                                        {bus_data.spacios_info.map((item_spacio, idx) => (<span key={idx}>
                                            {item_spacio.espacio_tipo} &nbsp;<span style={{display: "inline-block", width:"15px", height:"15px", backgroundColor: item_spacio.seat_hex_color}}></span><br />
                                        </span>))}
                                    </>)}
                                </div>
                                
                                {(bus_data.rows_piso_1 && bus_data.rows_piso_1.length) && (<div className="col-sm-3 col-12 text-start">
                                    <div id="bus_1st_floor" style={{width:"200px"}}>

                                        {(bus_data.rows_piso_2 && bus_data.rows_piso_2.length) ? (<h3> Piso 1 </h3>) : null}
                                        <SeatLoader
                                            onSelectSpace={handleSelectSeat}
                                            arr_data={bus_data.rows_piso_1}
                                            hideColsAndRows={true}
                                        />

                                    </div>
                                </div>)}

                                {(bus_data.rows_piso_2 && bus_data.rows_piso_2.length) && (<div className="col-sm-3 col-12 text-center">
                                    <div id="bus_1st_floor" style={{width:"200px"}}>

                                        <h3> Piso 2 </h3>
                                        <SeatLoader
                                            onSelectSpace={handleSelectSeat}
                                            arr_data={bus_data.rows_piso_2}
                                            hideColsAndRows={true}
                                        />

                                    </div>
                                </div>)}

                                
                            </div>

                        </>) : (preloaderPlaceholder()) }

                    </BlockUi>



                </>) : null}


                               
            </>)}
            onClose={() => setModalSearchResults(false)}
        />









        {/**
         * 
         * MODAL AGREGAR PASAJERO
         * 
         */}
        <CustomModal
            modalId="modal-add-passanger"
            showModal={modal_passanger}
            modalSize={"modal-xl"}
            modalHeader={true}
            title={"Add Passanger"}
            headerRight={(<>
                <Button variant="outline-secondary" onClick={() => {
                    setModalPassanger(false);
                }}>
                    <span className="fa fa-times"></span>
                </Button>
            </>)}
            content={(<>


                {(selected_seat && selected_seat.id) ? (<>
                    
                    <h3> Seat #{selected_seat.seat_number} </h3>
                    
                    <FormRegisterPassanger 
                        selected_salida={selected_salida}
                        selected_seat={selected_seat}
                        onPassangerAdded={() => {

                            //
                            setModalPassanger(false);
                            setIncLoadBus(inc_load_bus + 1);
                            //
                            NotificationManager.success("Asiento #" + selected_seat.seat_number + " asignado correctamente");

                            //
                            loadServerItems();

                        }}
                    />
                </>) : null}


            </>)}
            onClose={() => setModalPassanger(false)}
        />
    






        

        {/**
         * 
         * MODAL PAGAR
         * 
         */}
        <CustomModal
            modalId="modal-pagar"
            showModal={modal_pagar}
            modalSize={"modal-xl"}
            modalHeader={true}
            title={"Pagar " + config.AppName + (allowCredit(customer_data) ? "*" : "")}
            headerRight={(<>
                <Button variant="outline-secondary" onClick={() => {
                    setModalPagar(false);
                }}>
                    <span className="fa fa-times"></span>
                </Button>
            </>)}
            content={(<>


                {(config.appId === config.AppIdP1) ? (
                    <PayCardSquare
                        arr_items={getSaleItems()}                        
                        squareConfig={square_config}
                        customer_data={customer_data}
                        onPaymentSuccess={onPaymentSuccess}
                        onClose={() => {
                            console.log("onClose");
                        }}
                    />
                ) : null}


                {(config.appId === config.AppIdP2) ? (
                    <PayCardStripe
                        arr_items={getSaleItems()}
                        stripePromise={stripe_promise}
                        customer_data={customer_data}
                        onPaymentSuccess={onPaymentSuccess}
                        onClose={() => {
                            console.log("onClose");
                        }}
                    />
                ) : null}

                {(config.appId === config.AppIdP3) ? (
                    <PayCardANet
                        arr_items={getSaleItems()}
                        anetConfig={anet_config}
                        customer_data={customer_data}
                        onPaymentSuccess={onPaymentSuccess}
                        onClose={() => {
                            console.log("onClose");
                        }}
                    />
                ) : null}

            </>)}
            onClose={() => setModalPagar(false)}
        />





        {/**
         * 
         * MODAL MI VENTA
         * 
         */}
        <CustomModal
            modalId="modal-mi-venta"
            showModal={modal_mi_venta}
            modalSize={"modal-xl"}
            modalHeader={true}
            title={"Mi venta"}
            headerRight={(<>
                <Button variant="outline-secondary" onClick={() => {
                    setModalMiVenta(false);
                }}>
                    <span className="fa fa-times"></span>
                </Button>
            </>)}
            content={(<>


                <button type="button" onClick={() => {
                    //            
                    loadServerItems();

                    }} className="btn btn-primary"> Refresh </button>


                <button type="button" onClick={() => {
                    
                    //            
                    setModalPagar(true);

                    }} className="btn btn-primary"> Pagar </button>



                <div className="card">            
                    <DataTable value={arr_local_items} size="small" paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="id" header="Seat Passanger" body={VentasParser().parseSeat}></Column>
                        <Column field="id" header="Salida/Ruta/Bus" body={VentasParser().parseSalida}></Column>
                        <Column field="origen_ciudad_info" header="Origen" body={VentasParser().parseOrigen}></Column>
                        <Column field="destino_ciudad_info" header="Destino" body={VentasParser().parseDestino}></Column>
                        <Column field="total" header="Precio" body={VentasParser().parsePrice}></Column>
                        <Column field="id" header="Creado" body={parseDate}></Column>
                        <Column header="" body={saleActions}></Column>
                    </DataTable>
                    <h2 className='text-right text-end'> {getSaleTotals(arr_local_items)} </h2>
                </div>

            </>)}
            onClose={() => setModalMiVenta(false)}
        />






        <CustomModal
            showModal={modal_terminos_condiciones}
            modalSize={"modal-lg"}
            modalHeader={true}
            title={(<div><span className={"icofont-badge"}></span> Terms && Conditions </div>)}
            content={<TerminosYCondiciones />}
            onClose={() => setModalTerminosCondiciones(false)}
        />







    </div>)


}


export default withAppCtx(Index);