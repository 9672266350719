import React, { useState, useEffect } from 'react';

const SeatLoader = (props) => {
    
    //const [data, setData] = useState(null);

    useEffect(() => {


        //console.log("----------------------Data: ", props.arr_data)

        
    }, []);

    
    if (!props.arr_data) {
        return <div>Cargando...</div>; // Muestra un indicador de carga mientras se carga data
    }


    const renderBus = (arr_rows) => {

        return(
            
            <table className='table table-sm' style={{border: "1px solid #75d8ff"}}>
                <thead>
                    {( !props.hideColsAndRows && arr_rows && arr_rows.length ) ? (<tr>
                        <th style={{ width: '20px' }}>&nbsp;</th>
                        <th className="text-center"> A </th>
                        <th className="text-center"> B </th>
                        <th style={{ width: '15px' }}></th>
                        <th className="text-center"> C </th>
                        <th className="text-center"> D </th>
                    </tr>) : null}
                </thead>

                <tbody>
                    {arr_rows.map((item_row, idx) => (<tr key={idx}>

                        
                            {(!props.hideColsAndRows) ? (
                                <td>
                                    <small> {item_row.row} </small>
                                </td>
                            ) : null}
                        

                        {item_row.cols.map((item_col, idx2) => (<React.Fragment key={idx2}>
                            

                            {idx2 === 2 && (
                                <td style={{
                                    width: '15px',
                                    height: '40px',
                                    backgroundColor: '#fff',
                                    borderTop: '0',
                                    borderBottom: '0',
                                    borderLeft: '1px solid #75d8ff',
                                    borderRight: '1px solid #75d8ff',
                                    padding: '0'
                                }}></td>
                            )}

                            {/* Variables para el color, opacidad y borde del espacio */}
                            {item_col.seat_number && (
                                <td style={{
                                    height: '40px',
                                    backgroundColor: item_col.seat_hex_color || '#fff',
                                    opacity: item_col.ocupacion_id ? '0.5' : '1',
                                    border: item_col.ocupacion_id ? '1px solid #bdddff' : (item_col.seat_active ? '1px solid #75d8ff' : '1px solid #bdddff'),
                                    padding: '0'
                                }}>
                                    {/* Contenido de la celda (enlace) */}
                                    <a href="#" className={"btn-select-space"} onClick={(e) => {
                                        e.preventDefault();
                                        props.onSelectSpace((item_col.ocupacion_id ? false : true), item_col)
                                    }} style={{
                                        display: 'block',
                                        width: '100%',
                                        height: '100%',
                                        textDecoration:"none",
                                        textAlign: 'center',
                                        verticalAlign: 'middle'
                                    }}>
                                        {(item_col.seat_number) ? (<>
                                            {item_col.seat_number} 
                                            {(item_col.ocupacion_id) ? item_col.ocupacion_id : null}
                                        </>
                                        ) : null}
                                    </a>
                                </td>
                            )}


                        </React.Fragment>))}


                        
                    </tr>))}
                </tbody>


            </table>
        )
    }

    return renderBus(props.arr_data);
};

export default SeatLoader;
