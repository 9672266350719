import React, { Component } from 'react';
import {config} from "../../Config";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import { Tab, Nav, Form } from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import {withNavigate} from "../../Helpers/withNavigate";
import {fmtDateSpanish, getBAuth, getCAuth, SubscriptionItem} from "../../Helpers/Helper";
import PageScroller from "../../Helpers/PageScroller";




class Subscriptions extends Component {


    constructor(props) {
        super(props);


        //
        const queryParams = new URLSearchParams(this.props.location.search);
        const qs_sel_tab = queryParams.get('tab');
        //
        let default_tab = (qs_sel_tab) ? qs_sel_tab : "tab1";


        this.state = {
            activeTab: default_tab,
            subs_data: null,
            no_subs: false
        };


        //
        this.pageScrollerRef = React.createRef();
    }





    //
    componentDidMount() {
        //
        let self = this;
        //
        document.title = config.AppName + " - Suscripciones";


        console.log("---calling customer account props: ", this.props);


        //
        this.getLastSubscription();


        //
        if (this.props.token){ this.props.token.tokenUserData(); }
    }





    //
    getLastSubscription(){
        //
        let self = this;

        //
        getCAuth(this.props.context.data.auth, "/subscriptions/last",  function(success, data){
            console.log("---customer last subscriptions data: ", data);
            //
            if (success){
                //
                self.setState({
                    subs_data: data,
                    no_subs: false
                });
            } else {
                //
                self.setState({
                    no_subs: true
                });
            }
        }, null);

    }








    //
    btnReload = () => {
        if (this.pageScrollerRef.current) {
            this.pageScrollerRef.current.resetItems();
        }
    }






    //
    handleTabSelect = (tab) => {
        this.setState({
            activeTab: tab
        });
    };




    //
    render(){



        //
        const { activeTab, subs_data, no_subs } = this.state;


        //
        return (<>



            <div className="pt-4 pb-4">
                <h5><span className="icofont-badge"></span> Mi Suscripciones </h5>
            </div>




            <Tab.Container activeKey={activeTab} onSelect={this.handleTabSelect}>
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="tab1"> &raquo; Suscripcion Actual </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="tab2"> &raquo; Historial </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="tab1">



                        <div className="shadow-sm rounded overflow-hidden mb-3">
                            <div className="p-4 bg-white">






                                <div className="row">
                                    <div
                                        className="offset-xl-3 offset-lg-3 offset-md-2 offset-sm-2 col-xl-6 col-lg-6 col-md-8 col-sm-8 col-12 text-center">




                                        {(subs_data && subs_data.id) && (
                                            <div className="rounded">
                                                <header className="subs-hdr">
                                                    <h4 className=""> {(subs_data.lst_itm_item_info) ? subs_data.lst_itm_periodicidad.toUpperCase() : null} </h4>
                                                    <div className="">
                                                        <h3 className=""> <span className='fa fa-rocket'></span> {subs_data.lst_itm_item_info} </h3>
                                                    </div>
                                                </header>
                                                <div className="card-body p-4">
                                                    <ul className="list-group list-group-flush mb-4">

                                                        <li className="list-group-item py-2 px-0 border-0">
                                                            <i className="icofont-check-circled text-success mr-1"></i>  {subs_data.lst_itm_cant_busquedas} Busquedas Diarias
                                                        </li>
                                                        <li className="list-group-item py-2 px-0 border-0">
                                                            <i className="icofont-check-circled text-success mr-1"></i>  Busquedas Simultaneas
                                                        </li>

                                                        <li className="list-group-item py-2 px-0 border-0">
                                                            <i className="icofont-check-circled text-success mr-1"></i>
                                                            Valida del <strong> {fmtDateSpanish(subs_data.lst_itm_datetime_created.date)} </strong>
                                                            hasta el <strong> {fmtDateSpanish(subs_data.lst_itm_datetime_expiry.date)} </strong>
                                                        </li>
                                                        <li className="list-group-item py-2 px-0 border-0">
                                                            <i className="icofont-check-circled text-success mr-1"></i> pagado por {subs_data.customer_name}
                                                        </li>
                                                    </ul>
                                                    <NavLink to="/account/subscribe"
                                                             className="btn btn-outline-success"
                                                             tabIndex="0"> Actualiza tu Suscripcion <span
                                                        className="fa fa-rocket"></span></NavLink>
                                                </div>
                                            </div>
                                        )}

                                        {(no_subs) && (
                                            <div className="py-5" id="no-subscription">
                                                <div className="alert alert-light" role="alert">
                                                    <h4 className="alert-heading"><span
                                                        className="fa fa-info-circle"></span> Aun No tienes una
                                                        Suscripcion </h4>
                                                    <p>
                                                        ¡Suscríbete Ahora! Aumenta tu cantidad de busquedas diarias y tu radio de alcanze, llega a mas lugares para encontrar lo que buscas
                                                    </p>
                                                    <NavLink to="/account/subscribe"
                                                             className="btn btn-success"> Contrata Aqui <span
                                                        className="fa fa-arrow-right"></span></NavLink>
                                                </div>
                                            </div>
                                        )}


                                    </div>
                                </div>


                            </div>
                        </div>

                    </Tab.Pane>
                    <Tab.Pane eventKey="tab2">



                        <div className="shadow-sm rounded overflow-hidden mb-3">
                            <div className="p-4 bg-white">

                                <div className="row mb-4">
                                    <div className="col-8">
                                        <h5 className="mb-0 text-gray-900"><span
                                            className="fa fa-list"></span> Historial </h5>
                                    </div>
                                    <div className="col-4 text-right">
                                        <a className="btn btn-sm btn-outline-info" onClick={this.btnReload}><i
                                            className="fas fa-sync"></i></a>
                                    </div>
                                </div>



                                <PageScroller
                                    num_recs={4}
                                    height={400}
                                    ref={this.pageScrollerRef}
                                    listClassName={"clear-list"}
                                    parseScrollContent={(item, index) => <SubscriptionItem key={index} item={item} />}
                                    url={config.auth_cust_url + "/subscriptions"}
                                />



                            </div>
                        </div>

                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>

        </>)
    }

}

export default withAuthCtx(withAppCtx(withNavigate(Subscriptions)));