import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
//import { BsX } from 'react-icons/bs';


const CustomModal = ({ modalId, title, headerRight, modalHeader, content, onClose, showModal, hideOnScape, modalSize, footer }) => {

    const handleCloseModal = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Modal id={modalId} show={showModal} dialogClassName={(modalSize) ? modalSize : "modal-md"} onHide={handleCloseModal} backdrop="static" keyboard={(hideOnScape) ? true : false}>
            {modalHeader && (
                <Modal.Header>
                    <div className="col-8 text-start">
                        <Modal.Title><div className={"mt-2"} style={{fontSize:18}}>{title}</div></Modal.Title>
                    </div>
                    <div className="col-4 text-end">
                        {headerRight}
                    </div>                    
                </Modal.Header>
            )}
            <Modal.Body>{content}</Modal.Body>
            {footer && (
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default CustomModal;
