import React, { createContext, useState, useEffect } from 'react';
import moment from "moment";
import 'moment/locale/es';
import {config} from "./Config";


// Crear el contexto
const AppCtx = createContext();

// Crear el proveedor del contexto
const AppCtxProvider = ({ children }) => {
    // Estado para almacenar los datos en el contexto


    //
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    const [visitorId, setVisitorId] = useState(null);





    // Función para actualizar los datos en el contexto y en el localStorage
    const updateData = (obj_data) => {
        //console.log("---updating context data: ", obj_data);
        setData(obj_data);
        localStorage.setItem(config.appSessionPrefix, JSON.stringify(obj_data));
    };





    //
    const getAuth = () => {
        return ( data && data.auth && data.auth.id ) ? data.auth : null;
    }









    //
    const getLang = () => {
        //
        const device_lang = navigator.language || navigator.userLanguage;
        const spltd_lang = device_lang.split("-");
        //
        const deviceLang = (spltd_lang && spltd_lang[0]) ? spltd_lang[0] : spltd_lang;
        const lang_country_id = (deviceLang === 'es') ? 379 : 467;
        const currency_type = (deviceLang === 'es') ? "mxn" : "usd";
        //
        return {
            deviceLang: deviceLang,
            lang_country_id: lang_country_id,
            currency_type: currency_type
        };
    }




    //
    const loadFingerprintJS = async () => {
        const fpPromise = await window.FingerprintJS.load();
        const fp = await fpPromise;
        const result = await fp.get();
        //console.log(result);
        //
        var visitorId = (result && result.visitorId) ? result.visitorId : null;
        setVisitorId(visitorId);
        //
        setLoaded(true);
    };




    // Recuperar los datos del localStorage al iniciar la aplicación
    useEffect(() => {




        /*
        if ('Notification' in window) {
            Notification.requestPermission();
        }
         */




        /*
        * Set stored data
        * */
        //localStorage.removeItem(config.appSessionPrefix);
        const storedData = localStorage.getItem(config.appSessionPrefix);
        //
        if (storedData) {
            var parsed_data = JSON.parse(storedData);
            //console.log("---parsed_data: ", parsed_data);
            //
            setData(parsed_data);
        }



        /*
        * Set moment locale
        * */
        const curr_lang = getLang();
        //console.log("--curr_lang: ", curr_lang.deviceLang);
        moment.locale(curr_lang.deviceLang);


        /*
        * Al final carga fingerprint pq es un promise
        * */
        loadFingerprintJS();

    }, []);





    const browserNotification = (title, msg) => {
        if (Notification.permission === 'granted') {
            new Notification(title, {
                body: msg,
            });
        }
    };



    //
    if( !loaded ){
        //console.log("Loading AppCtx...");
        return (<div>...</div>);
    } else {
        //console.log("AppCtx loaded Ok!");
    }


    //
    const contextValue = {
        data,
        visitorId,
        browserNotification,
        updateData,
        getAuth,
        getLang
    };

    //
    return <AppCtx.Provider value={contextValue}>{children}</AppCtx.Provider>;
};

export { AppCtx, AppCtxProvider };
