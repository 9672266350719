import React, { Component, useState, useEffect } from 'react';
import {config} from "../../Config";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {useForm} from "react-hook-form";
import ConfirmPwd from "../Common/ConfirmPwd";
import CustomModal from "../Common/modals/CustomModal";
import {getCAuth, postCAuth, toFormData} from "../../Helpers/Helper";
import {NotificationManager} from "react-notifications";
import SelectLoader from "../../Helpers/SelectLoader";
import {withNavigate} from "../../Helpers/withNavigate";



const Credentials = ({ context, token }) => {


    //
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();
    const [post_data, setPostData] = useState(null);
    const [modal_pwd, setModalPwd] = useState(null);
    const [default_phone_country_id, setDefaultPhoneCountryId] = useState('');
    const [phone_number, setPhoneNumber] = useState('');





    //
    useEffect(() => {


        //
        getCAuth(context.data.auth, "",  function(success, data){
            console.log("---credentials data: ", data);
            if (success){
                //
                setDefaultPhoneCountryId(data.phone_country_id);
                setPhoneNumber(data.phone_number);
            }
        });


        //
        document.title = config.AppName + " - Credenciales";
        console.log("---credentials context: ", context);


        //
        token.tokenUserData();


    }, []);








    //
    const postForm = (the_pwd) => {
        //
        post_data.password = the_pwd;
        //
        postCAuth(context.data.auth, "/pwd",  toFormData(post_data), function(success, data){
            console.log("---credentials post results: ", data);
            if (success){
                //
                NotificationManager.success('Datos actualizados correctamente');
                //
                //updateContextDate(context, "auth", response.data.updateData);
                //navigate("/store/account");
                //window.location.reload();
            }
        });
    }


    //
    const onSubmitForm = (data) => {
        //
        setPostData(data);
        setModalPwd(true)
    };



    // el componente ConfirmPwd al poner el pwd cierra la ventana del modal
    // y manda llamar segun sea el caso a actualizar
    const onSetPwd = (the_pwd) => {
        //
        handleCloseModal();
        postForm(the_pwd);
    };


    //
    const handleSelectChange = (event) => {
        //console.log("---onChange: ", event.target.value);
        setDefaultPhoneCountryId(event.target.value);
    };

    //
    const handleCloseModal = () => {
        //
        setModalPwd(false);
    };



    //
    return (<div className={"pt-1 pb-4"}>


        <h5 className=""><span className="icofont-sign-in"></span> Credenciales de Acceso </h5>
        <hr />

        <div className="row mt-4">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">


                {/*<form method="post" id="form_credentials" name="form_credentials" class="p-40" enctype="multipart/form-data">*/}

                <div className="row">
                    <div className="col-sm-4 col-md-4">
                        <div className="form-group">

                            <label className="">Codigo Pais</label>
                            <SelectLoader
                                url={config.api_url + "/public/paises/list"}
                                name_id={"phone_country_id"}
                                empty_value={"--selecciona"}
                                value={default_phone_country_id}
                                onChange={handleSelectChange}
                                disabled
                                parse={(item) => "+" + item.phone_cc + " (" + item.abreviado + ")"}
                            />


                        </div>
                    </div>
                    <div className="col-sm-8 col-md-8">
                        <div className="form-group">
                            <label className="">Telefono</label>
                            <input type="text" className="form-control input-lg"
                                   name="phone_number" id="phone_number"
                                   value={phone_number}
                                   placeholder="Telefono" disabled />
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="row">
                        <div className="col-12 pl-2 text-right">
                            <label>&nbsp;</label>
                            {/*<button type="submit" class="btn btn-primary"><span class="fa fa-save"></span> Actualizar </button>*/}
                        </div>
                    </div>
                </div>

                {/*</form>*/}
            </div>


            <div className="col-xl-6 col-lg-6 col-md-6 col-12">


                <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off" role="presentation" className="p-40">

                    <h5><span className="fa fa-key"></span> Cambiar contrase&ntilde;a</h5>
                    <hr />

                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                                <label className="">Nueva Contrase&ntilde;a</label>
                                <input type="password" className="form-control input-lg"
                                       id="new_password" name="new_password"
                                       placeholder="clave"
                                       {...register('new_password', { required: 'introduce tu clave' })}
                                />
                                {errors.new_password && <label className="error">{errors.new_password.message}</label>}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                                <label className="">Confirmar Contrase&ntilde;a</label>
                                <input type="password" className="form-control input-lg"
                                       id="confirm_password" name="confirm_password"
                                       placeholder="confirma tu clave"
                                       {...register('confirm_password', {
                                           required: 'confirma tu clave',
                                           validate: value => value === getValues("new_password") || 'la clave no es igual'
                                       })}
                                />
                                {errors.confirm_password && <label className="error">{errors.confirm_password.message}</label>}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 pl-2 text-right">
                            <button type="submit" className="btn btn-primary"><span
                                className="fa fa-save"></span> Actualizar
                            </button>
                        </div>
                    </div>

                </form>

            </div>
        </div>



        <CustomModal
            modalHeader={true}
            title="Confirma tu Clave"
            content={<ConfirmPwd onSetPwd={onSetPwd} />}
            onClose={handleCloseModal}
            showModal={modal_pwd}
        />

    </div>)

}



export default withAuthCtx(withAppCtx(withNavigate(Credentials)));