import React, {createContext, useState, useEffect, useContext} from 'react';
import {config} from "./Config";
import axios from "axios";
import {AppCtx} from "./AppCtx";
import {useLocation, useNavigate} from "react-router-dom";
import {delContextData, scrollToTop} from "./Helpers/Helper";
//
const AuthCtx = createContext();


//
const AuthCtxProvider = ({ children }) => {

    //
    const [data, setData] = useState(null);
    const context = useContext(AppCtx);
    const navigate = useNavigate();
    const location = useLocation();



    //
    const validateURL = (url, targetURL) => {
        const regex = new RegExp(`^${targetURL}(\/)?$`);
        return regex.test(url);
    };






    //
    const ValidateCustomerRoutes = (token_data) => {
        //console.log(token_data);

        //
        let pathname = location.pathname;
        console.log("---validate customer routes: ", pathname);


        //
        if ( pathname === "/" ){
            //console.log("***********navigated to " + pathname);
            //navigate("/account");
        }



        //
        scrollToTop();
        setData(token_data);
    }


    //
    const ValidateStoreRoutes = (token_data) => {
        //console.log(token_data);

        //
        let pathname = location.pathname;
        //console.log("---validate store routes: ", pathname);


        //
        if ( !token_data.has_location ){
            navigate("/account/config-negocio?tab=tab2&setup=1");
        }
        //
        else if ( !token_data.has_about_and_logo ){
            navigate("/account/config-negocio?setup=1");
        }
            //
        // si es pedidero o autoparts debe de establecer el horario mandatorio ( Si es es qponea es opcional )
        else if ( config.appId === config.AppIdP2 || config.appId === config.AppIdP3 ){
            //
            if ( !token_data.has_valid_schedule ){
                navigate("/account/config-negocio?tab=tab3&setup=1");
            }
        }


        //
        scrollToTop();
        setData(token_data);
    }


    //
    const notValidTokenRedirect = () => {

        // remove auth data & set token_data to null (real time data)
        delContextData(context, "auth");

        //
        let pathname = location.pathname;
        //console.log("---notValidTokenRedirect: ", pathname);

        /**
         *
         * implementacion rutas que no pasan el auth
         *
         * */
        //
        if ( validateURL(pathname, "/account") ){
            //console.log("--path found " + pathname + " do redirect");
            navigate("/sign-in");
            setData(null);
        }
        //
        else {
            navigate("/");
            setData(null);
        }

        //
        scrollToTop();
    }



    //
    const verifyToken = (auth) => {
        //
        axios.get(config.auth_cust_url + "/ud", { headers: { Authorization: auth.token } })
            .then(response => {
                //console.log("---check token data: ", response.data);return;

                if (response && response.data && response.data.id){
                    //
                    ValidateCustomerRoutes(response.data)

                } else {
                    notValidTokenRedirect();
                }
            })
            .catch(error => {
                notValidTokenRedirect();
            });
    };







    /*
    *
    * */
    const tokenUserData = () => {
        //
        let auth = context.getAuth();
        if (auth){

            // no lo llamamos hasta que ponemos el isFirstCall a false
            verifyToken(auth);

        }
    }





    useEffect(() => {


        tokenUserData();


    }, []);




    /*
    if( data ){
        //console.log("Loading AuthCtx...");
        return (<div>
            <div className="container-cent">
                <div className="image-container-cent">
                    <img
                        className="img-fluid w-40 rotating-image"
                        src={process.env.PUBLIC_URL + '/images/Qponea-logo-simple.png'}
                        alt="Logo"
                    />
                </div>
            </div>
        </div>);
    } else {
        console.log("AuthCtx loaded Ok!");
    }
     */


    //
    const contextValue = {
        tokenUserData: tokenUserData,
        userData: data
    };

    //
    return <AuthCtx.Provider value={contextValue}>{children}</AuthCtx.Provider>;
};

export { AuthCtx, AuthCtxProvider };
