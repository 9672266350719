import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

//
const TerminosYCondiciones = ({}) => {



    //
    return (<>

        <p>Los siguientes términos y condiciones rigen tu uso de la plataforma YonkeParts. Te recomendamos leerlos atentamente:</p>

        <ol>
            <li><strong>Monitoreo:</strong> Valoramos la calidad del servicio brindado por cada negocio en YonkeParts. Monitoreamos las operaciones y, en caso de recibir un número significativo de reportes o detectar malos tratos o prácticas deshonestas, nos reservamos el derecho de dar de baja permanentemente a dicho negocio de la plataforma. Es fundamental para nosotros mantener la integridad y el prestigio de YonkeParts.</li>
            <li><strong>Responsabilidad Limitada:</strong> YonkeParts se exime de cualquier responsabilidad relacionada con las transacciones entre clientes y negocios. Cada negocio es responsable de sus productos, servicios y términos.</li>
            <li><strong>Uso Responsable:</strong> Te comprometes a utilizar la plataforma de manera responsable y respetuosa. No toleramos conductas inapropiadas o fraudulentas.</li>
            <li><strong>Privacidad:</strong> Respetamos tu privacidad. Consulta nuestra Política de Privacidad para obtener más información sobre cómo manejamos tus datos personales.</li>
            <li><strong>Actualizaciones:</strong> Los términos y condiciones pueden cambiar con el tiempo. Te recomendamos revisarlos periódicamente para mantenerte informado de cualquier actualización.</li>
            <li><strong>Contacto:</strong> Si tienes preguntas o preocupaciones, no dudes en contactarnos a través de nuestro correo electronico <strong>Info@YonkeParts.com</strong></li>
        </ol>

        <p>Al utilizar YonkeParts, aceptas estos términos y condiciones. Apreciamos tu confianza en nuestra plataforma y esperamos proporcionarte un servicio excepcional.</p>

    </>)

}


export default TerminosYCondiciones;