import React, { useContext, useState, useRef, useEffect } from 'react';
import {config} from "../../Config";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {withGeoLocationCtx} from "../../Helpers/withGeoLocationCtx";
import {SocketCtx} from "../../SocketCtx";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {NavLink} from "react-router-dom";



const LimitReached = ({ context, navigate, token, geo }) => {



    //
    const socketContext = useContext(SocketCtx);




    //
    const categoriesRef = useRef();






    //
    useEffect(() => {

        //
        document.title = config.AppName + " - Limite Alcanzado";
        console.log("------------limit reached: ");



        //
        token.tokenUserData();




    }, []);




    //
    const onMessages = (msg_data) => {
        //
        if (msg_data && msg_data.action_type && msg_data.action_type === "xxxxxxxxx" ) {

        }
    }




    //
    useEffect(() => {

        //
        console.log("------------limit reached msg_data: ", socketContext.msg_data);
        if (socketContext.msg_data){
            onMessages(socketContext.msg_data);
        }


        // remover mensajes el salir
        return () => {
            socketContext.resetMsgData();
        };


    }, [socketContext.msg_data]);








    //
    return (<div className="pt-4 pb-4">


        <div className="row">
            <div className="col-12">


                <div className={"p-5 text-center"}>

                    <h5 className={"text-info"}><span className="fas fa-info-circle"></span>
                        {( token.userData && token.userData.has_valid_subs ) ? (
                            <> Opps, parece que has alcanzado el límite de {token.userData.cbp} busquedas permitidas </>
                        ) : (
                            <> Opps, parece que has alcanzado el límite de 3 busquedas gratuitas permitidas </>
                        )}

                        <br />

                        <NavLink to={"/account/subscribe"} style={{textDecoration:"underline"}}>
                            &raquo; Actualiza tu suscripcion para continuar buscando sin restricciones
                        </NavLink>

                    </h5>




                </div>


            </div>
        </div>




    </div>)


}


export default withAppCtx(withAuthCtx(withGeoLocationCtx(LimitReached)));