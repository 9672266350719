import React, { useContext, useState, useRef, useEffect } from 'react';
import {withAppCtx} from "../Helpers/withAppCtx";
import {DivAlert, get, getApi, IsCustomerLogged, isSeller, mDateFormat, post, postApi, postCAuth, readFileToSrc, socketSend, toFormData} from "../Helpers/Helper";
import {withAuthCtx} from "../Helpers/withAuthCtx";
import {withNavigate} from "../Helpers/withNavigate";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FrontPageVentasParser } from "../Helpers/FormatParser";
import {confirmAlert} from "react-confirm-alert";
import {NotificationManager} from "react-notifications";




const ConfirmSale = ({ context, navigate, params, token, geo }) => {




    
    

    const [selected_sale, setSelectedSale] = useState(null);




    const getVenta = (sale_code) => {
        //
        getApi(`/sales/` + sale_code, function(success, response){
            if (success){
                //
                setSelectedSale(response);
            }
        });
    };



    //
    const postConfirmarVenta = (sale_code) => {
        //
        setSelectedSale(null);
        //
        postApi(`/sales/` + sale_code + "/confirm", {}, function(success, response){
            if (success){
                NotificationManager.success("Venta aceptada correctamente");                
            }
            //
            getVenta(params.sale_code);
        }, {

        });
    }
    

    //
    const confirmarVenta = (item) => {
        
        //
        confirmAlert({
            message: 'Al aceptar esta venta sera asignada a su usuario',
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: function(){
                        postConfirmarVenta(item.sale_code);
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: function(){

                    }
                }
            ]
        });

    };
    

    
    //
    useEffect(() => {

        //console.log("params: ", params);
        getVenta(params.sale_code);

    }, []);

    


    //
    const parseCredito = (item) => {
        //
        let company_name = (item.company_name) ? item.company_name + " - " : "";
        //
        return (<>
            {item.a_credito ? (
                <>
                    <div>Venta a credito: </div>

                    {item.seller_accepted ? (
                        <small style={{ color: 'green' }}>
                            <i className="fas fa-check" style={{ color: 'green' }}></i> Venta Acceptada
                        </small>
                    ) : (<div>
                            <button type="button" onClick={() => {confirmarVenta(item)}} className="btn btn-md btn-success"><span className="fa fa-rocket"></span> Aceptar Venta </button>
                    </div>)}
                </>
            ) : ""}
        </>)
    }

    


    //
    const getSelectedSale = () => {
        //
        return (<div style={{border:"1px solid gray", padding:25, margin:25, borderRadius:10}}>
            {(selected_sale) ? (<>

                {(selected_sale.a_credito && selected_sale.seller_accepted) ? (<>

                    <div style={{fontSize:20}} className={"mb-4"}><span className="fas fa-list"></span> Venta <strong>#{selected_sale.id}</strong> Aceptada </div>

                </>) : (<>

                    <div style={{fontSize:20}} className={""}><span className="fas fa-list"></span> Confirmar Venta <strong>#{selected_sale.id}</strong></div>
                    <small style={{color:"green"}}>Nota: solo usted tiene acceso al link de esta venta</small>

                    <div className='mt-4'>{FrontPageVentasParser().parseCustomerData(selected_sale)}</div>
                    <hr />
                    <div>{FrontPageVentasParser().parseItems(selected_sale)}</div>
                    <div>{FrontPageVentasParser().parseTotalAndPayments(selected_sale)}</div>
                    <hr />
                    <div>Fecha/hora creacion: {FrontPageVentasParser().parseDate(selected_sale)}</div>
                    <hr />
                    <div>{parseCredito(selected_sale)}</div>
                    <hr />
                    {/*<div>Pagado: {FrontPageVentasParser().parsePaid(selected_sale)}</div>*/}

                </>)}

            </>) : "loading..."}

        </div>)
    }
    

    
    //
    return (<div className="pt-4 pb-4">
        <div className="row">
            <div className="col-12 text-left">                
                {getSelectedSale()}
            </div>
        </div>
    </div>)


}


export default withAppCtx(withNavigate(ConfirmSale));