import React, { Component, useState, useEffect } from 'react';
import {config} from "../../Config";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {useForm} from "react-hook-form";
import ConfirmPwd from "../Common/ConfirmPwd";
import CustomModal from "../Common/modals/CustomModal";
import {getCAuth, postCAuth, toFormData, readFileToSrc, updateContextDataItem, dynUrl} from "../../Helpers/Helper";
import {NotificationManager} from "react-notifications";
import {withNavigate} from "../../Helpers/withNavigate";




const Profile = ({ context, token }) => {



    //
    const { register, handleSubmit, setValue, setFocus, formState: { errors } } = useForm();
    const [customer_data, setCustomerData] = useState('');
    const [email, setEmail] = useState('');
    const [post_data, setPostData] = useState(null);
    const [modal_pwd, setModalPwd] = useState(null);
    //
    const [profile_img, setProfileImgSrc] = useState('');



    //
    useEffect(() => {

        //
        document.title = config.AppName + " - Mi Perfil";
        console.log("---profile context: ", context);


        //
        getCAuth(context.data.auth, "",  function(success, data){
            console.log("---profile data: ", data);
            if (success){

                //
                setValue("first_name", data.first_name);
                setValue("last_name", data.last_name);
                setEmail(data.email);

                //
                setCustomerData(data);

                //
                if (data.profile_img){
                    setProfileImgSrc(data.profile_img + dynUrl("?"));
                }

            }
        });


        //
        setFocus("first_name");

        //
        token.tokenUserData();


    }, []);








    //
    const btnUpdateProfileImage = () => {
        const fileInput = document.getElementById('cust_img');
        fileInput.click();
    }
    //
    const onChangeProfileImg = (e) => {
        //
        let img_file = e.target.files[0];
        //console.log("---img_file: ", img_file);

        //
        readFileToSrc(img_file, function(results_src){
            setProfileImgSrc(results_src);
        });

        //
        setFocus("first_name");
    }




    //
    const postForm = (the_pwd) => {
        //
        post_data.password = the_pwd;
        //
        postCAuth(context.data.auth, "/basic",  toFormData(post_data), function(success, data){
            console.log("---profile post results: ", data);
            if (success){

                //
                NotificationManager.success('Datos actualizados correctamente');

                //
                if ( data.updateData && data.updateData.profile_img ){
                    updateContextDataItem(context, "auth", "profile_img", data.updateData.profile_img + dynUrl("?"));
                }

                //
                //navigate("/store/account");
                //window.location.reload();
            }
        });
    }


    //
    const onSubmitForm = (data) => {
        //
        setPostData(data);
        setModalPwd(true)
    };



    // el componente ConfirmPwd al poner el pwd cierra la ventana del modal
    // y manda llamar segun sea el caso a actualizar
    const onSetPwd = (the_pwd) => {
        //
        handleCloseModal();
        postForm(the_pwd);
    };



    //
    const getProfileImg = () => {
        //
        if (profile_img){
            return (<img className="store-img-2" src={profile_img} style={{maxWidth:150}} />)
        } else if (customer_data && customer_data.initials) {
            return (<div style={{margin:"0 auto"}} className="divRounded3"> {customer_data.initials} </div>)
        }
    };



    //
    const handleCloseModal = () => {
        //
        setModalPwd(false);
    };



    //
    return (<div className={"pt-0"}>




        <div className="row">
            <div className="col-12">


                <h4><span className="fa fa-user"></span> Perfil </h4>

                <hr />



                <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off" role="presentation" className="p-40">


                    <div className="row">
                        <div className="col-md-9 col-sm-8 col-12">


                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Nombre(s) Contacto</label>
                                        <input type="text" className="form-control input-lg"
                                               name="first_name" id="first_name"
                                               placeholder="Nombre(s) Contacto"
                                               {...register('first_name', { required: 'introduce tu nombre(s)' })}
                                        />
                                        {errors.first_name && <label className="error">{errors.first_name.message}</label>}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Apellido(s) Contacto</label>
                                        <input type="text" className="form-control input-lg"
                                               name="last_name" id="last_name"
                                               placeholder="Apellido(s) Contacto"
                                               {...register('last_name', { required: 'introduce tu apellido(s)' })}
                                        />
                                        {errors.last_name && <label className="error">{errors.last_name.message}</label>}
                                    </div>
                                </div>
                            </div>



                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Email</label>
                                        <input type="text" className="form-control input-lg"
                                               name="email" id="email" placeholder="Tu@Email"
                                               value={email}
                                               disabled
                                        />
                                    </div>
                                    {/*{(customer_data && customer_data.allow_send_sms) ? (<div>Permitido Enviar SMS</div>) : null}*/}
                                    {/*{(customer_data && customer_data.allow_receive_sms) ? (<div>Permitido Recibir SMS</div>) : null}*/}
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3 col-sm-4 col-12 text-center">

                            <label> Imagen de Perfil </label><br/>
                            {getProfileImg()}

                            <div className="row">
                                <div className="col-12">
                                    <input type="file" id="cust_img" name="cust_img"
                                           accept="image/*"
                                           style={{visibility:"hidden"}}
                                           {...register('cust_img', {
                                               onChange: onChangeProfileImg
                                           })}
                                    />
                                    {errors.cust_img && <label className="error">{errors.cust_img.message}</label>}

                                </div>
                            </div>


                            <button className="btn btn-sm btn-outline-primary mt-2"
                                    type="button" onClick={btnUpdateProfileImage}><span
                                className="fa fa-image"></span> seleccionar
                            </button>

                        </div>
                    </div>



                    <div className="row mt-2">
                        <div className="col-12 pl-2 text-right">
                            <button type="submit" className="btn btn-primary"><span
                                className="fa fa-save"></span> Actualizar
                            </button>
                        </div>
                    </div>

                </form>

            </div>
        </div>




        <CustomModal
            modalHeader={true}
            title="Confirma tu Clave"
            content={<ConfirmPwd onSetPwd={onSetPwd} />}
            onClose={handleCloseModal}
            showModal={modal_pwd}
        />


    </div>)





}



export default withAuthCtx(withAppCtx(withNavigate(Profile)));