import React, { useContext, useState, useRef, useEffect } from 'react';
import {NavLink, Outlet} from 'react-router-dom';
import {config} from "../../Config";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {withAppCtx} from "../../Helpers/withAppCtx";
import PageHeader from "../Common/PageHeader";
import PageFooter from "../Common/PageFooter";
import {DivAlert, setContextData} from "../../Helpers/Helper";
import {withNavigate} from "../../Helpers/withNavigate";


//
const CustomerMain = ({token, navigate, location, context}) => {


    const [pathname, setModalCoseRequest] = useState("");
    


    const headerAlerts = () => {

        //
        let {data} = context;
        let {userData} = token;

        // Si el customer tiene una Suscripcion valida
        if (userData && userData.has_valid_subs) {
            //
            if (data && !data.msg_dsms_ch1 ){
                //
                return  (
                    <DivAlert
                        type={"success"}
                        className={"mt-2 mb-2"}
                        onClose={() => {
                            setContextData(context, "msg_dsms_ch1", true)
                        }}
                        msg={"Ya cuentas con una suscripcion valida, ahora tendras mayor alcance"}
                    />
                )
            }
        }
        // si no tiene suscripcion valida y no es seccion subscribe
        else if (location.pathname !== "/account/subscribe") {
            //
            return  (
                <DivAlert
                    type={"info"}
                    className={"mt-4 mb-4"}
                    icon={(<span className={"fas fa-gift"}></span>)}
                    hideCloseBtn={true}
                    msg={(<span>
                        Aumenta tu cantidad de busquedas diarias y tu radio de alcanze, llega a mas lugares para encontrar lo que buscas
                        &nbsp; <button type="button" className={"btn btn-sm btn-success"}
                                       onClick={() => {
                                           navigate("/account/subscribe");
                                       }}> Suscribete Aqui <span className={"fas fa-arrow-right"}></span></button>
                    </span>)}
                />
            )
        }
    }


    //
    return (
        <>
        
            { ( config.appId === config.AppIdP1 ) && (<PageHeader />)}
            { ( config.appId === config.AppIdP2 ) && (<PageHeader />)}
            { ( config.appId === config.AppIdP3 ) && (<PageHeader />)}

            <div className="container" style={{marginTop:80, marginBottom:120}}>

                {/*headerAlerts()*/}
                <Outlet />

            </div>

            { ( config.appId === config.AppIdP1 ) && (<PageFooter />)}
            { ( config.appId === config.AppIdP2 ) && (<PageFooter />)}
            { ( config.appId === config.AppIdP3 ) && (<PageFooter />)}

        </>
    )


}


export default withAuthCtx(withAppCtx(withNavigate(CustomerMain)));