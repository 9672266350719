import React from 'react';
import {config} from "../../Config";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {withAppCtx} from "../../Helpers/withAppCtx";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";

// AUTH WRAPPER
export const EmptyPageWrapper = ({ children  }) => (
    <div className="container-fluid" style={{marginTop:40, marginBottom:120}}>

        <div className="row mb-5">
            <div className="offset-xl-4 offset-lg-4 offset-md-1 offset-sm-1 col-xl-4 col-lg-4 col-md-10 col-sm-10 col-12">
                {children}
            </div>
        </div>

        { ( config.appId === config.AppIdP3 ) && (<PageFooter />)}

    </div>
);

// PAGE WRAPPER
let pageWrapper = ({ children, token, context }) => {
    return(
        <div className="container" style={{marginTop:80, marginBottom:120}}>


            { ( config.appId === config.AppIdP1 ) && (<PageHeader />)}
            { ( config.appId === config.AppIdP2 ) && (<PageHeader />)}
            { ( config.appId === config.AppIdP3 ) && (<PageHeader />)}

            {children}

            { ( config.appId === config.AppIdP1 ) && (<PageFooter />)}
            { ( config.appId === config.AppIdP2 ) && (<PageFooter />)}
            { ( config.appId === config.AppIdP3 ) && (<PageFooter />)}

        </div>
    );
}
export const PageWrapper = withAuthCtx(withAppCtx(pageWrapper));

