import React from 'react';
import { SocketCtx } from '../SocketCtx';

export const withSocket = (Component) => {
    return class extends React.Component {
        render() {
            return (
                <SocketCtx.Consumer>
                    {(contextData) => <Component {...this.props} soc={contextData} />}
                </SocketCtx.Consumer>
            );
        }
    };
};
