import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {config} from "../Config";
import { useNavigate } from 'react-router-dom';
import {withAppCtx} from "../Helpers/withAppCtx";
import { useForm } from 'react-hook-form';
import SelectLoader from "../Helpers/SelectLoader";
import {getLogo, postApi, setContextData, toFormData} from "../Helpers/Helper";
import {confirmAlert} from "react-confirm-alert";
import TerminosYCondiciones from "./Common/TerminosYCondiciones";
import CustomModal from "./Common/modals/CustomModal";
import BlockUi from 'react-block-ui';


//
const CreateAccount = ({ context }) => {



    //
    const navigate = useNavigate();
    const {register, setFocus, setValue, getValues, handleSubmit, formState: { errors }} = useForm();
    const [default_phone_country_id, setDefaultPhoneCountryId] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [is_loading, setIsLoading] = useState(false);
    //
    const [modal_terminos_condiciones, setModalTerminosCondiciones] = useState('');


    useEffect(() => {


        //
        let lang = context.getLang();
        console.log("---lang: ", lang);
        console.log("---CreateAccount Context: ", context);



        // Seccion que si esta auth redirecciona
        if (context.getAuth()){ navigate("/account");}



        // set initial select value: 379 467
        setDefaultPhoneCountryId(lang.lang_country_id);



        // set dfaults
        setValue('phone_country_id', lang.lang_country_id);
        setValue('request_identifier', context.visitorId);

        //
        document.title = config.AppName + " - Crear Cuenta de Cliente";

    }, []);




    //
    const onSubmit = (data) => {


        confirmAlert({
            message: 'Los datos estan correctos?',
            buttons: [
                {
                    label: 'Si',
                    onClick: function(){

                        //
                        if (is_loading){
                            console.log("is loading");
                            return;
                        }
                        //
                        setIsLoading(true);
                        //
                        postApi("/auth/register-customer", toFormData(data), function(success, data){
                            console.log("---post create customer: ", data);
                            //
                            if (success){
                                //
                                let the_data = {
                                    type: 'reg-custmr',
                                    id: data.id,
                                    contact_name: data.first_name + " " + data.last_name,
                                    email: data.email
                                };
                                //
                                setContextData(context, 'reg', the_data);
                                //
                                navigate("/activate-account");
                            }
                            //
                            setIsLoading(false);
                        }, {
                            conteTypeForm: true
                        });

                    }
                },
                {
                    label: 'No',
                    onClick: function(){

                    }
                }
            ]
        });

    };




    //
    const handleSelectChange = (event) => {
        setDefaultPhoneCountryId(event.target.value);
    };

    //
    const handleIsBiz = () => {
        //
        setIsChecked(!isChecked);
        //
        setTimeout(function(){
            setFocus("company_name")
        }, 150);
    };






    //
    return (<>


        <NavLink className="login-back" to="/"><span className="mdi mdi-chevron-left"></span></NavLink>

        <div className="text-center mt-5 mb-5 login-main-left-header pt-0 mr-0">
            <NavLink to="/"><img src={process.env.PUBLIC_URL + getLogo()} className="sect-logo"
                                 alt="LOGO" /></NavLink>
            <h5 className="mt-3 mb-3">Crear Cuenta</h5>
            <p>
                Regístrate para comenzar a buscar presupuestos
            </p>
        </div>

        <div className="text-center mt-5 mb-5">
            <p className="light-gray">Ya tienes una cuenta? <NavLink to="/sign-in"
                                                                     style={{textDecoration:"underline"}}>Ingresa Aqui</NavLink>
            </p>
        </div>

        <BlockUi blocking={is_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> Enviando Solicitud </div>)}>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">


                <h5 className="mt-5 mb-3 border-title"><span className={"fas fa-user"}></span> Informacion </h5>
                <br/>

                <div className="row">
                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label >Nombre(s)</label>
                            <input type="text" className="form-control input-lg" name="first_name" id="first_name"
                                   placeholder="Nombre(s)"
                                   {...register('first_name', { required: 'introduce tu nombre(s)' })}
                            />
                            {errors.first_name && <label className="error">{errors.first_name.message}</label>}
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label >Apellido(s)</label>
                            <input type="text" className="form-control input-lg" name="last_name" id="last_name"
                                   placeholder="Apellido(s)"
                                   {...register('last_name', { required: 'introduce tu apellido(s)' })}
                            />
                            {errors.last_name && <label className="error">{errors.last_name.message}</label>}
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-sm-4 col-md-4">
                        <div className="form-group">

                            <label className="">Codigo Pais</label>
                            <SelectLoader
                                url={config.api_url + "/public/paises/list"}
                                name_id={"phone_country_id"}
                                empty_value={"--selecciona"}
                                value={default_phone_country_id}
                                onChange={handleSelectChange}
                                parse={(item) => "+" + item.phone_cc + " (" + item.abreviado + ")"}
                                register={{...register("phone_country_id", {
                                        required: 'selecciona el pais',
                                        onChange: handleSelectChange
                                    })}}
                            />
                            {errors.phone_country_id && <label className="error">{errors.phone_country_id.message}</label>}

                        </div>
                    </div>
                    <div className="col-sm-8 col-md-8">
                        <div className="form-group">
                            <label >Telefono</label>
                            <input type="number" className="form-control no-spinners input-lg" name="phone_number" id="phone_number" placeholder="Telefono"
                                   {...register('phone_number', { required: 'introduce tu telefono' })}
                            />
                            {errors.phone_number && <label className="error">{errors.phone_number.message}</label>}
                        </div>
                    </div>                    
                </div>



                <h5 className="mt-5 mb-3 border-title"><span className={"fas fa-shop"}></span> Cuenta de Vendedor </h5>
                <br/>

                <div className="mb-20">
                    <input
                        type="checkbox"
                        id="is_biz"
                        name="is_biz"
                        checked={isChecked}
                        onChange={handleIsBiz}
                        {...register('is_biz', {
                            onChange: handleIsBiz
                        })}
                    />
                    <label htmlFor="is_biz"> &nbsp; quiero ser vendedor </label>
                </div>

                {(isChecked) ? (
                    <div className="row mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-group">
                                <label >Nombre del Negocio</label>
                                <input type="text" className="form-control input-lg" name="company_name" id="company_name"
                                       placeholder="...nombre del negocio"
                                       {...register('company_name', { required: 'introduce el nombre del negocio' })}
                                />
                                {errors.company_name && <label className="error">{errors.company_name.message}</label>}
                            </div>
                        </div>
                    </div>
                ) : (<hr />)}






                <h5 className="mt-5 mb-3 border-title"><span className={"fas fa-sign-in"}></span> Acceso a tu Cuenta <small>(recibiras un correo electronico)</small></h5>
                <br/>


                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                            <label >Email</label>
                            <input type="email" className="form-control input-lg" name="email" id="email"
                                   placeholder="Correo Electronico"
                                   {...register('email', {
                                       required: 'introduce tu email',
                                       validate: {
                                           matchPattern: (v) =>
                                               /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                               "introduce un email valido",
                                       }
                                   })}
                            />
                            {errors.email && <label className="error">{errors.email.message}</label>}
                        </div>
                    </div>
                </div>


                <div className="form-group">
                    <label >Clave</label>
                    <input type="password" className="form-control input-lg" name="password"
                           id="password" placeholder="Password"
                           {...register('password', { required: 'introduce tu clave' })}
                    />
                    {errors.password && <label className="error">{errors.password.message}</label>}
                </div>

                <div className="form-group">
                    <label >Confirmar Clave</label>
                    <input type="password" className="form-control input-lg" name="confirm_password" id="confirm_password"
                           placeholder="Confirm Password"
                           {...register('confirm_password', {
                               required: 'confirma tu clave',
                               validate: value => value === getValues("password") || 'la clave no es igual'
                           })}
                    />
                    {errors.confirm_password && <label className="error">{errors.confirm_password.message}</label>}
                </div>

                <div className="mb-5 mt-5">
                    <input type="checkbox" id="agree_terms" name="agree_terms"
                           {...register('agree_terms', { required: 'debes de aceptar los terminos para continuar' })}
                    />
                    <label htmlFor="agree_terms"> &nbsp; Acepto los
                        <NavLink onClick={() => setModalTerminosCondiciones(true)}>
                            Terminos y Condiciones
                        </NavLink>
                    </label>
                    <br />
                    {errors.agree_terms && <label className="error">{errors.agree_terms.message}</label>}
                </div>

                <div className="mt-4">
                    <div className="row">
                        <div className="col-12 pl-2">

                            <br />
                            <button type="submit" className="btn btn-primary btn-block btn-lg">Crear Cuenta</button>

                        </div>
                    </div>
                </div>
            </form>
        </BlockUi>


        <CustomModal
            showModal={modal_terminos_condiciones}
            modalSize={"modal-lg"}
            modalHeader={true}
            title={(<div><span className={"icofont-badge"}></span> Terminos y Condiciones </div>)}
            content={<TerminosYCondiciones />}
            onClose={() => setModalTerminosCondiciones(false)}
        />



    </>)

}

export default withAppCtx(CreateAccount);