//
export const config = {    
    //
    //AppName: window.appName,
    get AppName(){
        if (window.appId===11){
            return "MissionExpress";
        } else if (window.appId===13){
            return "Plabuz";
        } else if (window.appId===16){
            return "Tickets4Buses";
        }
    },
    host_url: window.hostUrl,
    appSessionPrefix: window.appName + '-ses',
    appId: window.appId,
    AppIdP1: 11, // MissioneExpress
    AppIdP2: 13, // Plabuz
    AppIdP3: 16, // Tickets4Buses
    //
    STRP_CARD: "stripe_card",
    STRP_OXXO: "stripe_oxxo",
    ANET_CARD: "authorizenet_card",
    SQR_CARD: "square_card",
    CASH: "cash",
    CREDIT: "credit",
    //
    company_type_id_autoparts: 11,
    //
    default_lat: 31.761877,
    default_lng: -106.485023,
    //
    socket_url: 'wss://32lcyupel3.execute-api.us-west-2.amazonaws.com/production',
    //
    PROD_TYPE_CUSTOMER_ID: 12,
    PROD_TYPE_STORE_ID: 13,
    //
    STORE_PR_STATUS_ID_AVAIL: 1,
    STORE_PR_STATUS_ID_NOT_AVAIL: 2,
    STORE_PR_STATUS_ID_DEAL: 3,
    STORE_PR_STATUS_ID_IGNORE: 4,
    //
    gooApiKey: 'AIzaSyAnefoaTTKkeSdSfD-s8vP1rkniL3ePBi8',
    //
    protocol: window.location.protocol,
    hostname: window.location.hostname,
    get app_domain(){
        return this.protocol + this.hostname;
    },

    storageKey: 'temp_sale_id',
    generateRandomId(){
        return Math.random().toString(36).substr(2, 9);
    },
    get temp_sale_id(){
        let tempSaleId = localStorage.getItem(this.storageKey);
        if (!tempSaleId) {
            tempSaleId = this.generateRandomId();
            localStorage.setItem(this.storageKey, tempSaleId);
        }
        return tempSaleId;
    },
    resetTempSaleId(){
        const newTempSaleId = this.generateRandomId();
        localStorage.setItem(this.storageKey, newTempSaleId);
    },

    //
    get api_url() {
        return `${this.host_url}/api`;
    },
    //
    get public_url() {
        return `${this.host_url}/public`;
    }
};

config.auth_cust_url = `${config.api_url}/customer`;
config.auth_store_url = `${config.api_url}/store`;