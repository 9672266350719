import React from 'react';
import { GeoLocationCtx } from '../GeoLocationCtx';

export const withGeoLocationCtx = (Component) => {
    return class extends React.Component {
        render() {
            return (
                <GeoLocationCtx.Consumer>
                    {(contextData) => <Component {...this.props} geo={contextData} />}
                </GeoLocationCtx.Consumer>
            );
        }
    };
};
