import React, { useContext, useState, useRef, useEffect } from 'react';
import {config} from "../../Config";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {withGeoLocationCtx} from "../../Helpers/withGeoLocationCtx";
import {
    IsCustomerLogged,
    getOrderItems,
    delSetContextData,
    getCAuth,
    socketSend, setContextData, delContextData
} from "../../Helpers/Helper";
//
import {GetOrderInfo, orderStatusId} from "../../Helpers/OrdersHelper";

import {NotificationManager} from "react-notifications";
import {withSocket} from "../../Helpers/withSocket";
import CancelOrder from "./../Common/CancelOrder";
import CustomModal from "./../Common/modals/CustomModal";
import CalificarOrder from "./../Common/CalificarOrder";
import {useNavigate} from "react-router-dom";
import {SocketCtx} from "../../SocketCtx";


const MyOrder = ({ context, geo }) => {



    //
    const socketContext = useContext(SocketCtx);
    const navigate = useNavigate();
    //
    const [new_order_id, setNewOrderId] = useState(null);
    const [order_info, setOrderInfo] = useState(null);
    //
    const [modal_cancel, openModalCancel] = useState(null);
    const [modal_calific, openModalCalific] = useState(null);


    //
    useEffect(() => {


        //
        document.title = config.AppName + " - My Order";
        console.log("---MyOrder Context: ", context);

        //
        if (context.data.new_order_id){
            setNewOrderId(context.data.new_order_id);
        } else {
            navigate("/");
        }

    }, []);








    //
    const onMessages = (msg_data) => {
        //
        if (msg_data && msg_data.action_type && msg_data.action_type === "request-order" ){
            //
            delSetContextData(context, "order", "new_order_id", msg_data.new_order_id);
            setNewOrderId(msg_data.new_order_id);
        }
        //
        else if (msg_data && msg_data.action_type && msg_data.action_type === "update-order-status" ){
            //
            loadOrderInfo(msg_data.order_id);
        }
        //
        else if (msg_data && msg_data.action_type && msg_data.action_type === "calific-order-results" ){
            if ( !msg_data.isError ){
                delContextData(context, "new_order_id");
                navigate("/");
            }
        }
    }

    //
    useEffect(() => {
        //
        if (socketContext.msg_data) {
            onMessages(socketContext.msg_data);
        }
    }, [socketContext.msg_data]);











    /*
    * Efecto para cargar la info de la orden
    * */
    useEffect(() => {
        console.log("---calling new_order_id effect", new_order_id);
        if (new_order_id) {
            loadOrderInfo(new_order_id);
        }
    }, [new_order_id]);


    //
    const loadOrderInfo = (order_id) => {
        //
        getCAuth(context.data.auth, "/orders/" + order_id,  function(success, data){
            console.log("---customer order: ", data);
            if (success){

                //
                if (
                    data.status_id  === orderStatusId.CUSTOMER_CANCELLED ||
                    data.status_id  === orderStatusId.STORE_CANCELLED
                ){
                    //
                    delContextData(context, "new_order_id");
                    navigate("/");
                } else {
                    //
                    setOrderInfo(data);
                }

            } else {
                delContextData(context, "new_order_id");
                navigate("/");
            }
        });
    }





    // order items reduced
    const getOrderItemsReduced = () => {
        //
        let arr_order = getOrderItems(context);
        //
        let arr_order_items = [];
        //
        arr_order.forEach(function(item, index){
            //console.log(item, index);
            arr_order_items.push({
                id: item.id,
                qty: 2
            })
        });
        return arr_order_items;
    }







    //
    const btnCalificar = () => {
        openModalCalific(true);
    }





    //
    const onSubmitCancel = (post_data) => {
        //console.log(post_data); return;

        //
        let socket_post_data = {
            order_id: new_order_id,
            status_id: orderStatusId.CUSTOMER_CANCELLED,
            status_notes: post_data.status_notes
        }
        socketSend(socketContext, socket_post_data, "update-order-status");

        //
        openModalCancel(false);
    }

    //
    const handleCloseModalCancel = () => {
        openModalCancel(false)
    }



    //
    const btnUpdateOrderStatus = (new_status_id) => {

        // Si cancela
        if ( new_status_id === orderStatusId.CUSTOMER_CANCELLED ){
            openModalCancel(true);
        } else {

            //
            let socket_post_data = {
                order_id: new_order_id,
                status_id: new_status_id
            }
            socketSend(socketContext, socket_post_data, "update-order-status");
        }

    }






    //
    const onSubmitCalific = (post_data) => {
        console.log(post_data);
        //
        post_data.order_id = new_order_id;
        socketSend(socketContext, post_data, "calific-order");
        //
        openModalCalific(false);
    }
    //
    const handleCloseModalCalific = () => {
        openModalCalific(false)
    }








    //
    return (<>




        <section className="section/-padding homepage-search-block position-relative mt-5">
            <div className="container">



                { (order_info && order_info.id) && (
                    <GetOrderInfo
                        view_type={"for_customer"}
                        order_info={order_info}
                        btnUpdateOrderStatus={btnUpdateOrderStatus}
                        btnCalificar={btnCalificar}
                    />
                )}



            </div>
        </section>



        <CustomModal
            modalHeader={true}
            title={"Cancelar Orden #" + new_order_id}
            content={<CancelOrder onSubmitData={onSubmitCancel} />}
            onClose={handleCloseModalCancel}
            showModal={modal_cancel}
        />

        <CustomModal
            modalHeader={true}
            title={"Calificar Orden #" + new_order_id}
            content={<CalificarOrder onSubmitData={onSubmitCalific} />}
            onClose={handleCloseModalCalific}
            showModal={modal_calific}
        />





    </>)


}


export default withAppCtx(withGeoLocationCtx(MyOrder));