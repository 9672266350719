import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

//
const ConfirmPwd = ({ onSetPwd }) => {


    //
    const {register, handleSubmit, setFocus, formState: { errors }} = useForm();

    //
    useEffect(() => {
        //
        setFocus("the_pwd");
    }, []);




    //
    const onSubmit = (data) => {
        //console.log("---- submit data: ", data);
        if  (onSetPwd){
            onSetPwd(data.the_pwd);
        }
    };




    //
    return (<>


        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">

            <div className="row">
                <div className="col-12">
                    <div className="form-group">

                        <label className="">Clave</label>
                        <input type="password" className="form-control" name="the_pwd" id="the_pwd"
                               data-rule-required="true" data-rule-minlength="2"
                               data-rule-maxlength="30" placeholder="" autoComplete="off"
                               {...register('the_pwd', { required: 'introduce tu clave' })}
                        />

                        {errors.the_pwd && <label className="error">{errors.the_pwd.message}</label>}
                    </div>
                </div>
            </div>




            <div className="mt-2 mb-2">
                <div className="row">
                    <div className="col-12 pl-2 text-center">

                        <button type="submit" className="btn btn-primary">Confirmar</button>


                    </div>
                </div>
            </div>


        </form>



    </>)

}


export default ConfirmPwd;