import React, { useCallback, useState, useRef, useEffect } from 'react';
import {NavLink} from "react-router-dom";

export const orderStatusId = {
    REQUESTED: 2,
    WORKING: 4,
    READY_FOR_PICKUP: 5,
    STORE_PROVIDED: 6,
    CUSTOMER_CANCELLED: 7,
    STORE_CANCELLED: 8
}



//
export const GetCustomerBtnType = ({order_info, btnUpdateOrderStatus, btnCalificar}) => {
    //
    if ( order_info.status_id === orderStatusId.REQUESTED ){
        return (<div>

            <h5 className={"text-success"}> esperando respuesta del negocio </h5>
            {order_info.status_notes && (<div className={"fontMovil-sm text-info"}><span className={"fas fa-info-circle"}></span> {order_info.status_notes} </div>)}

            <button type="button" onClick={() => btnUpdateOrderStatus(orderStatusId.CUSTOMER_CANCELLED)} className={"btn btn-link text-warning"}><span className={"fas fa-times"}></span> cancelar pedido </button>

        </div>)
    } else if ( order_info.status_id === orderStatusId.WORKING ){
        return (<div>

            <h5 className={"text-primary"}> tu orden se esta trabajando </h5>
            {order_info.status_notes && (<div className={"fontMovil-sm text-info"}><span className={"fas fa-info-circle"}></span> {order_info.status_notes} </div>)}

            <button type="button" onClick={() => btnUpdateOrderStatus(orderStatusId.CUSTOMER_CANCELLED)} className={"btn btn-link text-warning"}><span className={"fas fa-times"}></span> cancelar pedido </button>

        </div>)
    } else if ( order_info.status_id === orderStatusId.READY_FOR_PICKUP ){
        return (<div>

            <h5 className={"text-primary"}>Tu Orden esta lista en la Tienda</h5>
            {order_info.status_notes && (<div className={"fontMovil-sm text-info"}><span className={"fas fa-info-circle"}></span> {order_info.status_notes} </div>)}

            <div className={"text-light"}>despues de entregado podras calificar negocio</div>
            <button type="button" onClick={() => btnUpdateOrderStatus(orderStatusId.CUSTOMER_CANCELLED)} className={"btn btn-link text-warning"}><span className={"fas fa-times"}></span> cancelar pedido </button>

        </div>)
    } else if ( order_info.status_id === orderStatusId.STORE_PROVIDED ) {
        return (<div>

            <h5 className={"text-secondary"}>Orden recibida el {order_info.status_datetime.date}</h5>
            {order_info.status_notes && (<div className={"fontMovil-sm text-info"}><span className={"fas fa-info-circle"}></span> {order_info.status_notes} </div>)}


            { ( btnCalificar && !order_info.calific_store_datetime ) && (
                <button
                    type="button"
                    onClick={() => btnCalificar()}
                    className={"btn btn-link"}><span className={"fas fa-star"}></span> Calificar Negocio
                </button>
            )}

        </div>)
    } else if ( order_info.status_id === orderStatusId.STORE_CANCELLED ){
        return (<h5 className={"text-info"}> Tienda Cancelo la Orden <span className={"text-danger"}>{order_info.status_notes && order_info.status_notes}</span> </h5>)
    } else if ( order_info.status_id === orderStatusId.CUSTOMER_CANCELLED ) {
        return (<h5 className={"text-info"}> Cancelaste la orden <span
            className={"text-danger"}>{order_info.status_notes && order_info.status_notes}</span></h5>)
    }
}


//
export const GetStoreBtnType = ({order_info, btnUpdateOrderStatus, btnCalificar}) => {
    //
    if ( order_info.status_id === orderStatusId.REQUESTED ){
        return (<div>

            <h5 className={"text-success"}>El cliente esta esperando que aceptes su orden</h5>
            {order_info.status_notes && (<div className={"fontMovil-sm text-info"}><span className={"fas fa-info-circle"}></span> {order_info.status_notes} </div>)}

            <button
                type="button"
                onClick={() => btnUpdateOrderStatus(orderStatusId.WORKING)}
                className={"btn btn-primary"}> Aceptar Orden y trabajarla </button>

            <button type="button" onClick={() => btnUpdateOrderStatus(orderStatusId.STORE_CANCELLED)} className={"btn btn-link text-warning"}><span className={"fas fa-times"}></span> cancelar pedido </button>

        </div>)
    } else if ( order_info.status_id === orderStatusId.WORKING ){
        return (<div>

            <h5 className={"text-primary"}>Orden trabajandose</h5>
            {order_info.status_notes && (<div className={"fontMovil-sm text-info"}><span className={"fas fa-info-circle"}></span> {order_info.status_notes} </div>)}

            <button
                type="button"
                onClick={() => btnUpdateOrderStatus(orderStatusId.READY_FOR_PICKUP)}
                className={"btn btn-primary"}> Cambiar a lista para recoger </button>

            <button type="button" onClick={() => btnUpdateOrderStatus(orderStatusId.STORE_CANCELLED)} className={"btn btn-link text-warning"}><span className={"fas fa-times"}></span> cancelar pedido </button>

        </div>)
    } else if ( order_info.status_id === orderStatusId.READY_FOR_PICKUP ){
        return (<div>

            <h5 className={"text-primary"}>La orden esta lista para recoger</h5>
            {order_info.status_notes && (<div className={"fontMovil-sm text-info"}><span className={"fas fa-info-circle"}></span> {order_info.status_notes} </div>)}

            <button
                type="button"
                onClick={() => btnUpdateOrderStatus(orderStatusId.STORE_PROVIDED)}
                className={"btn btn-primary"}> Orden Entregada </button>

            <button type="button" onClick={() => btnUpdateOrderStatus(orderStatusId.STORE_CANCELLED)} className={"btn btn-link text-warning"}><span className={"fas fa-times"}></span> cancelar pedido </button>

        </div>)
    } else if ( order_info.status_id === orderStatusId.STORE_PROVIDED ) {
        return (<div>

            <h5 className={"text-secondary"}>Orden entregada el {order_info.status_datetime.date}</h5>
            {order_info.status_notes && (<div className={"fontMovil-sm text-info"}><span className={"fas fa-info-circle"}></span> {order_info.status_notes} </div>)}

            { (btnCalificar && !order_info.calific_cliente_datetime) && (
                <button
                    type="button"
                    onClick={() => btnCalificar()}
                    className={"btn btn-link"}><span className={"fas fa-star"}></span> Calificar Cliente
                </button>
            )}


        </div>)
    } else if ( order_info.status_id === orderStatusId.STORE_CANCELLED ){
        return (<h5 className={"text-info"}> Cancelaste la orden <span className={"text-danger"}>{order_info.status_notes && order_info.status_notes}</span> </h5>)
    } else if ( order_info.status_id === orderStatusId.CUSTOMER_CANCELLED ){
        return (<h5 className={"text-info"}> Cliente cancelo la orden <span className={"text-danger"}>{order_info.status_notes && order_info.status_notes}</span> </h5>)
    }
}


//
export function GetOrderInfo({view_type, order_info, btnUpdateOrderStatus, btnCalificar}){



    //
    return (<div style={{padding:"10px", border:"1px solid gray"}}>



        {(view_type === "for_customer") ? (
            <>

                <div>Orden Folio: {order_info.id}</div>
                <div>Tienda: {order_info.store_name} </div>

                {(order_info.arr_items && order_info.arr_items.length) && (
                    <>
                        <div> {order_info.arr_items.length} Articulos </div>
                        {order_info.arr_items.map((item, index) => (<ul key={index}>
                            <li>
                                (x{item.qty}) {item.item_info} {item.final_amount}
                            </li>
                        </ul>))}
                    </>
                )}

                <div>Total: {order_info.grand_total} </div>
                <div> {order_info.notes} </div>

                <hr />

                <GetCustomerBtnType
                    order_info={order_info}
                    btnUpdateOrderStatus={btnUpdateOrderStatus}
                    btnCalificar={btnCalificar}
                />
                {/*<NavLink to={"/account"} className={"btn btn-sm btn-primary"}><span className={"fas fa-reply"}></span> Regresar </NavLink>*/}

            </>
        ) : (
            <>

                <div>Orden Folio: {order_info.id}</div>
                <div>Cliente: {order_info.customer_name} </div>

                {(order_info.arr_items && order_info.arr_items.length) && (
                    <>
                        <div> {order_info.arr_items.length} Articulos </div>
                        {order_info.arr_items.map((item, index) => (<ul key={index}>
                            <li>
                                (x{item.qty}) {item.item_info} {item.final_amount}
                            </li>
                        </ul>))}
                    </>
                )}

                <div>Total: {order_info.grand_total} </div>
                <div> {order_info.notes} </div>

                <hr />


                <GetStoreBtnType
                    order_info={order_info}
                    btnUpdateOrderStatus={btnUpdateOrderStatus}
                    btnCalificar={btnCalificar}
                />
                <NavLink to={"/store/account/orders"} className={"btn btn-sm btn-primary"}><span className={"fas fa-reply"}></span> Regresar </NavLink>

            </>
        )}






    </div>);
}
