import React, { useCallback, useState, useRef, useEffect } from 'react';
import {NavLink} from "react-router-dom";
import {fmtDateSpanish, LoadingImg, ucFirst, convertirMinutosADiasHorasMinutos, shortenString} from "./Helper";
import {config} from "../Config";




export const CustomerRequestItem = ({item, userData, onSelect}) => {


    //
    let str_btn_type = "btn-light";
    let item_status_info = null;


    //
    if (item.cancel_datetime && item.cancel_datetime.date){
        item_status_info = (<small className={"text-secondary"}> Cancelado el {fmtDateSpanish(item.cancel_datetime.date, true)} </small>)
    }
    //
    else if (item.deal_datetime && item.deal_datetime.date){

        //
        if (item.deal_with_store_id){
            //
            item_status_info = (<>
                <small className={"text-success"}> Trato con <strong>{item.deal_with_store_name}</strong> <br />{fmtDateSpanish(item.deal_datetime.date, true)} </small>
            </>)
        } else {
            //
            item_status_info = (<>
                <small className={"text-success"}> Se Adquirio en otro lugar - {fmtDateSpanish(item.deal_datetime.date, true)} </small>
            </>)
        }

    }
    //
    else if (item.cant_resp) {
        str_btn_type = "btn-outline-success-2";
        item_status_info = (
            <div className={"mt-2"}> <span className={"badge badge-success"}>{item.cant_resp}</span> Respuestas </div>
        )
    }
    //
    else {

        // si ha pasado mas de 1 hora entonces mostramos otro tipo de mensaje
        if ( item.transcurred_mins > 60 ){
            item_status_info = (
                <small className={"text-primary"}> Esperando respuestas desde hace {convertirMinutosADiasHorasMinutos(item.transcurred_mins)}...</small>
            )
        } else {
            str_btn_type = "btn-outline-success-2";
            item_status_info = (
                <small className={"text-success"}> <LoadingImg /> Enviado, Esperando Respuestas... </small>
            )
        }


    }


    //
    return (
        <li className="item no-dots" style={{padding: 5, borderBottom:"1px solid #eee", minHeight:50, marginTop:5, width:"100%", backgroundColor:"#fff" }}>

            {/*onClick={(item.deal_datetime) ? null : onSelect}>*/}
            <a className={'btn ' + str_btn_type} style={{width:"100%"}} onClick={onSelect}>
                <div className="row">
                    <div className="col-md-8 col-12 text-left">

                        <div className={"text-wrap"}>
                            <div style={{}}><span className={"fas fa-arrow-right"} style={{fontSize:"12px"}}></span>

                                &nbsp; Presupuesto para  { ucFirst(shortenString(item.parts_info, 160)) }

                            </div>

                            <div className={"font-weight-bolder"}> {item.marca}/{item.submarca}/{item.modelo_anio}/{item.cilindros} Cilindros </div>
                            <small style={{color:"#333"}}> folio: {item.id}, fecha: {fmtDateSpanish(item.datetime_created.date, true)} </small>

                        </div>

                    </div>
                    <div className="col-md-4 col-12 text-left">
                        <div className={""}> {item_status_info} </div>

                    </div>
                </div>
            </a>

        </li>
    )
}









export const StoreRequestItem = ({item, userData, onSelect}) => {


    //
    let str_btn_type = "btn-light";
    let item_status_info = null;



    //
    let str_cant_respuestas = null;
    // se cuenta a partir de 2 por que el 1er y 2do mensajes son creados por el cliente en el usp
    if ( item.cant_sent_by_customer > 2 ){
        str_cant_respuestas = (
            <div> <span className={"badge badge-success"}>{item.cant_sent_by_customer}</span> Respuestas </div>
        )
    }

    //
    if (item.cancel_datetime && item.cancel_datetime.date){
        item_status_info = (
            <small className={"text-secondary"} className={"text-secondary"}> Cancelada </small>
        )
    }
    //
    else if (item.deal_datetime && item.deal_datetime.date){

        //
        if (item.deal_with_me ){
            str_btn_type = "btn-outline-success-2";
            item_status_info = (<>
                <small className={"text-success"}> Cliente Te Selecciono - {fmtDateSpanish(item.status_datetime.date, true)} </small>
                {str_cant_respuestas}
            </>)
        } else {
            item_status_info = (<small className={"text-secondary"}> Cerrada </small>)
        }
    }
    //
    else if (item.status_id === config.STORE_PR_STATUS_ID_AVAIL ){
        str_btn_type = "btn-outline-success-2";
        item_status_info = (<>
            <small className={"text-success"}> Pieza Disponible </small>
            {str_cant_respuestas}
        </>)
    }
    //
    else if (item.status_id === config.STORE_PR_STATUS_ID_NOT_AVAIL ){
        item_status_info = (<small className={"text-secondary"}> Pieza No Disponible </small>)
    }
    //
    else if (item.status_id === config.STORE_PR_STATUS_ID_IGNORE ){
        item_status_info = (<small className={"text-secondary"}> Ignorada </small>)
    }
    //
    else {
        // si ha pasado mas de 1 hora entonces mostramos otro tipo de mensaje
        if ( item.transcurred_mins > 60 ){
            //
            item_status_info = (
                <small className={"text-primary"}> Esperando respuesta desde hace {convertirMinutosADiasHorasMinutos(item.transcurred_mins)}... </small>
            )
        } else {
            str_btn_type = "btn-outline-success-2";
            item_status_info = (
                <small className={"text-success"}> <LoadingImg /> Esperando Respuesta... </small>
            )
        }
    }




    const getCustomerProfileImg = (item) => {
        //
        if (item.profile_img){
            return (<img style={{width:25, borderRadius:20}} src={item.profile_img} />)
        }
        //
        else if (item.customer_img_ext){
            let cust_profile_url = config.host_url + "/files/customers/" + item.customer_id + "/profile/me." + item.customer_img_ext;
            return (<img style={{width:25, borderRadius:20}} src={cust_profile_url} />)
        }
        else {
            return (<span className="divRoundedSmall">{item.customer_initials}</span>);
        }
    }



    const customerInfo = () => {
        //
        if ( userData && userData.has_valid_subs ){
            return (<strong> {ucFirst(item.customer_name)} </strong>);
        } else {
            return (<strong>[CLIENTE]</strong>);
        }
    }


    //
    return (
        <li className="item no-dots" style={{padding: 5, minHeight:50, marginTop:5, width:"100%", backgroundColor:"#fff" }}>

            {/*onClick={(item.deal_datetime) ? null : onSelect}>*/}
            <a className={'btn ' + str_btn_type} style={{width:"100%"}} onClick={onSelect}>
                <div className="row">
                    <div className="col-md-8 col-12 text-left">

                        <div className={"text-wrap"}>
                            <div style={{}}>

                                {getCustomerProfileImg(item)} {customerInfo(item)} Presupuesto para { ucFirst(shortenString(item.parts_info, 160)) }

                            </div>

                            <div className={"font-weight-bolder"}> {item.marca}/{item.submarca}/{item.modelo_anio}/{item.cilindros} Cilindros </div>
                            <small style={{color:"#333"}}> folio: {item.id}, fecha: {fmtDateSpanish(item.datetime_created.date, true)} </small>

                        </div>

                    </div>
                    <div className="col-md-4 col-12 text-left">

                        <div className={""}> {item_status_info} </div>

                    </div>
                </div>
            </a>

        </li>
    )
}

