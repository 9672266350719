import React, { useContext, useState, useRef, useEffect } from 'react';
import {config} from "../../Config";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {NavLink} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {confirmAlert} from "react-confirm-alert";
import {DivAlert, getCAuth, getComisionInfo, IsCustomerLogged, isSeller, mDateFormat, post, postCAuth, readFileToSrc, socketSend, toFormData} from "../../Helpers/Helper";
import SelectLoader from "../../Helpers/SelectLoader";
import {NotificationManager} from "react-notifications";
import {SocketCtx} from "../../SocketCtx";
import BlockUi from 'react-block-ui';
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {withNavigate} from "../../Helpers/withNavigate";
import ConfirmPwd from "../Common/ConfirmPwd";
import CustomModal from "../Common/modals/CustomModal";
import ConfirmRequest from "./Forms/ConfirmRequest";
import TerminosYCondiciones from "../Common/TerminosYCondiciones";

import { FrontPageVentasParser } from "../../Helpers/FormatParser";


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Button from 'react-bootstrap/Button';

import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import moment from "moment";




const CustomerIndex = ({ context, navigate, params, token, geo }) => {




    
    const [arr_results, setResults] = useState([]);
    const [customer_data, setCustomerData] = useState(null);



    //
    let auth = context.getAuth();
    let is_seller = isSeller(auth);



    //
    const getVentas = () => {
        //
        getCAuth(auth, `/sales`, function(success, response){
            if (success){
                //
                setResults(response.data);
            }
        });
    };


    //
    const getCustomerData = () => {
        //
        getCAuth(context.data.auth, "",  function(success, data){
            console.log("---customer data: ", data);
            if (success){
                //
                setCustomerData(data);
            }
        });
    }

     

    
    //
    useEffect(() => {

        //
        getVentas();
        getCustomerData();

    }, []);

    
    



    const parseResultActions = (item) => {
        //
        return (<>
            
            <button type="button" onClick={() => {                
                //console.log("***selected sale: ", item)
                navigate("/account/sale-details/" + item.id);
            }} className="btn btn-sm btn-success"><span className="fa fa-eye"></span>&nbsp;Ver&nbsp;</button>

            </>);
    };





    //
    const postConfirmarVenta = (sale_id) => {
        //
        setResults(null);
        //
        //getCAuth(auth,
        postCAuth(auth, `/sales/` + sale_id + "/confirm", {}, function(success, response){
            if (success){
                NotificationManager.success("Venta aceptada correctamente");                
            }
            //
            getVentas();
        }, {

        });
    }
    


    //
    const confirmarVenta = (item) => {
        
        //
        confirmAlert({
            message: 'Al aceptar esta venta sera asignada a su usuario',
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: function(){
                        postConfirmarVenta(item.id);
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: function(){

                    }
                }
            ]
        });

    };


    //
    const parseCredito = (item) => {
        //
        return (<>
            {item.a_credito ? (
                <>
                    <div>Venta a credito: </div>

                    {item.seller_accepted ? (
                        <small style={{ color: 'green' }}>
                            <i className="fas fa-check" style={{ color: 'green' }}></i> Venta Acceptada
                        </small>
                    ) : (<div>
                            <button type="button" onClick={() => {confirmarVenta(item)}} className="btn btn-sm btn-primary"><span className="fa fa-rocket"></span> Aceptar Venta </button>
                    </div>)}
                </>
            ) : ""}
        </>)
    }






    //
    let company_name = "",
        ventas_title = "Mis Compras";

    //
    if (is_seller){
        company_name = " - " + auth.company_name;
        ventas_title = "Mis Ventas";
    }
    //
    return (<div className="pt-4 pb-4">
        <div className="row">
            <div className="col-12 text-left">

                <h4>{ventas_title} - {auth.first_name} {auth.last_name}{company_name}</h4>
                <small> Registered in <i>{auth.acct_app_name}</i> on <i>{auth.fecha_creado}</i></small>
                <hr />
                {(customer_data && customer_data.id) ? getComisionInfo(customer_data) : null}

                <style>
                {`
                    .p-datatable-table tr {
                        border-bottom: 1px solid #d6e3f5;
                    }
                `}
                </style>

                <div className="mt-5 card">
                    {is_seller ? (
                        <DataTable value={arr_results} size="small" tableStyle={{ minWidth: '50rem' }}>
                            <Column field="id" header="folio"></Column>
                            <Column field="id" header="Customer" body={FrontPageVentasParser().parseCustomerData}></Column>
                            <Column field="id" header="Items" body={FrontPageVentasParser().parseItems}></Column>
                            <Column field="id" header="Total / Payments" body={FrontPageVentasParser().parseTotalAndPayments}></Column>
                            <Column field="id" header="Date" body={FrontPageVentasParser().parseDate}></Column>
                            <Column field="id" header="Pagado" body={FrontPageVentasParser().parsePaid}></Column>
                            <Column field="id" header="Venta a credito" body={parseCredito}></Column>
                            <Column field="id" header="Comision" body={FrontPageVentasParser().parseComision}></Column>
                            <Column field="id" header="Venta" body={FrontPageVentasParser().parseVenta}></Column>
                            <Column header="" body={parseResultActions}></Column>
                        </DataTable>
                    ) : (
                        <DataTable value={arr_results} size="small" tableStyle={{ minWidth: '50rem' }}>
                            <Column field="id" header="folio"></Column>
                            <Column field="id" header="Customer" body={FrontPageVentasParser().parseCustomerData}></Column>
                            <Column field="id" header="Items" body={FrontPageVentasParser().parseItems}></Column>
                            <Column field="id" header="Total / Payments" body={FrontPageVentasParser().parseTotalAndPayments}></Column>
                            <Column field="id" header="Pagado" body={FrontPageVentasParser().parsePaid}></Column>   
                            <Column field="id" header="Date" body={FrontPageVentasParser().parseDate}></Column>                        
                            <Column header="" body={parseResultActions}></Column>
                        </DataTable>
                    )}                    
                </div>


            </div>
        </div>
    </div>)


}


export default withAuthCtx(withAppCtx(withNavigate(CustomerIndex)));