import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {config} from "../Config";
import { useNavigate } from 'react-router-dom';
import {withAppCtx} from "../Helpers/withAppCtx";
import {useForm} from "react-hook-form";
import qs from "qs";
import {postApi, setAuthAndClear, getLogo} from "../Helpers/Helper";
import BlockUi from 'react-block-ui';




const UpdatePassword = ({ context }) => {



    //
    const navigate = useNavigate();
    const {register, setValue, getValues, handleSubmit, formState: { errors }} = useForm();
    const [section_title, setSectionTitle] = useState(null);
    const [section_info, setSectionInfo] = useState(null);
    const [is_loading, setIsLoading] = useState(false);



    //
    useEffect(() => {



        //
        let lang = context.getLang();
        console.log("---lang: ", lang);
        console.log("---UpdatePassword Context: ", context.data);



        // Seccion que si esta auth redirecciona
        if (context.getAuth()){ navigate("/account");}



        //
        let {activation_code} = context.data;
        let {reg} = context.data;


        //
        if (reg && reg.id && activation_code){

            // set defaults
            setValue('id', reg.id);
            setValue('activation_code', activation_code);
            setValue('request_identifier', context.visitorId);
            //
            setSectionTitle("Actualizar Clave");
            setSectionInfo(<><strong> {reg.contact_name} </strong> introduce tu nueva clave de acceso para {reg.email} </>);

            //
            document.title = config.AppName + " " + "Actualizar Clave";

        } else {
            navigate("/");
        }

    }, []);





    //
    const onSubmit = (data) => {
        //
        if (is_loading){
            console.log("is loading");
            return;
        }
        //
        setIsLoading(true);
        //
        const postData = qs.stringify(data);
        console.log("---- updatePassword post data: ", data);
        //
        postApi("/auth/recover-update-pwd", postData, function(success, data){
            //
            if (success){
                //
                setAuthAndClear(context, data)
                //
                window.location.href = "/account";
            }
            //
            setIsLoading(false);
        });
    };


    //
    return (<>


        <NavLink className="login-back" to="/"><span className="mdi mdi-chevron-left"></span></NavLink>

        <div className="text-center mt-5 mb-5 login-main-left-header pt-0 mr-0">
            <NavLink to="/"><img src={process.env.PUBLIC_URL + getLogo()} className="sect-logo"
                                 alt="LOGO" /></NavLink>
            <h5 className="mt-3 mb-3"> {section_title} </h5>
            <p> {section_info} </p>
        </div>

        <BlockUi blocking={is_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> sending... </div>)}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">


                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                            <label >Nueva Clave</label>
                            <input type="password" className="form-control input-lg" name="password"
                                   id="password" placeholder="Password"
                                   {...register('password', { required: 'introduce tu clave' })}
                            />
                            {errors.password && <label className="error">{errors.password.message}</label>}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                            <label >Confirmar Clave</label>
                            <input type="password" className="form-control input-lg" name="confirm_password" id="confirm_password"
                                   placeholder="Confirm Password"
                                   {...register('confirm_password', {
                                       required: 'confirma tu clave',
                                       validate: value => value === getValues("password") || 'la clave no es igual'
                                   })}
                            />
                            {errors.confirm_password && <label className="error">{errors.confirm_password.message}</label>}
                        </div>
                    </div>
                </div>


                <div className="mt-4">
                    <div className="row">
                        <div className="col-12 pl-2">

                            <button type="submit" className="btn btn-primary btn-block btn-lg"> Enviar </button>

                        </div>
                    </div>
                </div>
            </form>
        </BlockUi>



    </>)


}

export default withAppCtx(UpdatePassword);