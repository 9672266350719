import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {config} from "../Config";
import { useForm } from 'react-hook-form';
import { withAppCtx } from '../Helpers/withAppCtx';
import { useNavigate } from 'react-router-dom';
import SelectLoader from "../Helpers/SelectLoader";
import {postApi, setAuthAndClear, toFormData, getLogo} from "../Helpers/Helper";
import BlockUi from 'react-block-ui';


// Login
const Login = ({ context }) => {


    //
    const navigate = useNavigate();
    const {register, setValue, handleSubmit, formState: { errors }} = useForm();
    const [is_loading, setIsLoading] = useState(false);



    //
    useEffect(() => {

        //
        let lang = context.getLang();
        console.log("---lang: ", lang);
        console.log("---SingIn Context: ", context);


        // Seccion que si esta auth redirecciona
        if (context.getAuth()){ navigate("/account");}




        // set dfaults
        setValue('email', "ivanjzr@gmail.com");
        setValue('password', "111111");
        setValue('request_identifier', context.visitorId);


        //
        document.title = config.AppName + " - Ingresar a Cuenta de Cliente";



    }, []);







    //
    const onSubmit = (data) => {
        //
        if (is_loading){
            console.log("is loading");
            return;
        }
        //
        setIsLoading(true);
        //
        postApi("/auth/login", toFormData(data), function(success, data){
            //
            if (success){
                //
                setAuthAndClear(context, data)
                // aqui debe de actualizar el path para que pueda re-definiar los paths mediante la sesion
                window.location.href = "/";
            }
            //
            setIsLoading(false);
        }, {
            conteTypeForm: true
        });
    };




    


    //
    return (<>




        <div className="text-center mt-5 mb-5 login-main-left-header pt-0 mr-0">
            <NavLink to="/"><img src={process.env.PUBLIC_URL + getLogo()} className="sect-logo" alt="LOGO" /></NavLink>
            <h5 className="mt-3 mb-3">Ingresar a tu Cuenta</h5>
            <p>Ingresa con tus accesos para continuar</p>
        </div>

        <div className="text-center mt-5 mb-5">
            <h5 className="light-gray">No tienes Cuenta? <NavLink to="/create-account" style={{textDecoration:"underline"}}>Crea una Aqui</NavLink>
            </h5>
        </div>

        <BlockUi blocking={is_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> sending... </div>)}>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">

                <div className="row">
                    <div className="col-12">
                        <div className="form-group">

                            <label >Email</label>
                            <input type="email" className="form-control input-lg" name="email" id="email"
                                   placeholder="Correo Electronico"
                                   {...register('email', {
                                       required: 'introduce tu email',
                                       validate: {
                                           matchPattern: (v) =>
                                               /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                               "introduce un email valido",
                                       }
                                   })}
                            />
                            {errors.email && <label className="error">{errors.email.message}</label>}
                            
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label className="">Contrase&ntilde;a</label>
                    <input type="password" data-rule-required="true" className="form-control" name="password"
                           id="password" placeholder="****" autoComplete="none"
                           {...register('password', { required: 'introduce tu clave' })}
                    />
                    {errors.password && <label className="error">{errors.password.message}</label>}
                </div>


                <div className="mt-4">
                    <div className="row">
                        <div className="col-12 pl-2 text-end">

                            <button type="submit" className="btn btn-primary btn-block btn-lg">Ingresar</button>


                            <p className="mt-4 light-gray text-center">Olvidaste tus accesos? <NavLink
                                to="/recover-account" style={{textDecoration:"underline"}}>Recuperala
                                Aqui</NavLink>
                            </p>

                        </div>
                    </div>
                </div>


            </form>
        </BlockUi>


        <div className="text-center mt-5">
            <h5 className="light-gray">Cuentas de Negocio <NavLink to="/stores/sign-in" style={{textDecoration:"none"}}>Ingresar Aqui <span
                className="fas fa-arrow-circle-right"></span> </NavLink></h5>
        </div>



    </>)

}


export default withAppCtx(Login);