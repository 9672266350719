import React, {useContext, useRef, useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import {
    DivAlert,
    postBAuth,
    post,
    fmtDateSpanish, IsCustomerLogged, IsStoreLogged,
    socketSend, ucFirst, postCAuth, readFileToSrc, toFormData, strContains,
} from "../../Helpers/Helper";
import {withAppCtx} from "../../Helpers/withAppCtx";
import PageScroller from "../../Helpers/PageScroller";
import {config} from "../../Config";
import {SocketCtx} from "../../SocketCtx";
import {confirmAlert} from "react-confirm-alert";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {NotificationManager} from "react-notifications";
import TerminosYCondiciones from "./TerminosYCondiciones";
import CustomModal from "./modals/CustomModal";
import Button from "react-bootstrap/Button";
import BlockUi from 'react-block-ui';



//
const ChatMessages = ({ context, token, selected_item, onCloseSuccess, onClose }) => {


    //
    const socketContext = useContext(SocketCtx);
    const pageScrollerRef = useRef();

    //
    const {register, watch, setValue, handleSubmit, setFocus, getValues, trigger, formState: { errors }} = useForm();
    //
    const textMessage = watch('message', '');
    const [chat_img_src, setChatImageSrc] = useState('');
    const [img_link, setImageLink] = useState(null);
    const [modal_view_img, modalViewImageLink] = useState(false);
    const [is_loading, setIsLoading] = useState(false);



    //
    useEffect(() => {

        //
        console.log("---modal customer sel item: ", selected_item);


        //
        setFocus("message");

    }, [selected_item]);






    //
    useEffect(() => {
        console.log("---message received for type " + context.data.auth.sale_type_id + ": ", socketContext.msg_data);


        if ( socketContext.msg_data){

            //
            if ( socketContext.msg_data.action_type === "ap-send-msg-error-closed-request" ){
                onCloseSuccess();
            }
            //
            else if ( socketContext.msg_data.action_type === "ap-update-part-request-self" ){
                onCloseSuccess(true);
            }
            //
            else if ( socketContext.msg_data.action_type === "ap-send-msg" ){
                //
                if ( socketContext.msg_data.new_item &&
                    (socketContext.msg_data.new_item.store_id === selected_item.store_id) &&
                    (socketContext.msg_data.new_item.customer_id === selected_item.customer_id)
                ){
                    console.log("------------- MISMO STORE-CUSTOMER -------------");
                    if (pageScrollerRef.current) {
                        pageScrollerRef.current.appendItem(socketContext.msg_data.new_item);
                    }
                }
            }

        }

        // remover mensajes el salir
        return () => {
            socketContext.resetMsgData();
        };

    }, [socketContext.msg_data]);




    //
    const resetScroller = () => {
        if (pageScrollerRef.current) { pageScrollerRef.current.resetItems();}
    };





    // Send Msg Via Post
    const PostSendMessage = (post_data) => {

        setIsLoading(true);

        //
        post_data.id = selected_item.id;
        post_data.action_type = "ap-send-msg";

        //
        post(context.data.auth, "/ap-send-msg", toFormData(post_data), {conteTypeForm:true}, function(success, data){
            console.log("---ap-send-msg res: ", data);
            //
            dataAddMsg(data);
            setValue("message", '');
            //
            setIsLoading(false);
        });
    }






    // Send Message Via Socket
    const SocketSendMessage = (post_data) => {
        //
        post_data.id = selected_item.id;
        //
        socketSend(socketContext, post_data, "ap-send-msg");
        //
        setValue("message", '');
    }




    //
    const onSubmit = (post_data) => {
        //console.log(post_data);
        //console.log("-------- auth: ", context.data.auth);


        // Si es store
        if ( IsStoreLogged(context) ){
            // Si no tiene suscripcion valida
            if ( token.userData && token.userData.has_valid_subs ) {

                //
                PostSendMessage(post_data);

            } else {
                NotificationManager.info("Se requiere una suscripcion para responder a presupuestos");
                setValue("message", '');
            }
        }
        //
        else if ( IsCustomerLogged(context) ){

            //
            PostSendMessage(post_data);

        }
    };




    const btnSelectChatImage = () => {
        const fileInput = document.getElementById('chat_imgs');
        fileInput.click();
    }
    const onSelectChatImage = (e) => {

        //
        let chat_img_files = e.target.files[0];
        console.log("---chat_imgs: ", chat_img_files);


        if (chat_img_files){

            //
            setIsLoading(true);

            //
            readFileToSrc(chat_img_files, function(results_src){
                setChatImageSrc(results_src);
            });

            //
            let post_data = {}
            //
            post_data.id = selected_item.id;
            post_data.chat_imgs = chat_img_files;
            post_data.action_type = "ap-send-msg";

            //
            post(context.data.auth, "/ap-upload-img", toFormData(post_data), {conteTypeForm:true}, function(success, data){
                console.log("---ap-send-msg res: ", data);
                //
                dataAddMsg(data);
                document.getElementById('chat_imgs').value = "";
                //
                setIsLoading(false);
            });
        }

    }




    const dataAddMsg = (data) =>{
        //
        if ( data && data.new_item && (data.new_item.store_id === selected_item.store_id) && (data.new_item.customer_id === selected_item.customer_id) ){
            console.log("------------- MISMO STORE-CUSTOMER -------------");
            if (pageScrollerRef.current) {
                pageScrollerRef.current.appendItem(data.new_item);
            }
        }
    }




    const getResourceImage = (type, item) => {
        //
        if (type==="store"){

            // Aqui se obtiene del llamado al api en php
            if (item.biz_logo){
                return (<img style={{width:"100%", borderRadius:20}} src={item.biz_logo} />)
            }

            // Aqui se obtiene del onMessage del apiGateway (solo con el img_ext)
            else if (item.store_img_ext){
                let store_biz_url = config.host_url + "/files/stores/" + item.store_id + "/profile/me." + item.store_img_ext;
                return (<img style={{width:"100%", borderRadius:20}} src={store_biz_url} />)
            }

            // si no tiene ninguno entonces mostramos las iniciales
            else {
                return (<div className="divRounded">{item.store_initials}</div>)
            }
        }
        else if (type==="customer"){

            //
            if (item.profile_img){
                return (<img style={{width:"100%", borderRadius:20}} src={item.profile_img} />)
            }
            //
            else if (item.customer_img_ext){
                let cust_profile_url = config.host_url + "/files/customers/" + item.customer_id + "/profile/me." + item.customer_img_ext;
                return (<img style={{width:"100%", borderRadius:20}} src={cust_profile_url} />)
            }
            else {
                return (<div className="divRounded">{item.cust_initials}</div>);
            }
        }
        return null;
    }


    //
    const onViewImg = (http_link) => {
        setImageLink(http_link);
        modalViewImageLink(true);
    }


    //
    const parseMsg = (msg) => {
        //
        if (strContains(msg, "http")){
            return (<button style={{border:"none"}} onClick={() => {onViewImg(msg)}}><img src={msg} style={{width:200}} /></button>);
        } else {
            return msg;
        }
    }

    //
    const getChatMessageItem = (type, name, img_res, msg, datetime) => {

        //
        if ( type === "left" ){
            return (
                <div className="col-12 text-left">

                    <div style={{color:"#999", fontSize:10}}> {name} </div>

                    <div style={{width:"100%"}}>


                        <div style={{width:"10%", backgroundColor:"#fff", float:"left", marginRight:10}}>
                            {img_res}
                        </div>

                        <div style={{width: "85%", backgroundColor:"#fff", float:"left"}}>
                            <div style={{width:"100%", color:"darkblue"}}> {parseMsg(msg)}  </div>
                            <small style={{color:"gray", fontSize:10}}> {fmtDateSpanish(datetime, true)} </small>
                        </div>
                    </div>

                </div>
            )
        }
        else if ( type === "right" ){
            return (
                <div className="col-12 text-right">

                    <div style={{color:"#999", fontSize:10}}> {name} </div>

                    <div style={{width:"100%"}}>

                        <div style={{width:"10%", backgroundColor:"#fff", float:"right", marginLeft:10}}>
                            {img_res}
                        </div>

                        <div style={{width: "85%", backgroundColor:"#fff", float:"right"}}>
                            <div style={{width:"100%", color:"darkblue"}}> {parseMsg(msg)}  </div>
                            <small style={{color:"gray", fontSize:10}}> {fmtDateSpanish(datetime, true)} </small>
                        </div>

                    </div>

                </div>
            )
        }

    }


    //
    const getImgSrc = (item, type, img_url) => {
        //
        if (item[img_url]){
            return item[img_url];
        }
        //
        else if ( type==="cust" && item.customer_img_ext ){
            return config.host_url + "/files/customers/"+item.customer_id+"/profile/me." + item.customer_img_ext;
        }
        //
        else if ( type==="store" && item.store_img_ext ){
            return config.host_url + "/files/stores/"+item.store_id+"/profile/me." + item.store_img_ext;
        }
        //
        return null;
    }


    //
    const getChatMessages = (item, index) => {
        //
        if (item && item.id){
            //
            if ( IsCustomerLogged(context) ){
                //
                if ( item.customer_sent ){
                    return getChatMessageItem("right", "Tu: ", getResourceImage("customer", item), item.message, item.dt2);
                }
                //
                else if ( item.store_sent ){
                    return getChatMessageItem("left", item.store_name + ": ", getResourceImage("store", item), item.message, item.dt2);
                }
            }
            //
            else if ( IsStoreLogged(context) ){
                //
                if ( item.store_sent ){
                    return getChatMessageItem("right", "Tu: ", getResourceImage("store", item), item.message, item.dt2);
                }
                //
                else if ( item.customer_sent ){
                    //
                    let customer_name = (token.userData && token.userData.has_valid_subs) ? selected_item.customer_name + ": " : "[Cliente:] ";
                    return getChatMessageItem("left", customer_name, getResourceImage("customer", item), item.message, item.dt2);
                }
            }
        }
        //
        return null;
    }




    //
    const parseScrollContent = (item, index) => {
        //
        return (
            <li key={index} className="item" style={{padding:5, borderBottom:"1px solid #eee", minHeight:50, marginTop:5, width:"100%", backgroundColor:"#fff" }}>
                <div className="row">
                    {getChatMessages(item, index)}
                </div>
            </li>
        )
    }




    //
    const getChatUrl = () => {
        //
        if ( IsCustomerLogged(context) ){
            return config.auth_cust_url + "/notifications/" + selected_item.store_id + "/msgs";
        }
        else if ( IsStoreLogged(context) ){
            return config.auth_store_url + "/notifications/" + selected_item.customer_id + "/msgs";
        }
        return null;
    }



    //
    const getChatTitle = () => {
        //
        if ( IsCustomerLogged(context) ){
            return selected_item.store_name;
        }
        else if ( IsStoreLogged(context) ){
            return (token.userData && token.userData.has_valid_subs) ? selected_item.customer_name : "[Cliente]";
        }
        return null;
    }








    //
    const handleTextMessageOnKeyDown = async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            //
            //
            const isValid = await trigger();
            if (isValid) {
                onSubmit(getValues());
            }
        }
    };
    //
    const handleTextMessageChange = (e) => {
        setValue('message', e.target.value);
    };




    const updatePartRequest = (update_type) => {
        //
        let postData = {
            part_request_id: selected_item.part_request_id,
            store_part_request_id: selected_item.id,
            store_id: selected_item.store_id,
            update_type: update_type
        }
        //
        socketSend(socketContext, postData, "ap-update-part-request");
    }




    const btnDeshacerCerrarSolicitud = () => {
        //console.log(item, index);

        //
        alert("No se puede deshacer, solicitud cerrada"); return;

        //
        confirmAlert({
            message: (<>
                <div><strong>Abrir Solicitud</strong></div>
                <div>Quitar que adquiriste tus productos con {selected_item.store_name}?</div>
            </>),
            buttons: [
                {
                    label: 'Si',
                    onClick: function(){

                        //
                        updatePartRequest("open");

                    }
                },
                {
                    label: 'No',
                    onClick: function(){

                    }
                }
            ]
        });
    };








    const updateStoreRequestStatus = (item, type) => {
        //
        let postData = {
            id: item.id,
            part_request_id: item.part_request_id,
            status_type: type
        }
        //
        postBAuth(context.data.auth, "/parts-requests/status", postData, function(success, data){
            console.log("---post update store request status: ", data);
            //
            if (success){
                //
                onCloseSuccess(true);
            }
        });
    }




    const btnCerrarSolicitud = () => {
        //console.log(item, index);

        //
        confirmAlert({
            message: (<>
                <div className={"font-weight-bolder"}>
                    Adquiriste tus productos con {selected_item.store_name}?
                </div>
                <small className={"text-primary"}>Al cerrar esta solicitud solo podras ver el historial de mensajes.</small>
            </>),
            buttons: [
                {
                    label: 'Si',
                    onClick: function(){

                        //
                        updatePartRequest("close");

                    }
                },
                {
                    label: 'No',
                    onClick: function(){

                    }
                }
            ]
        });
    };


    const getBotonAbrirSolicitud = () => {
        return (
            <button type="button" className="btn btn-sm btn-block btn-outline-success" onClick={() => {
                //
                confirmAlert({
                    message: (<>
                        <div className={"font-weight-bolder"}>
                            Abrir Solicitud con folio {selected_item.id}?
                        </div>
                        <small className={"text-primary"}>La Solicitud se ira a la lista de nuevas.</small>
                    </>),
                    buttons: [
                        {
                            label: 'Si',
                            onClick: function(){
                                //
                                updateStoreRequestStatus(selected_item, "type_avail");
                            }
                        },
                        {
                            label: 'No',
                            onClick: function(){

                            }
                        }
                    ]
                });
            }}>
                <span className="fa fa-check"></span> Marcar Como Pieza Disponible
            </button>
        )
    }



    const getBotonIgnorarSolicitud = () => {
        return (
            <button type="button" className="btn btn-sm btn-light" onClick={() => {
                //
                confirmAlert({
                    message: (<>
                        <div className={"font-weight-bolder"}>
                            Ignorar Solicitud con folio {selected_item.id}?
                        </div>
                        <small className={"text-primary"}>Aun podras ver la solicitud en el listado de cerradas.</small>
                    </>),
                    buttons: [
                        {
                            label: 'Si',
                            onClick: function(){
                                //
                                updateStoreRequestStatus(selected_item, "type_ignore");
                            }
                        },
                        {
                            label: 'No',
                            onClick: function(){

                            }
                        }
                    ]
                });
            }}>
                <span className="fa fa-remove"></span> ignorar solicitud
            </button>
        )
    }


    const getChatForm = () => {
        return (
            <div className={"mt-2 mb-2"}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">

                    <div className="d-flex align-items-center">

                        <textarea
                            onKeyDown={handleTextMessageOnKeyDown}
                            onChange={handleTextMessageChange}
                            value={textMessage}
                            className="form-control mr-2"
                            name="message" id="message"
                            data-rule-required="true" data-rule-minlength="2" data-rule-maxlength="512"
                            placeholder="Aa"
                            autoComplete="off"
                            {...register('message', {required:"Campo requerido"})}
                        />
                        {errors.message && <label className="error">{errors.message.message}</label>}

                        <button type="submit" className="btn btn-primary"><span className={"fas fa-rocket"}></span> Enviar </button>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <input type="file" id="chat_imgs" name="chat_imgs"
                                   accept="image/*"
                                   style={{}}
                                   {...register('chat_imgs', {
                                       onChange: onSelectChatImage
                                   })}
                            />
                            {errors.chat_imgs && <label className="error">{errors.chat_imgs.message}</label>}

                            {/*<button className="btn btn-sm btn-outline-primary mt-2"*/}
                            {/*        type="button" onClick={btnSelectChatImage}><span*/}
                            {/*    className="fa fa-image"></span> seleccionar*/}
                            {/*</button>*/}

                        </div>
                    </div>


                </form>
            </div>
        )
    }






    //
    const getChatFooter = () => {



        //
        if ( IsCustomerLogged(context) ){

            //
            if (selected_item.deal_datetime && selected_item.status_id === config.STORE_PR_STATUS_ID_DEAL ){
                return (
                    <div style={{fontSize:12}} className={"badge badge-info font-weight-normal p-2 btn-block text-center"}>
                        <span className={"fas fa-check"}></span> Adquiriste tus productos con {selected_item.store_name} &nbsp;
                        {/*<button type="button" className="btn btn-sm btn-info" onClick={() => btnDeshacerCerrarSolicitud()}><span className="fa fa-undo"></span> Deshacer </button>*/}
                    </div>
                )
            } else {
                return (
                    <>
                        {getChatForm()}

                        <button type="button" className="btn btn-sm btn-block btn-outline-success" onClick={() => btnCerrarSolicitud()}>
                            <span className="fa fa-check"></span> Adquiriste tus productos con {selected_item.store_name}
                        </button>
                    </>
                )
            }

        }

        //
        else if( IsStoreLogged(context) ){

            //
            if (selected_item.deal_datetime) {

                //
                if (selected_item.deal_with_me ) {
                    return (    <span style={{fontSize: 14}} className={"badge badge-info btn-block p-2"}>
                                    <span className={"fas fa-star"}></span> {ucFirst(selected_item.customer_name)} te selecciono en trato
                                </span>
                    )
                } else {
                    return (
                        <span className={"text-secondary"}> Solicitud cerrada </span>
                    )
                }

            } else if (selected_item.status_id === config.STORE_PR_STATUS_ID_NOT_AVAIL ){

                //
                return getBotonAbrirSolicitud();

            } else if (selected_item.status_id === config.STORE_PR_STATUS_ID_IGNORE ){

                //
                return getBotonAbrirSolicitud();

            } else {

                //
                return (
                    <>
                        {getChatForm()}
                        <div className={"text-center"}>
                            {getBotonIgnorarSolicitud()}
                        </div>
                    </>
                )

            }

        }

    }





    const getNotifications = () => {
        //
        if ( IsStoreLogged(context) ){

            //
            if ( token.userData && token.userData.has_valid_subs ){

                /* si tiene suscripcion valida */

            } else {
                //
                return (
                    <DivAlert
                        type={"info"}
                        className={"mt-2 mb-4"}
                        hideCloseBtn={true}
                        msg={"Actualiza tu suscripcion para responder a presupuestos"}
                    />
                )
            }
        }
    }



    //
    return (<>


        <div className="row">
            <div className="col-sm-8">
                <div style={{margin:"0", padding:"0", fontWeight:"bold"}}>
                    {getChatTitle()}
                </div>
            </div>
            <div className="col-sm-4 text-right">

                <div className={"btn-group"}>
                    <button className="btn btn-sm btn-secondary" onClick={onClose} style={{marginLeft:"10px"}}> <span className="fa fa-times"></span> Salir </button>
                </div>

            </div>
        </div>

        <hr className="mt-2 mb-2"/>



        <div className="row mb-3">
            <div className="col-12 text-center">
                <h5 className={"text-primary"}> {selected_item.marca}/{selected_item.submarca}/{selected_item.modelo_anio}/{selected_item.cilindros} Cilindros </h5>
            </div>
            <div className="col-12">
                <div><span className={"fas fa-wrench"} style={{fontSize:"12px"}}></span> {selected_item.parts_info} </div>
                <small style={{color:"gray"}}> folio: {selected_item.id} : {fmtDateSpanish(selected_item.datetime_created.date, true)} </small>
            </div>

        </div>


        {getNotifications()}



        <BlockUi blocking={is_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> enviando... </div>)}>

            <PageScroller
                ref={pageScrollerRef}
                num_recs={4}
                listClassName={"clear-list"}
                is_reverse={true}
                height={"250px"}
                parseScrollContent={(item, index) => parseScrollContent(item, index)}
                url={getChatUrl()}
            />


            <div className="row mt-2 mb-0">
                <div className="col-12">
                    {getChatFooter()}
                </div>
            </div>

        </BlockUi>



        <CustomModal
            showModal={modal_view_img}
            modalSize={"modal-lg"}
            hideOnScape={true}
            content={<div>
                <Button style={{position:"absolute", top:20, right:20}} variant="warning" onClick={() => modalViewImageLink(false)}>
                    <span className="fa fa-times"></span>
                </Button>
                <img src={img_link} className={"img-fluid"} />
            </div>}
            onClose={() => modalViewImageLink(false)}
        />


    </>)

}


export default withAppCtx(withAuthCtx(ChatMessages));