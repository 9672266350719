import React, { Component } from 'react';
import {config} from "../../Config";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {NavLink} from "react-router-dom";
import {getBAuth, SubscriptinListItem, SubscriptionItem, ucFirst} from "../../Helpers/Helper";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import CustomModal from "../Common/modals/CustomModal";
import PayCardStripe from "../Forms/PayCardStripe";
import PayCardANet from "../Forms/PayCardANet";
import {loadStripe} from '@stripe/stripe-js';
import PayOxxo from "../Common/Forms/PayOxxo";
import PayTransf from "../Common/Forms/PayTransf";
import PageScroller from "../../Helpers/PageScroller";
import SelectPeriodicidad from "../Common/Forms/SelectPeriodicidad";


class Subscribe extends Component {


    constructor(props) {
        super(props);
        //
        this.state = {
            is_loading: false,
            arr_items: [],
            sel_subscription: null,
            sel_periodo: null,
            modal_periodicidad: false,
            modal_payment: false,
            modal_content_type: null,
            stripe_promise: null
        };
        //
        this.pageScrollerRef = React.createRef();
    }



    componentDidMount() {
        //
        let self = this;
        //
        document.title = config.AppName + " - Suscribirse";


        console.log("---customer subscribe props: ", this.props);


        //
        this.getPlatformsConfig();
        //this.loadSubscriptions();

        //
        if (this.props.token){ this.props.token.tokenUserData(); }
    }



    //
    btnReload = () => {
        this.reloadPager();
    }


    reloadPager = () => {
        if (this.pageScrollerRef.current) { this.pageScrollerRef.current.resetItems(); }
    }




    btnPayment = (item, payment_type) => {
        console.log("---btnPayment: ", item.id, payment_type);
        //
        this.setState({
            modal_periodicidad: true,
            modal_payment_type: payment_type,
            sel_subscription: item
        })
    }






    //
    stripePromise = null;
    anetConfig = null;


    //
    setPaymentConfig(type, pmt_config){
        console.log("---payment type " + type + ": ", pmt_config);
        //
        if (type==="stripe" && pmt_config.pk){
            this.stripePromise = loadStripe(pmt_config.pk);
        }
        if (type==="authorizenet"){
            this.anetConfig = pmt_config;
        }        
    }



    getPlatformsConfig(){

        //
        let self = this;
        let {auth} = this.props.context.data;

        this.setState({
            is_loading: true
        }, function(){

            //
            axios.get(config.api_url + "/pconfig?appid="+config.appId, {
                headers: {
                    'Authorization': auth.token,
                    'Utype': auth.sale_type_id
                },
            })
                .then(response => {
                    //console.log("---customer platforms config: ", response);
                    //
                    if ( response.data.stripe_config ){
                        //
                        self.setPaymentConfig("stripe", response.data.stripe_config)
                    }
                    //
                    else if ( response.data.authorizenet ){
                        //
                        self.setPaymentConfig("authorizenet", response.data.authorizenet)
                    }
                    //
                    else {
                        let err = (response.data && response.data.error) ? response.data.error: "No se pudieron obtener los datos";
                        //NotificationManager.info(err);
                    }
                })
                .catch(error => {
                    let err = (error) ? error: "Network error or no internet connection";
                    console.error(err);
                    //NotificationManager.info(err.message);
                });


        })
    }









    //
    render(){


        //
        return (<>


            <div className="pt-4 pb-4 text-center">
                <h1 className="">Elige tu <span className="">Suscripci&oacute;n</span></h1>
                <p className={"text-success"}>
                    Incrementa la cantidad de busquedas diarias simulataneas y llega a mas lugares de AutoPartes
                </p>
                <h6 className="">
                    Ahorra hasta un 25% en una <span className="badge badge-info badge-lg"> Suscripcion Anual </span>
                </h6>
            </div>



                <div className="container-fluid">


                    <p className="mb-5 mt-1 text-center" style={{fontSize:12}}>
                        Aceptamos Tarjetas de Debito/Credito y Deposito en tu tienda Oxxo mas cercana
                        <img src={process.env.PUBLIC_URL + '/images/tarjetas-oxxo.png'} style={{width:"200px", marginLeft: "10px"}} />
                    </p>


                    <div className="row">
                        <div
                            className="col-12 text-center">



                            <PageScroller
                                num_recs={4}
                                height={400}
                                ref={this.pageScrollerRef}
                                isDivElement={true}
                                listClassName={"row"}
                                parseScrollContent={(item, index) => <SubscriptinListItem
                                    key={index}
                                    item={item}
                                    tipo={"customer"}
                                    btnPayment={this.btnPayment}
                                />}
                                url={config.api_url + "/list-subscriptions"}
                            />


                            <div className="row py-lg-4">
                                {/*{this.state.arr_items}*/}
                            </div>


                        </div>
                    </div>


                    <div className="text-center">
                        <NavLink className="btn btn-info mt-3" to="/account/subscriptions"><span
                            className="fa fa-reply"></span> Regresar </NavLink>
                    </div>


                </div>




            <CustomModal
                modalSize={"modal-md"}
                showModal={this.state.modal_periodicidad}
                content={<SelectPeriodicidad
                    sel_subscription={this.state.sel_subscription}
                    onSubmit={(calc_data) => {
                        //
                        this.setState({
                            sel_periodo: calc_data,
                            modal_periodicidad: false,
                            modal_payment: true
                        });
                    }}
                    onClose={() => {this.setState({modal_periodicidad: false});}}
                        />}
            />


            <CustomModal
                modalSize={"modal-lg"}
                showModal={this.state.modal_payment}
                content={<>

                    { (this.state.modal_payment_type === "card_anet" ) && (
                        <PayCardANet
                            sel_periodo={this.state.sel_periodo}
                            sel_subscription={this.state.sel_subscription}
                            anetConfig={this.anetConfig}
                            onSubmit={() => {this.setState({modal_payment: false});}}
                            onClose={() => {this.setState({modal_payment: false});}}
                        />
                    )}

                    { (this.state.modal_payment_type === "card_stripe" ) && (
                        <PayCardStripe
                            sel_periodo={this.state.sel_periodo}
                            sel_subscription={this.state.sel_subscription}
                            stripePromise={this.stripePromise}
                            onSubmit={() => {this.setState({modal_payment: false});}}
                            onClose={() => {this.setState({modal_payment: false});}}
                        />
                    )}

                    { (this.state.modal_payment_type === "oxxo" ) && (
                        <PayOxxo
                            sel_periodo={this.state.sel_periodo}
                            sel_subscription={this.state.sel_subscription}
                            stripePromise={this.stripePromise}
                            onSubmit={() => {this.setState({modal_payment: false});}}
                            onClose={() => {this.setState({modal_payment: false});}}
                        />
                    )}

                    { (this.state.modal_payment_type === "transf" ) && (
                        <PayTransf
                            sel_periodo={this.state.sel_periodo}
                            sel_subscription={this.state.sel_subscription}
                            onSubmit={() => {this.setState({modal_payment: false});}}
                            onClose={() => {this.setState({modal_payment: false});}}
                        />
                    )}

                </>}
            />




        </>)
    }

}


export default withAuthCtx(withAppCtx(Subscribe));
