import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {withAppCtx} from "../../Helpers/withAppCtx";
import {config} from "../../Config";
import {
    arrContains, delContextData,
    getOrderItems,
    getLogo,
    IsCustomerLogged,
    IsStoreLogged,
    postBAuth,
    postCAuth,
    setContextData, SubscriptinListItem
} from "../../Helpers/Helper";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {isBrowser, isMobile} from 'react-device-detect';
import Notific from "../../Helpers/Notific";
import {withSocket} from "../../Helpers/withSocket";
import {NotificationManager} from "react-notifications";
import {withNavigate} from "../../Helpers/withNavigate";
import {confirmAlert} from "react-confirm-alert";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PageScroller from "../../Helpers/PageScroller";
import {CustomerRequestItem} from "../../Helpers/APHelper";
import BlockUi from "react-block-ui";


class PageHeader extends Component {




    //
    constructor(props) {
        super(props);
        //
        this.state = {
            is_loading: false,
            display_notifications: false
        };
        //
        this.pageScrollerRef = React.createRef();
    }




    reloadPager = () => {
        if (this.pageScrollerRef.current) { this.pageScrollerRef.current.resetItems(); }
    }





    //
    componentDidMount() {
        //console.log("---AutoParts header props: ", this.props);
        //
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('click', this.handleOutsideClick);
        //
    }








    //
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('click', this.handleOutsideClick);
    }




    /*
    * Si da click en un lugar que no sea el nc (notificationsContainer)
    * */
    handleOutsideClick = (event) => {
        const prevent_close = document.getElementsByClassName('prev_close');
        if ( prevent_close && prevent_close[0] && !prevent_close[0].contains(event.target) ) {
            //
            this.setState({
                display_notifications: false
            });
        }
    };
    /*
    * Si presiona Scape
    * */
    handleKeyDown = (event) => {
        if (event.keyCode === 27) {
            //
            this.setState({
                display_notifications: false
            });
        }
    };



    //
    onNotificationsClick = () => {
        //
        let self = this;

        //
        this.setState({
            display_notifications: !this.state.display_notifications
        }, function(){

            //
            if (IsStoreLogged(this.props.context)){
                //
                postBAuth(this.props.context.data.auth, "/n/so", {}, function(success, data){
                    console.log("---post store notification set as openned: ", data);
                    //
                    if (success){
                        //
                        self.props.token.tokenUserData();
                    }
                });
            }
            //
            else if (IsCustomerLogged(this.props.context)){
                //
                postCAuth(this.props.context.data.auth, "/n/so", {}, function(success, data){
                    console.log("---post customers notification set as openned: ", data);
                    //
                    if (success){
                        //
                        self.props.token.tokenUserData();
                    }
                });
            }

        });
    }



    markAsViewed(notification_id){
        //
        let self = this;
        //
        postBAuth(this.props.context.data.auth, "/n/sv", {id: notification_id}, function(success, data){
            console.log("---post notification set as viewed: ", data);
            //
            if (success){
                //
            }
        });
    }



    NotificationsMenu = () => {

        //
        let {userData} = this.props.token;
        //
        let badge_count = null;
        //
        if (userData && userData.cno && userData.cno > 0){
            badge_count = (<>
                <span style={{position:"absolute", top:"-5px", }} className={"badge badge-danger"}> {userData.cno} </span>
            </>)
        }
        //
        return (
            <a href="#!" style={{position:"relative", borderRadius: "20px"}} className="btn btn-sm btn-primary ml-auto mr-1 prev_close" onClick={this.onNotificationsClick}>
                <i className="fas fa-bell"></i> {badge_count}
            </a>
        );
    }






    PendingOrdersMenu = () => {
        //
        let {userData} = this.props.token;
        //
        if ( userData && userData.cpo && userData.cpo > 0 ){
            //
            return (
                <NavLink to="/account/orders" style={{position:"relative", borderRadius: "20px"}} className="btn btn-sm btn-warning ml-auto mr-1">
                    &nbsp; <span style={{fontSize:12}} className={"badge badge-info"}> {userData.cpo} </span> Pendientes <i className="fas fa-cutlery"></i> &nbsp;
                </NavLink>
            );
        }
    }









    isMenuActive = (arr_paths) => {
        let {pathname} = this.props.location;
        //console.log("-----------isMenuActive: ", pathname, arr_paths);
        //
        if (arrContains(arr_paths, pathname)){
            return "active"
        }
        return '';
    }



    //
    handleNavLinkClick = (name) => {
        //console.log('---Navigate: ', name);
        //
        const navBarsMenu = document.getElementById('navBarsMenu');
        if (navBarsMenu) {
            navBarsMenu.classList.remove('show');
        }
    };




    getUsersMenus(){
        //
        if ( this.props.context.data && this.props.context.data.auth && isBrowser ){
            //
            let {auth} = this.props.context.data;

            //
            if ( auth.sale_type_id === config.PROD_TYPE_STORE_ID ){
                //
                return (<>
                    <li className={'nav-item itm-bordr ' + this.isMenuActive(["/account"])}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Solicitudes de Clientes</Tooltip>}
                        >
                            <NavLink className="nav-link hdr-item" to="/account" onClick={() => this.handleNavLinkClick('store_account')}><span
                                className="fas fa-rocket"></span> </NavLink>
                        </OverlayTrigger>
                    </li>
                    <li className={'nav-item itm-bordr ' + this.isMenuActive(["/account/subscriptions", "/account/subscribe"]) }>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Mis Suscripciones </Tooltip>}
                        >
                            <NavLink className="nav-link hdr-item" to="/account/subscriptions" onClick={() => this.handleNavLinkClick('store_subscriptions')}><span
                                className="icofont-badge"></span></NavLink>
                        </OverlayTrigger>
                    </li>
                    <li className={'nav-item itm-bordr ' + this.isMenuActive(["/account/config-negocio"])}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Configuracion</Tooltip>}
                        >
                            <NavLink className="nav-link hdr-item" to="/account/config-negocio" onClick={() => this.handleNavLinkClick('store_config_negocio')}><span
                                className="fas fa-cog"></span> </NavLink>
                        </OverlayTrigger>

                    </li>
                </>)
            } else if ( auth.sale_type_id === config.PROD_TYPE_CUSTOMER_ID ){
                //
                return (<>
                    <li className={'nav-item itm-bordr ' + this.isMenuActive(["/account"])}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Iniciar Nueva Busqueda</Tooltip>}
                        >
                            <NavLink className="nav-link hdr-item" to="/account" onClick={() => this.handleNavLinkClick('customer_account')}><span
                                className="fas fa-search"></span></NavLink>
                        </OverlayTrigger>

                    </li>
                    <li className={'nav-item itm-bordr ' + this.isMenuActive(["/account/searches"])}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Mis Solicitudes</Tooltip>}
                        >
                            <NavLink className="nav-link hdr-item" to="/account/searches" onClick={() => this.handleNavLinkClick('customer_searches')}><span
                                className="fas fa-list-ul"></span></NavLink>
                        </OverlayTrigger>

                    </li>
                    <li className={'nav-item itm-bordr ' + this.isMenuActive(["/account/subscriptions", "/account/subscribe"]) }>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Mis Suscripciones</Tooltip>}
                        >
                            <NavLink className="nav-link hdr-item" to="/account/subscriptions" onClick={() => this.handleNavLinkClick('customer_subscriptions')}><span
                                className="icofont-badge"></span></NavLink>
                        </OverlayTrigger>
                    </li>
                </>)
            }

        }
        return null;
    }




    getCustomerMenu(){
        //
        let auth = this.props.context.getAuth()
        //
        if (auth && auth.id){

            let company_name = ( (parseInt(auth.customer_type_id) === 2) && auth.company_name) ? " - " + auth.company_name : "";

            return (<>

                {/*this.NotificationsMenu()*/}

                <NavLink to={"/account/settings"} className="btn btn-sm btn-info ml-auto mx-4 acct_link">
                    <span className={"badge badge-warning"}><i className="fas fa-user"></i></span> <span className="user_name"> {auth.first_name} {company_name} </span>
                </NavLink>

        </>) 
        }
        return (<>
            <NavLink className="btn btn-sm btn-link text-primary font-weight-bold mx-2"
                             to="/create-account"><i className="fas fa-user"></i> Registrate Aqui </NavLink>
            <NavLink className="btn btn-sm btn-link text-success font-weight-bold mx-2"
                             to="/sign-in"><i className="fas fa-sign-in-alt"></i> Ingresar Aqui </NavLink>
        </>)
    }



    //
    render(){



        //
        let str_home_link = "/";
        //
        if (this.props.context.getAuth()){
            str_home_link = "/account";
        }


        //
        let endpoint_url = null;
        //
        if ( IsStoreLogged(this.props.context) ){
            endpoint_url = config.auth_store_url + "/n/s";
        }
        //
        else if ( IsCustomerLogged(this.props.context) ){
            endpoint_url = config.auth_cust_url + "/n/s";
        }



        //
        return (<>
                <nav className="navbar navbar-expand navbar-light bg-blurred fixed-top">

                    <div className="container">

                        <NavLink exact="true" className="mr-2" to={str_home_link} style={{ color: 'black' }}>
                            <img className="img-fluid" src={process.env.PUBLIC_URL + getLogo()} alt={config.AppName} style={{width:"200px"}} />
                        </NavLink>


                        <div className="collapse navbar-collapse" id="navbarsExample02">
                            <ul className="navbar-nav mr-auto">

                                {this.getUsersMenus()}


                            </ul>
                            <ul className="navbar-nav ml-auto">

                                {this.getCustomerMenu()}

                            </ul>
                        </div>

                    </div>

                </nav>


                {(this.props.context.getAuth() && this.state.display_notifications) && (<>
                    <Notific />
                </>)}

            </>
        );

    }

}


export default withAppCtx(withAuthCtx(withSocket(withNavigate(PageHeader))));
