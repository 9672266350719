import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter, Route, useLocation, Routes } from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
//
import {PageWrapper, EmptyPageWrapper} from "./Sections/Common/Wrappers";
//
import Login from "./Sections/Login";
import CreateAccount from "./Sections/CreateAccount";
import RecoverAccount from "./Sections/RecoverAccount";
import ActivateAccount from "./Sections/ActivateAccount";
import UpdatePassword from "./Sections/UpdatePassword";

//
import CustomerMain from "./Sections/AuthCustomer/CustomerMain";
import CustomerLimitReached from "./Sections/AuthCustomer/LimitReached";
import CustomerMisBusquedas from "./Sections/AuthCustomer/MisBusquedas";
import CustomerMyOrder from "./Sections/AuthCustomer/MyOrder";
//
import CustomerSubscriptions from "./Sections/AuthCustomer/Subscriptions";
import CustomerSubscribe from "./Sections/AuthCustomer/Subscribe";
import CustomerSettings from "./Sections/AuthCustomer/Settings";
//
import CustomerProfile from "./Sections/AuthCustomer/Profile";
import CustomerCredentials from "./Sections/AuthCustomer/Credentials";
import CustomerIndex from "./Sections/AuthCustomer/CustomerIndex";


//
import './App.css'
import {AuthCtxProvider} from "./AuthCtx";
import {SocketCtxProvider} from "./SocketCtx";
//import {GeoLocationCtxProvider} from "./GeoLocationCtx";
import NotFound from "./Sections/NotFound";
import NoInternet from "./Sections/NoInternet";
import {config} from "./Config";
import {getSeconds} from "./Helpers/Helper";
import {AppCtx} from "./AppCtx";
import Index from "./Sections/Index";
import SaleDetails from './Sections/AuthCustomer/SaleDetails';
import ConfirmSale from './Sections/ConfirmSale';











const App = () => {




    //
    const app_context = useContext(AppCtx);

    //
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);
    const [auth, setAuth] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false); // Nuevo estado para controlar la carga de los datos
    const [is_order_id, setIsOrderId] = useState('');
    //const location = useLocation();



    //
    useEffect(() => {


        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                const checkInternetConnection = () => {
                    setIsOnline(window.navigator.onLine);
                };
                // 1st time internet check
                checkInternetConnection();
                // check internet every 15 secs
                const interval = setInterval(checkInternetConnection, getSeconds(15));

                // Limpia el intervalo cuando el componente se desmonta
                return () => {
                    clearInterval(interval);
                };
            }
        };

        //
        document.addEventListener("visibilitychange", handleVisibilityChange);




        //
        const storedData = localStorage.getItem(config.appSessionPrefix);
        //
        if (storedData) {
            var parsed_data = JSON.parse(storedData);
            if (parsed_data && parsed_data.auth){
                setAuth(parsed_data.auth);
            }
        }


        // Marcar los datos como cargados
        setDataLoaded(true);




        // Limpia el intervalo y los event listeners cuando el componente se desmonta
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };

    }, []);








    //
    useEffect(() => {
        //console.log("---is_order_id: ", app_context.data);
        //
        if (app_context.data && app_context.data.new_order_id){
            setIsOrderId(true);
        } else {
            setIsOrderId(false);
        }
    }, [app_context]);







    if (!dataLoaded) {
        return 'data not loaded';
    }




    //
    const MyOrderRoute = () => {
        //
        return (
            <Routes>
                <Route path="" element={(
                    <CustomerMain />
                )}>

                    <Route index element={(<CustomerMyOrder />)} />
                    <Route path="*" element={(<CustomerMyOrder />)} />
                </Route>
            </Routes>
        )
    }


    //
    const AppRoutes = () => {


        //
        return (
            <Routes>



                {/* 
                    MissionExpress = AppIdP1 = 11, Plabuz = AppIdP2 = 13, Tickets4Buses = AppIdP3 = 16
                    Secciones comunes que no requieren auth 
                */}
                <Route path="/" element={(
                    <PageWrapper>
                        <Index />
                    </PageWrapper>
                )} />


                <Route path="/confirm/:sale_code" element={(
                    <PageWrapper><ConfirmSale /></PageWrapper>
                )} />


                {/*
                    {( config.appId === config.AppIdP1 ) && (
                        <div>AppIdP1</div>
                    )}

                    {( config.appId === config.AppIdP2 ) && (
                        <div>AppIdP2</div>
                    )}

                    {( config.appId === config.AppIdP3 ) && (
                        <div>AppIdP3</div>
                    )}
                */}


                <Route path="/sign-in" element={(<EmptyPageWrapper><Login /></EmptyPageWrapper>)} />
                <Route path="/create-account" element={(<EmptyPageWrapper><CreateAccount /></EmptyPageWrapper>)} />
                <Route path="/recover-account" element={(<EmptyPageWrapper><RecoverAccount /></EmptyPageWrapper>)} />
                <Route path="/activate-account" element={(<EmptyPageWrapper><ActivateAccount /></EmptyPageWrapper>)} />
                <Route path="/update-password" element={(<EmptyPageWrapper><UpdatePassword /></EmptyPageWrapper>)} />



                <Route path="/account" element={(<CustomerMain />)}>

                    <Route index element={(<CustomerIndex />)} />

                    <Route path="sale-details/:sale_id" element={(
                        <PageWrapper><SaleDetails /></PageWrapper>
                    )} />

                    <Route path="searches" element={(<CustomerMisBusquedas />)} />
                    <Route path="limit-reached" element={(<CustomerLimitReached />)} />

                    <Route path="subscriptions" element={(<CustomerSubscriptions />)} />
                    <Route path="subscribe" element={(<CustomerSubscribe />)} />
                    <Route path="settings" element={(<CustomerSettings />)} />

                    <Route path="profile" element={(<CustomerProfile />)} />
                    <Route path="credentials" element={(<CustomerCredentials />)} />

                </Route>



                <Route path="/not-found" element={(<EmptyPageWrapper><NotFound /></EmptyPageWrapper>)} />
                <Route path="*" element={(<EmptyPageWrapper><NotFound /></EmptyPageWrapper>)} />


            </Routes>
        )
    }




    //
    return (
        <BrowserRouter>
            {isOnline ? (
                /*<GeoLocationCtxProvider>*/
                    <AuthCtxProvider>
                        {/*<SocketCtxProvider>*/}


                            { ( is_order_id ) ? MyOrderRoute() : AppRoutes()  }

                            <NotificationContainer />


                        {/*</SocketCtxProvider>*/}
                    </AuthCtxProvider>
                /*</GeoLocationCtxProvider>*/
            ) : (<>
                <EmptyPageWrapper><NoInternet /></EmptyPageWrapper>
            </>)}
        </BrowserRouter>
    )
}

export default App;