import React, { createContext, useContext, useState, useEffect } from 'react';
import {config} from "./Config";
import {AppCtx} from "./AppCtx";
import {useLocation} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import {AuthCtx} from "./AuthCtx";
import {shortenString} from "./Helpers/Helper";
import {isBrowser, isMobile} from "react-device-detect";


// Crear el contexto
const SocketCtx = createContext();

// Crear el proveedor del contexto
const SocketCtxProvider = ({ children }) => {
    // Estado para almacenar los datos en el contexto




    //
    const context = useContext(AppCtx);
    const auth_context = useContext(AuthCtx);
    //
    const [loaded, setLoaded] = useState(false);
    const [socket, setSocket] = useState(null);
    const [is_error, setIsError] = useState(false);
    const [closed_count, setClosedCount] = useState(0);
    //
    const [msg_data, setMessage] = useState(null);






    const initSocket = (msg) => {
        //
        console.log(msg);
        let auth = context.getAuth();
        //
        if ( auth ) {
            //
            if (socket){

                //
                console.info('\u001b[34m ***Ws Conn Already Stablished \u001b[0m');

            } else {

                //
                let ws_qs = "";
                ws_qs += "?token=" + auth.token;
                ws_qs += "&ut=" + auth.sale_type_id;
                ws_qs += "&vid=" + context.visitorId;
                //
                let ws_conn = new WebSocket(config.socket_url + ws_qs);


                //
                ws_conn.onopen = (e) => {
                    //
                    console.info('\u001b[34m ***Ws New Connection Stablished  \u001b[0m');
                    //
                    setSocket(ws_conn);
                    setLoaded(true);
                    setIsError(false);

                    //
                    ws_conn.onmessage = (evt) => {
                        //
                        const msg_data = JSON.parse(evt.data);
                        //console.log('Socket onmessage:', msg_data);

                        //
                        setMessage(msg_data);

                        // si el user esta auth cargamos el user data para obtener el count notifications (cno)
                        if ( context.getAuth() ) {
                            // cno
                            //console.log("----called: ", auth_context)
                            auth_context.tokenUserData();
                        }

                        //
                        if (msg_data && msg_data.msg) {
                            //
                            const msg = shortenString(msg_data.msg, 160);
                            //
                            //if (isBrowser){ context.browserNotification('', msg); }
                            NotificationManager.info(msg);
                        }
                    };
                };


                //
                ws_conn.onerror = () => {
                    //
                    console.info('***Ws Connection Error');
                    setSocket(null);
                    setLoaded(true);
                    //
                    setIsError(true);
                };
                //
                ws_conn.onclose = () => {
                    console.info('***Ws Connection Closed');
                    setSocket(null);
                    setLoaded(true);
                    //
                    setClosedCount(closed_count + 1);
                };


            }
        } else {
            //
            console.log("***Unable to initSocket, Auth not found");
            //
            setLoaded(true);
            /*
            //console.log(location);
            if ( location.pathname === "/request-order" ){
                //
            }
            */
        }
    }


    //
    useEffect(() => {

        //
        //console.log("---init socket");
        initSocket("***initSocket first time in useEffect");


    }, []);



    //
    const closeSocket = () => {
        if (socket){
            socket.close();
        }
    }


    //
    const resetMsgData = () => {
        setMessage(null);
    }





    //
    useEffect(() => {
        //console.log("....closed_count: ", closed_count, is_error);
        //
        if ( closed_count > 0 ){
            //
            let timeout = (is_error) ? 60000 : 1000;
            //console.log("....closed_count: ", closed_count, is_error, timeout);
            //
            setTimeout(function(){
                //
                initSocket("***initSocket there is no socket");
            }, timeout);
        }
    }, [closed_count, is_error]);






    //
    if( !loaded ){
        //console.log("Loading SocketCtx...");
        return (<div>...</div>);
    } else {
        //console.log("SocketCtx loaded Ok!");
    }





    //
    const contextValue = {
        socket,
        initSocket,
        closeSocket,
        msg_data,
        resetMsgData
    };

    //
    return <SocketCtx.Provider value={contextValue}>{children}</SocketCtx.Provider>;
};

export { SocketCtx, SocketCtxProvider };
