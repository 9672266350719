import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    fmtAmount,
    ucFirst,
    allowCredit,
    toFormData,
    postPublic,
} from "../../Helpers/Helper";
import {NotificationManager} from "react-notifications";
import InputMask from 'react-input-mask';
import {withAppCtx} from "../../Helpers/withAppCtx";
import {config} from "../../Config";
import BlockUi from "react-block-ui";
import SelectLoader from "../../Helpers/SelectLoader";
import {useNavigate} from "react-router-dom";
import {Form} from "react-bootstrap";


//
const PayCardANet = ({ context, customer_data, anetConfig, arr_items, onClose, onPaymentSuccess }) => {

    //
    const [loading, setLoading] = useState(false);
    //
    const {register, handleSubmit, setValue, setFocus, trigger, formState: { errors }} = useForm();
    const [phone_country_id, setPhoneCountryId] = useState('');
    const navigate = useNavigate();
    const [metodo_pago, setMetodoPago] = useState(config.ANET_CARD);



    //
    useEffect(() => {

        //console.log("---modal pay card anet");
        //console.log(anetConfig);
        //console.log("---arr_items: ", arr_items);

        let lang = context.getLang();

        //
        setFocus("cardholderName");
        setPhoneCountryId(lang.lang_country_id);

        //
        setValue("cardholderName", "ivan o juarez");
        setValue("phone_number", "6567923954");
        setValue("email", "ivanjzr@gmail.com");

        setValue("card_number", "5424-0000-0000-0015");
        setValue("exp_date", "03/2025");
        setValue("cvv", "1234");

        

    }, []);


   
    


     /* Seccion Lista Ok! */
     const handlePayment = async (post_data) => {
        console.log("---handle anet payment: ", post_data);

        
        // items
        post_data.arr_items = arr_items;
        
        // info
        if (metodo_pago === config.ANET_CARD){
            post_data.type = config.ANET_CARD;
            post_data.card_number = post_data.card_number.replace(/-/g, '');
        }
        
        // info pago credito
        else if (metodo_pago === config.CREDIT){
            post_data.type = config.CREDIT;
        }     


        



        //
        let post_options = {};
        const auth = context.getAuth()
        if (auth){ post_options.auth = auth; }

        //
        setLoading(true);

        //
        // MissionExpress = 11, Plabuz = 13, Tickets4Buses = 16
        //
        postPublic("/pay/", post_data, function(success, data){
            //console.log("---pay card post results: ", data);
            //
            if (success){
                
                //
                onPaymentSuccess();
            }
            //
            setTimeout(function(){
                setLoading(false);
            }, 1000);

        }, post_options);
            
    };


    //
    const handleSelectChange = (event) => {
        //console.log("---onChange: ", event.target.value);
        setPhoneCountryId(event.target.value);
    };



    const handleChangeFilterType = (event) => {
        //console.log(event.target);
        setMetodoPago(event.target.value);
    }


    const getANetPaymentType = () => {
        return (
            <div className="row mt-4">
                <div className="col-sm-6 col-12">
                    <div className="form-group">
                        <label htmlFor="card_number">Card Number</label>
                        <InputMask 
                            mask="9999-9999-9999-9999"
                            maskChar=""
                            type="text" 
                            className="form-control" 
                            id="card_number" 
                            name="card_number" 
                            placeholder="Card Number"
                            {...register('card_number', {
                                required: "Se requiere el numero de tarjeta",
                                pattern: {
                                    value: /^\d{4}-\d{4}-\d{4}-\d{4}$/,
                                    message: "Numero de tarjeta invalido"
                                }
                            })}
                        />
                        {errors.card_number && <label className="error">{errors.card_number.message}</label>}
                    </div>
                </div>
                <div className="col-sm-3 col-6"> 
                    <div className="form-group">
                        <label htmlFor="exp_date">Exp Date (mes/año)</label>
                        <InputMask  
                            mask="99/9999"
                            maskChar=""
                            type="text" 
                            className="form-control" 
                            id="exp_date" 
                            name="exp_date" 
                            placeholder="MM/YYYY"
                            {...register('exp_date', {
                                required: "Se requiere la fecha de expiracion",
                                pattern: {
                                    value: /^(0[1-9]|1[0-2])\/\d{4}$/,
                                    message: "Formato de fecha invalido"
                                },
                                validate: {
                                    futureDate: value => {
                                        const [month, year] = value.split('/').map(Number);
                                        const now = new Date();
                                        const expDate = new Date(year, month - 1);
                                        return expDate > now || "La tarjeta está expirada";
                                    }
                                }
                            })}
                        />
                        {errors.exp_date && <label className="error">{errors.exp_date.message}</label>}
                    </div>
                </div>
                <div className="col-sm-3 col-6">
                    <div className="form-group">
                        <label htmlFor="cvv">CVV</label>
                        <input type="text" className="form-control" id="cvv" name="cvv" 
                            placeholder="CVV"
                            {...register('cvv', {
                                required: "Se requiere el Cvv",
                                pattern: {
                                    value: /^\d{3,4}$/,
                                    message: "CVV invalido"
                                }
                            })}
                        />
                        {errors.cvv && <label className="error">{errors.cvv.message}</label>}
                    </div>
                </div>
            </div>
        )
    }


    /**
     * 
     * Establece el metodo de pago solo si el customer esta auth 
     * y ademas tiene permitido el credito
     * en caso contrario no muestra nada y el metodo por default es card
     * @returns 
     * 
     */
    const getMetodoPago = () => {
        //
        if (allowCredit(customer_data)){
            //
            var company_name = (customer_data.company_name) ? customer_data.company_name + " - " : "";
            //
            return (<>
                <div className="row mt-2 mb-4">                    
                    <div className="col-12 text-center">
                        
                        <Form.Check
                            type="radio"
                            label="Credito"
                            id="metodo_pago_credit"
                            name="metodo_pago"
                            value={config.CREDIT}
                            inline
                            style={{  }}
                            checked={metodo_pago === config.CREDIT}
                            onChange={handleChangeFilterType}
                        />
                        <Form.Check
                            type="radio"
                            label="Tarjeta"
                            id="metodo_pago_card"                            
                            name="metodo_pago" 
                            value={config.ANET_CARD}
                            inline
                            style={{ marginLeft: 50 }}
                            checked={metodo_pago === config.ANET_CARD}
                            onChange={handleChangeFilterType}
                        />

                    </div>
                </div>

                {(metodo_pago === config.ANET_CARD) ? getANetPaymentType() : (<div className='text-center'> Credito a nombre de: {company_name} {customer_data.first_name} </div>)}

            </>)
        } 
        
        //
        return getANetPaymentType();
    };



    //
    return (
        <BlockUi blocking={loading}>            
            <div className="p-2">
             
                <form onSubmit={handleSubmit(handlePayment)}>

                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                                <label htmlFor="cardholder-name">Nombre del titular de la tarjeta</label>
                                <input type="text" className="form-control"
                                    id="cardholderName"
                                    name="cardholderName"
                                    placeholder="Nombre del titular de la tarjeta"
                                    {...register('cardholderName', {
                                        required: "Se requiere el nombre del titular"
                                    })}
                                />
                                {errors.cardholderName && <label className="error">{errors.cardholderName.message}</label>}
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                                <label >Email</label>
                                <input type="email" className="form-control input-lg" name="email" id="email"
                                        placeholder="Correo Electronico"
                                        {...register('email', {
                                            required: 'introduce tu email',
                                            validate: {
                                                matchPattern: (v) =>
                                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                    "introduce un email valido",
                                            }
                                        })}
                                />
                                {errors.email && <label className="error">{errors.email.message}</label>}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-sm-5 col-md-5">
                            <div className="form-group">

                                <label className="">Codigo Pais</label>
                                <SelectLoader
                                    url={config.api_url +"/public/paises/list"}
                                    name_id={"phone_country_id"}
                                    empty_value={"--selecciona"}
                                    value={phone_country_id}
                                    onChange={handleSelectChange}
                                    parse={(item) => "+" + item.phone_cc + " (" + item.abreviado + ")"}
                                    register={{...register("phone_country_id", {
                                            required: 'selecciona el pais',
                                            onChange: handleSelectChange
                                        })}}
                                />
                                {errors.phone_country_id && <label className="error">{errors.phone_country_id.message}</label>}


                            </div>
                        </div>
                        <div className="col-sm-7 col-md-7">
                            <div className="form-group">
                                <label className="">Telefono</label>
                                <input type="number" className="form-control no-spinners" name="phone_number" id="phone_number"
                                        data-rule-required="true" data-rule-number="true" data-rule-minlength="10"
                                        data-rule-maxlength="12" placeholder="Telefono" autoComplete="off"
                                        {...register('phone_number', { required: 'introduce tu telefono' })}
                                />
                                {errors.phone_number && <label className="error">{errors.phone_number.message}</label>}
                            </div>
                        </div>
                    </div>

                    
                    
                    {getMetodoPago()}

                

                    <div className={"mt-4 text-end"}>
                        <button type="submit" className="btn btn-primary"><span className={"fas fa-check"}></span> Pagar </button>
                    </div>


                </form>


                

            </div>


        </BlockUi>
    )

}


export default withAppCtx(PayCardANet);