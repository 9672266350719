import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    postPublic,
} from "../../Helpers/Helper";
import {NotificationManager} from "react-notifications";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {config} from "../../Config";
import {useStripe, useElements, Elements, CardElement} from '@stripe/react-stripe-js';
import BlockUi from "react-block-ui";
import SelectLoader from "../../Helpers/SelectLoader";
import {useNavigate} from "react-router-dom";


const CheckoutForm = ({app_context, postVenta}) => {

    //
    const {register, handleSubmit, setValue, setFocus, trigger, formState: { errors }} = useForm();
    const [card_errors, setCardErrors] = useState(null);
    //
    const stripe = useStripe();
    const [phone_country_id, setPhoneCountryId] = useState('');
    const elements = useElements();


    //
    useEffect(() => {


        //console.log("****app_context: ", app_context);
        let lang = app_context.getLang();

        //
        setFocus("cardholderName");
        setPhoneCountryId(lang.lang_country_id);

        //
        setValue("cardholderName", "ivan o juarez");
        setValue("phone_number", "6567923954");
        setValue("email", "ivanjzr@gmail.com");
        

    }, []);



    /* Seccion Lista Ok! */
    const handleStripePayment = async (post_data) => {
        console.log("---handle stripe payment: ", post_data);

        //
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        //
        elements.billing_details = {
            name: post_data.cardholderName
        };

        //
        const result = await stripe.createPaymentMethod({
            //`Elements` instance that was used to create the Payment Element
            elements
        });
        //console.log("---result: ", result);
        //
        if (result.error) {
            //console.log("---card_errors: ", result.error.message);
            setCardErrors(result.error.message);
        }
        //
        else if (result && result.paymentMethod && result.paymentMethod.id){
            //
            setCardErrors(null);
            //
            postVenta(post_data, result.paymentMethod.id);
        }
    };



     //
     const handleSelectChange = (event) => {
        //console.log("---onChange: ", event.target.value);
        setPhoneCountryId(event.target.value);
    };




    return (
        <form onSubmit={handleSubmit(handleStripePayment)}>


            <div className="row">
                <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                        <label htmlFor="cardholder-name">Nombre del titular de la tarjeta</label>
                        <input type="text" className="form-control"
                            id="cardholderName"
                            name="cardholderName"
                            placeholder="Nombre del titular de la tarjeta"
                            {...register('cardholderName', {
                                required: "Se requiere el nombre del titular"
                            })}
                        />
                        {errors.cardholderName && <label className="error">{errors.cardholderName.message}</label>}
                    </div>
                </div>
                <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                        <label >Email</label>
                        <input type="email" className="form-control input-lg" name="email" id="email"
                                placeholder="Correo Electronico"
                                {...register('email', {
                                    required: 'introduce tu email',
                                    validate: {
                                        matchPattern: (v) =>
                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                            "introduce un email valido",
                                    }
                                })}
                        />
                        {errors.email && <label className="error">{errors.email.message}</label>}
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-5 col-md-5">
                    <div className="form-group">

                        <label className="">Codigo Pais</label>
                        <SelectLoader
                            url={config.api_url +"/public/paises/list"}
                            name_id={"phone_country_id"}
                            empty_value={"--selecciona"}
                            value={phone_country_id}
                            onChange={handleSelectChange}
                            parse={(item) => "+" + item.phone_cc + " (" + item.abreviado + ")"}
                            register={{...register("phone_country_id", {
                                    required: 'selecciona el pais',
                                    onChange: handleSelectChange
                                })}}
                        />
                        {errors.phone_country_id && <label className="error">{errors.phone_country_id.message}</label>}


                    </div>
                </div>
                <div className="col-sm-7 col-md-7">
                    <div className="form-group">
                        <label className="">Telefono</label>
                        <input type="number" className="form-control no-spinners" name="phone_number" id="phone_number"
                                data-rule-required="true" data-rule-number="true" data-rule-minlength="10"
                                data-rule-maxlength="12" placeholder="Telefono" autoComplete="off"
                                {...register('phone_number', { required: 'introduce tu telefono' })}
                        />
                        {errors.phone_number && <label className="error">{errors.phone_number.message}</label>}
                    </div>
                </div>
            </div>


            <div className="row my-4">
                <div className="col-sm-5 col-md-5">

                    <div className="form-group">

                        <label className='my-4' htmlFor="card-element">Información de la tarjeta de crédito o débito</label>

                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                        color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                        />
                        {card_errors && <label className="error">{card_errors}</label>}

                    </div>

                </div>
            </div>

            

            <div className="row my-4">
                <div className="col-12 text-end">
                    <button type="submit" className="btn btn-primary"><span className={"fas fa-check"}></span> Pagar </button>
                </div>
            </div>


        </form>
    );
};






//
const PayCardStripe = ({ context, customer_data, stripePromise, arr_items, onClose, onPaymentSuccess }) => {

    //
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [is_loading, setIsLoading] = useState(false);


    //
    useEffect(() => {
        //console.log("---modal pay card stripe");
        //console.log("---arr_items: ", arr_items);
    }, []);


    const postVenta = (post_data, paymentMethodId) => {


        // items
        post_data.arr_items = arr_items;
        // info
        post_data.payment_method_id = paymentMethodId;
        post_data.type = config.STRP_CARD;
        

         //
         let post_options = {};
         const auth = context.getAuth()
         if (auth){ post_options.auth = auth; }

        //
        setLoading(true);

        //
        // MissionExpress = 11, Plabuz = 13, Tickets4Buses = 16
        //
        postPublic("/pay/", post_data, function(success, data){
            //
            if (success){
                
                //
                onPaymentSuccess();
                
            }
            //
            setIsLoading(false);
        }, post_options);

    }


    //
    return (
        <BlockUi blocking={loading}>
            <div className="p-2">

                <Elements stripe={stripePromise}>
                    <CheckoutForm
                        app_context={context}
                        postVenta={postVenta}
                    />
                </Elements>

            </div>


        </BlockUi>
    )

}


export default withAppCtx(PayCardStripe);