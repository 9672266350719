import React, { useEffect } from 'react';
import {fmtAmount, getEmail, ucFirst} from "../../../Helpers/Helper";
import {withAppCtx} from "../../../Helpers/withAppCtx";
import {withGeoLocationCtx} from "../../../Helpers/withGeoLocationCtx";
import {config} from "../../../Config";



//
const PayTransf = ({ context, geo, sel_subscription, sel_periodo, onClose }) => {


    //
    useEffect(() => {

        console.log("---modal pay transf: ", sel_subscription);

    }, []);



    //
    return (<>
            <div className="row">
                <div className="col-sm-6 col-12">
                    <h4 style={{margin:"0", padding:"0"}}>
                        Transferencia
                    </h4>
                </div>
                <div className="col-sm-6 col-12 text-right">

                    <button type="button" className="btn btn-secondary" onClick={onClose} style={{marginLeft:"10px"}}>
                        <span className="fa fa-times"></span> Salir
                    </button>

                </div>
            </div>

            <hr className="mt-2 mb-4"/>

            <div className="p-4">


                <div className="form-group">
                    <h4>
                        Plan {ucFirst(sel_subscription.nombre)} {ucFirst(sel_periodo.selected_periodo)} <small>{fmtAmount(sel_periodo.final_price)}</small>

                        {(sel_periodo.orig_price === sel_periodo.final_price) ? (
                            <small style={{color:"orangered", fontSize:12}}> ( No Aplica Descuento ) </small>
                        ) : (
                            <small style={{fontSize:14}}><br /> Descuento de <span style={{color:"green"}}> %{sel_periodo.disc_perc} </span> ( Ahorra {fmtAmount(sel_periodo.disc_amt)} )
                                Antes: <span style={{textDecoration:"line-through", color:"orangered"}}> {fmtAmount(sel_periodo.orig_price)} </span>
                            </small>
                        )}

                    </h4>
                </div>

                <div className="form-group">
                    <label>Titular:</label>
                    <h4>{config.AppName} - {geo.city}</h4>
                </div>

                <div className="form-group">
                    <label>Clabe: </label>
                    <h4>1271 6401 3326 1303 18</h4>
                </div>

                <small style={{display:"block"}} className="mt-4 mb-2 text-gray"> Recuerda pagar la cantidad exacta de
                    la suscripcion, posterior a tu pago mandanos un screenshot a <strong>{getEmail()}</strong>
                </small>


            </div>

        </>
    )

}


export default withAppCtx(withGeoLocationCtx(PayTransf));