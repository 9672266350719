import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-notifications/lib/notifications.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import 'react-block-ui/style.css';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AppCtxProvider} from "./AppCtx";
import axios from "axios";


// fix: defaults para evitar enviar el Content-Type text/html y despues saque la sesion por error
axios.defaults.headers.get['Content-Type'] = 'application/json';
//axios.defaults.headers.post['Content-Type'] = 'application/json';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppCtxProvider>
        {/*<React.StrictMode>*/}
        <PrimeReactProvider>
            <App />
        </PrimeReactProvider>
        {/*</React.StrictMode>*/}
    </AppCtxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
