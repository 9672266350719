import React, { useRef } from 'react';
import _ from 'lodash';

import {
    mDateFormat,
} from "../Helpers/Helper";







export function VentasParser(){
    
    //
    return {
        parseSalida: (item) => {
            return (<div> 
                {item.id} / {item.ruta_id} / {item.autobus_clave} 
            </div>)
        },
        parseSeat: (item) => {
            //console.log("----iter item: ", item);
            
            const strSeatInfo = (
                <div style={{ fontSize: '24px', lineHeight: '18px' }}>
                    #{item.num_asiento} - {item.passanger_name}
                </div>
            );
            return (<div>
                <input type="hidden" className="item-id" value={item.item_insert_id} />
                {item.id} {strSeatInfo}
                <br />
                <small style={{ color: 'gray' }}>
                    {mDateFormat(item.passanger_dob, 'DD MMM YYYY')} / {item.passanger_age} años / {item.tipo_precio_descripcion}
                </small>
            </div>)
            
        },
        parseOrigen: (item) => {
    
            var str_ext_destino = (item.ext_destino_ciudad_id) ? "<br /><small>extension a " + item.ext_destino_info + "</small> " : "";
            return <div> {item.origen_ciudad_info} <br /> {mDateFormat(item.fecha_hora_salida.date, "DD MMM YY h:mm A")} {str_ext_destino} </div>
        },
        parseDestino: (item) => {
            var str_ext_origen = (item.ext_origen_ciudad_id) ? "<br /><small>extension de " + item.ext_origen_info + "</small> " : "";
            return <div> {item.destino_ciudad_info} <br /> {mDateFormat(item.fecha_hora_llegada.date, "DD MMM YY h:mm A")} {str_ext_origen} </div>        
        },
        parsePrice: (item) => {
            return <div>{item.total}</div>;
        },       
    }
    
};


function getComisiones(item){
    //
    if ( parseFloat(item.comisiones) > 0 ){
        return  (<>
            <br /><small style={{color:"blue"}}>comisiones: {item.comisiones}, a entregar: {item.total_sin_comisiones}</small>
        </>)
    }
    return null;    
}



export function FrontPageVentasParser(options){
    

    let div_display_type = (options && options.display_type) ? options.display_type : "block";

    //
    return {
        parseCustomerData: (item) => {        
            return (
                <div>
                    {item.customer_id && item.company_name && (
                        <strong style={{ fontWeight: 'bold' }}>
                            {item.company_name}
                        </strong>
                    )}
                    {item.customer_id && item.company_name && " "}
                    <span>{item.customer_name}</span>
                    <br />
                    {item.email} ({item.phone_number})
                </div>
            );
        },    
        parseItems: (item) => {
            return item.salidas_ocupacion.map((item) => {
                //
                const dataInfo = JSON.stringify({
                    salida_id: item.salida_id,
                    fecha_hora_salida: item.fecha_hora_salida,
                    fecha_hora_llegada: item.fecha_hora_llegada
                });
                //    
                return (
                    <div key={item.salida_id}>

                        <ul style={{ padding: 0, margin: 0 }}>
                            <li><strong> Seat #{item.num_asiento} </strong> - {item.passanger_name}</li>
                            <li>&raquo; {item.origen_info} - {mDateFormat(item.fecha_hora_salida.date, "DD MMM YY h:mm A")}</li>
                            <li>&laquo; {item.destino_info} - {mDateFormat(item.fecha_hora_llegada.date, "DD MMM YY h:mm A")}</li>
                        </ul>
                        
                        <hr />
                        
                        {parseFloat(item.costo_ext_salida) || parseFloat(item.costo_ext_llegada) ? (
                            <ul style={{ padding: 0, margin: 0 }}>
                                <li>costo: {item.costo_origen_destino}</li>
                                {item.costo_ext_salida && <li>costo extension salida: {item.costo_ext_salida}</li>}
                                {item.costo_ext_llegada && <li>costo extension llegada: {item.costo_ext_llegada}</li>}
                                <li>total Pasaje: {item.total} {/*getComisiones(item)*/} </li>
                            </ul>
                        ) : (<ul style={{ padding: 0, margin: 0 }}>
                                <li>total Pasaje: ${item.total} &emsp; <a href={item.ticket_url+"?pdf=1"} target='_blank' className='btn btn-sm btn-primary'><i className='fas fa-print'></i></a> {/*getComisiones(item)*/} </li>
                            </ul>)}

                        
    
                        {item.is_temp_salida && (() => {
                            const itemDatetimeCreated = mDateFormat(item.datetime_created.date, "DD MMM YY h:mm A");        
                            return (
                                <div style={{ backgroundColor: '#ffffd8' }}>
                                    {itemDatetimeCreated} 
                                </div>
                            );
                        })()}
                        
                        
                    </div>
                );
            });
        },    
        parseTotalAndPayments: (item) => {
            return (<>
                <ul>
                    { (item.discount_percent > 0 || item.tax_amount > 0) && (
                        <>
                            
                            <li><strong>Subtotal: </strong>{item.sub_total}</li>
    
                            { item.discount_percent > 0 ? (
                                <li><strong>Discount %: </strong>{item.discount_percent}: -{item.discount_amount}</li>
                            ) : (
                                item.discount_amount > 0 && <li><strong>Discount: </strong> -{item.discount_amount}</li>
                            )}
    
                            { item.tax_amount > 0 && (
                                <li><strong>Tax %: </strong>{item.tax_percent}: {item.tax_amount}</li>
                            )}
    
                        </>
                    )}
                    <li><strong>Total: </strong>{item.grand_total}</li>
                </ul>

                
                {item.sale_payments.length ? (<ul>
                    <hr />
                    {item.sale_payments.map((payment, idx) => {
                        const strTransactionId = payment.transaction_id ? (<>
                            <br /><small style={{ color: 'gray' }}>({payment.transaction_id})</small>
                        </>) : null;
    
                        return (
                            <li key={idx}>
                                <strong style={{ color: 'green' }}> {payment.payment_type} </strong> {payment.amount} 
                                <small style={{ color: 'gray' }}> {payment.tipo_moneda} </small> {payment.payment_status} 
                                {strTransactionId}
                            </li>
                        );
                    })}
                </ul>) : null}               


            </>)
        },        
        parseDate: (item) => {
            return (<>
                {mDateFormat(item.datetime_created.date, "DD MMM YY h:mm A")}
            </>)
        },    
        parseCredito: (item) => {
            return (<>
                {item.a_credito ? (
                    <>
                        {item.seller_accepted ? (
                            <small style={{ color: 'green' }}>
                                <i className="fas fa-check" style={{ color: 'green' }}></i> aceptado
                            </small>
                        ) : (
                            <small style={{ color: 'orangered' }}>sin-aceptar</small>
                        )}
                    </>
                ) : ""}
            </>)
        },
        parsePaid: (item) => {

            if (item.a_credito){
                return null;
            } else {
                return (<>

                    {item.sale_paid ? (
                        <small style={{ color: 'green' }}>
                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                        </small>
                    ) : (
                        <small style={{ color: 'orangered' }}>--sin pagar</small>
                    )}
                </>)
            }
        },
        parseComision: (item) => {
            let strInfo = '';

            if (item.por_pagar_a_cte) {
                strInfo = (<>

                    {item.is_cobrado_pagado ? (
                        <span style={{ color: 'green', display:div_display_type }}>
                            Cobrado <i className="fas fa-check"></i>
                        </span>
                    ) : (
                        <span style={{ color: 'orangered', display:div_display_type }}>--sin-cobrar</span>
                    )}

                </>);
            }            
            return (<>
                <div> {item.comi_monto} {strInfo} </div>
            </>);
        },
        parseVenta: (item) => {
            let strInfo = '';
            if (item.por_cobrar_a_cte) {
                strInfo = (<>

                    {item.is_cobrado_pagado ? (
                        <span style={{ color: 'green', display:div_display_type }}>
                            Pagado <i className="fas fa-check"></i>
                        </span>
                    ) : (
                        <span style={{ color: 'orangered', display:div_display_type }}>--sin-pagar</span>
                    )}

                </>);
            }

            return (<>
                <div> {item.new_total} {strInfo} </div>
            </>);
        }  
    }
    
};

