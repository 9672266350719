import React, { useEffect } from 'react';
import {config} from "../Config";
import { NavLink } from 'react-router-dom';




const Notfound = ({ context, geo }) => {



    //
    useEffect(() => {
        //
        document.title = config.AppName + " - 404 Not Found";
    }, []);







    //
    return (<>


        <div className="mt-5 py-5 text-center">


                <h2 className="text-dark"><span className="fa fa-info-circle"></span> 404 Pagina no encontrada </h2>


                <div style={{display: "flex", alignItems: "center"}}>

                    <img src={process.env.PUBLIC_URL + '/images/gpig-small.png'} style={{marginRight: "10px"}} />
                    <p>
                        Parece que el recurso que buscas no se encontro regresa a la pagina de inicio y busca en el menu
                    </p>
                </div>

                <NavLink to="/" className="btn btn-primary"><i
                    className="icofont-reply"></i> Regresar
                </NavLink>



        </div>




    </>)


}


export default Notfound;