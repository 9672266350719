import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {NotificationManager} from "react-notifications";
import InputMask from 'react-input-mask';
import {
    DivAlert,
    getLogo,
    IsCustomerLogged,
    addSaleItem,
    getSaleItems,
    postCAuth,
    postApi,
    postPublic,
    mDateFormat,
    mDateObj,
    getCurrentDateFormat,
    getCurrentDateObj,
    setContextData,
    socketSend,
    toFormData
} from "../../Helpers/Helper";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {config} from "../../Config";
import BlockUi from "react-block-ui";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";




//
const FormRegisterPassanger = ({ context, selected_salida, selected_seat, onPassangerAdded }) => {

    //
    const [loading, setLoading] = useState(false);
    const [is_loading, setIsLoading] = useState(false);


    //
    const {register, setFocus, setValue, getValues, setError, handleSubmit, formState: { errors }} = useForm();
    const navigate = useNavigate();

    const [passanger_dob, setPassangerDob] = useState();
    const [results_info, setResultsInfo] = useState(null);



    useEffect(() => {
        if (passanger_dob) {
          calcDob();
        }
      }, [passanger_dob]);




      useEffect(() => {

        //console.log("selected_salida: ", selected_salida);console.log("selected_seat: ", selected_seat);
        //console.log("Context: ", context);

        setValue("ruta_id", selected_salida.ruta_id);
        setValue("salida_id", selected_salida.id);
        setValue("origen_ciudad_id", selected_salida.origen_ciudad_id);
        setValue("destino_ciudad_id", selected_salida.destino_ciudad_id);
        setValue("destino_ciudad_id", selected_salida.destino_ciudad_id);
        //
        const initial_date = new Date(1935, 0, 2);
        setPassangerDob(initial_date);

        //
        setTimeout(function(){
            setFocus("passanger_name");    
        }, 500);
        


      }, []);


      
      

    
    //
    const calcDob = () => {


        //
        var post_data = getValues();       

        //
        post_data.dob = mDateFormat(passanger_dob, "YYYY-MM-DD");
        //console.log("calcDob - form_data: ", post_data);

        //
        setIsLoading(true);
        // 
        postPublic(`/utils/calc-dob`, toFormData(post_data), function(success, data){
            //console.log("---post calc-dob: ", data);
            //
            if (success){

                //                
                setResultsInfo(data);

            } else {


                
            }
            //
            setIsLoading(false);
        }, {
            conteTypeForm: true
        });
    };




    //
    const onSubmit = (post_data) => {
        //
        post_data.dob = mDateFormat(passanger_dob, "YYYY-MM-DD");
        //console.log("Subnut - form_data: ", post_data);


        //
        var post_data = {
            visitor_id: context.visitorId,
            temp_sale_id: config.temp_sale_id,
            ruta_id: selected_salida.ruta_id,
            salida_id: selected_salida.id,            
            origen_ciudad_id: selected_salida.origen_ciudad_id,
            destino_ciudad_id: selected_salida.destino_ciudad_id,
            lugar_id: selected_seat.id,
            passanger_name: post_data.passanger_name,
            passanger_dob: mDateFormat(passanger_dob, "YYYY-MM-DD"),
            passanger_email: null,
        }

        //
        setIsLoading(true);
        // 
        postPublic(`/utils/apartar`, toFormData(post_data), function(success, data){
            //console.log("---post apartar: ", data);
            //
            if (success){
                //
                addSaleItem(data);
                onPassangerAdded();
            }
            //
            setIsLoading(false);
        }, {
            conteTypeForm: true
        });
    };







    
    //
    return (
        <BlockUi className='' blocking={is_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> Enviando Solicitud </div>)}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">
                
                <div className="row">
                    <div className="col-md-4 col-6">
                        <div className="form-group">

                            <label >Nombre Pasajero </label>
                            <input type="text" className="form-control input-lg" name="passanger_name" id="passanger_name"
                                    placeholder="Nombre(s)"
                                    {...register('passanger_name', { required: 'introduce tu nombre(s)' })}
                            />
                            {errors.passanger_name && <label className="error">{errors.passanger_name.message}</label>}
                            
                        </div>
                    </div>
                    <div className="col-md-4 col-6">
                        <div className="form-group">
                            
                            <label>Fecha Nacimiento</label>
                            <br />

                            <DatePicker
                                dateFormat="Y-MM-d"
                                className="form-control input-lg" 
                                name="passanger_dob" 
                                id="passanger_dob"
                                selected={passanger_dob} 
                                onChange={(date) => {
                                    //console.log("****date: ", date)
                                    setPassangerDob(date);
                                }}
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                yearDropdownItemNumber={100} // Muestra 100 años en el dropdown
                                scrollableYearDropdown
                                //openToDate={new Date(2000, 0, 1)}
                                maxDate={new Date()} // Fecha máxima (hoy)
                                />

                            {errors.passanger_dob && <label className="error">{errors.passanger_dob.message}</label>}
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-2 col-6">
                        
                        <label>Edad </label>
                        <div className='fw-bold'>{results_info && results_info.passanger_age ? results_info.passanger_age : 0}</div>

                        <input type="hidden" className="form-control input-lg" name="passanger_age" id="passanger_age"
                                value={results_info && results_info.passanger_age ? results_info.passanger_age : 0} 
                        />
                        

                    </div>
                    <div className="col-md-3 col-6">

                        <label>Clasificacion </label>
                        <div className='fw-bold'>{results_info && results_info.clave ? results_info.clave + ' - ' + results_info.descripcion : ''}</div>

                        <input type="hidden" className="form-control input-lg" name="classificacion" id="classificacion"
                                value={results_info && results_info.clave ? results_info.clave + ' - ' + results_info.descripcion : ''}
                        />
                        
                        
                    </div>
                    <div className="col-md-3 col-6">

                        <label>Precio </label>
                        <div className='fw-bold'>{results_info && results_info.id ? results_info.new_precio : ''}</div>
                        {(results_info && results_info.calc_info) ? (<small>{results_info.calc_info}</small>) : null}

                        <input type="hidden" className="form-control input-lg" name="new_precio" id="new_precio"
                                placeholder="Precio"
                                value={results_info && results_info.id ? results_info.new_precio : ''}
                        />
                        
                        
                    </div>
                    <div className="col-md-4 col-6 text-end">                        
                        
                        <button type="submit" className="btn btn-primary">Enviar</button>

                    </div>
                </div>
                


        
            </form>
        </BlockUi>
    )

}


export default withAppCtx(FormRegisterPassanger);