import React, { useContext, useState, useRef, useEffect } from 'react';
import axios from "axios";
import {config} from "../Config";
import {NotificationManager} from "react-notifications";
import moment from "moment";
import _ from 'lodash';
import {Alert} from "react-bootstrap";


//
export function ucFirst(str){
    return str.replace(/\b\w/g, function(match) {
        return match.toUpperCase();
    });
}


export function resetUrl(){
    const newUrl = `${window.location.origin}${window.location.pathname}`;
    window.history.replaceState({}, document.title, newUrl);
};


export function SqrGenUUID(){
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};



export function preloaderPlaceholder(height){
    return (
        <div style={{height:(height) ? height : 100}}></div>
    )
};

// Moment Get Time
export function mGetTime(dateString){
    const formattedDate = moment(dateString);
    return formattedDate.format('HH:mm');
}



export function mDateObj(dateString){
    return moment(dateString);
}

export function getDurationText(diffInSeconds){
    const duration = moment.duration(diffInSeconds, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let result = '';
    if (hours > 0) {
        result += `${hours} horas, `;
    }
    if (minutes > 0) {
        result += `${minutes} minutos y `;
    }
    result += `${seconds} segundos`;

    return result;
}


export function mDateFormat(dateString, format){
    return moment(dateString).format(format);
}

export function getCurrentDateFormat(format){
    return moment().format(format);
}


export function getCurrentDateObj(){
    return moment();
}

export function shortenString(originalString, maxLength) {
    if (originalString.length > maxLength) {
        return originalString.slice(0, maxLength) + '...';
    }
    return originalString;
}



export function calcularMontoAndDiscounts(cant_meses, precio){

    // cantidad meses
    let cm = parseInt(cant_meses);

    // original price
    let op = (parseFloat(precio) * cm);

    // discount percent
    let dp = 0;


    //
    if ( cm === 3 ){
        dp = 15;
    }
    else if ( cm === 6 ){
        dp = 20;
    }
    else if ( cm === 12 ){
        dp = 25;
    }

    // discount amount
    let da = (op * dp / 100);

    // original price menos discount amount
    let fp = (op - da);

    //
    return {
        orig_price: op,
        disc_perc: dp,
        disc_amt: da,
        final_price: fp
    }
}


//
export function getLogo(){
    //
    if ( config.appId === config.AppIdP1 ) {
        return "/images/11/logo.png";
    }
    else if ( config.appId === config.AppIdP2 ) {
        return "/images/13/logo.png";
    }
    else if ( config.appId === config.AppIdP3 ) {
        return "/images/16/logo.png";
    }
    return null;
}


export function getEmail(){
    //
    if ( config.appId === config.AppIdP1 ) {
        return "info@qponea.com";
    }
    else if ( config.appId === config.AppIdP2 ) {
        return "info@pedidero.com";
    }
    else if ( config.appId === config.AppIdP3 ) {
        return "info@yonkeparts.com";
    }
    return null;
}



export function fmtAmount(amount) {
    return "$" + parseFloat(amount).toLocaleString('en-US', { maximumFractionDigits: 2 });
}


export function LoadingImg({width}) {
    return (<img style={{width:(width) ? width : 20}} src={process.env.PUBLIC_URL + '/images/loading.gif'} />)
}



export function convertirMinutosADiasHorasMinutos(minutos) {
    if (minutos < 0) {
        return "Los minutos no pueden ser negativos";
    }

    const minutosEnUnDia = 24 * 60;
    const minutosEnUnaHora = 60;

    const dias = Math.floor(minutos / minutosEnUnDia);
    minutos %= minutosEnUnDia;

    const horas = Math.floor(minutos / minutosEnUnaHora);
    minutos %= minutosEnUnaHora;

    //
    let str_dias = (dias > 0 ) ? `${dias} días, ` : "";
    let str_horas = (horas > 0 ) ? `${horas} horas, ` : "";
    let str_minutos = (minutos > 0 ) ? `${minutos} minutos` : "";
    //
    return str_dias + str_horas + str_minutos;
}




//
export function getOrderItems(context){
    //
    let { data } = context;
    //
    return ( data.order && data.order.length ) ? data.order : [];
}



//
export function parseItem(arr_order, new_item){
    //
    let item_found = null;
    //
    arr_order.forEach((item, index) => {
        //console.log(item, index);
        //
        if ( parseInt(item.id) === parseInt(new_item.id) ){
            item_found = item;
        }
    });
    // si se encontro lo sumamos
    if (item_found){
        console.log("***item found");
        item_found.qty = (item_found.qty + new_item.qty);
        return item_found;
    }
    console.log("***item NOT found");
    return new_item;
}



export function getSeconds(seconds){
    return seconds * 1000;
}





export function setContextData(context, obj_name, data){
    //
    let new_context_data = { ...context.data };
    //
    new_context_data[obj_name] = data;
    //
    context.updateData(new_context_data);
}


export function updateContextDataValue(context, obj_name, field_name, value){
    //
    let new_context_data = { ...context.data };
    //
    if (new_context_data[obj_name][field_name]){
        new_context_data[obj_name][field_name] = value;
    }
    //
    context.updateData(new_context_data);
}



//
export function updateContextData(context, obj_name, newData){
    //
    let new_context_data = { ...context.data };
    //
    if (new_context_data[obj_name]){
        //
        for (let key in newData) {
            new_context_data[obj_name][key] = newData[key];
        }
    }

    //
    context.updateData(new_context_data);
}


//
export function updateContextDataItem(context, obj_name, item_name, newData){
    //
    let new_context_data = { ...context.data };
    //
    if ( new_context_data[obj_name] ){
        new_context_data[obj_name][item_name] = newData;
    }
    //
    context.updateData(new_context_data);
}






//
export function readFileToSrc(file, callback){
    //
    var reader = new FileReader();
    reader.onload = function(e) {
        callback(e.target.result);
    };
    reader.readAsDataURL(file);
}






//
export function delContextData(context, obj_name){
    //
    if ( context.data && context.data[obj_name] ){
        // clonamos el objeto
        let new_context_data = { ...context.data };
        // eliminamos del objeto clonado
        delete new_context_data[obj_name];
        // set new values
        context.updateData(new_context_data);
    }
}


//
export function delSetContextData(context, del_name, new_obj_name, data){
    //
    if ( context.data && context.data[del_name] ){

        // clonamos el objeto
        let new_context_data = { ...context.data };

        // eliminamos del objeto clonado
        delete new_context_data[del_name];

        // pasamos el nuevo obj con su valor
        new_context_data[new_obj_name] = data;

        // set new values
        context.updateData(new_context_data);
    }
}

export function getSaleTotals(arr_sale_items){
    //
    return arr_sale_items.reduce((accumulator, the_item) => accumulator + parseFloat(the_item.total), 0);
}


const arr_sale_key_name = "arr_sale_items";


export function getSaleItems(){
    //
    const storedData = localStorage.getItem(arr_sale_key_name);
    //console.log("***storedData: ", storedData);
    //
    if (storedData) {
        //
        var parsed_data = JSON.parse(storedData);
        //console.log("***parsed_data: ", parsed_data);        
        //
        if (parsed_data && parsed_data.length){
            return parsed_data;
        }
    }
    //
    return [];
}

export function updateSaleItems(arr_items){
    localStorage.setItem(arr_sale_key_name, JSON.stringify(arr_items));
}

export function clearSaleItems(){
    updateSaleItems([]);
}

export function delSaleItem(item_id){
    //     
    let arr_items = getSaleItems().filter(the_item => the_item.id !== item_id);
    //console.log("**********new arr_items: ", arr_items);
    updateSaleItems(arr_items);
}


export function addSaleItem(new_item){
    //    
    let arr_items = getSaleItems();
    console.log("*** arr_items Inicial: ", arr_items);

    arr_items.push(new_item);
    console.log("*** Despues: ", arr_items);

    updateSaleItems(arr_items);
}


export function updateSaleItem(item_id, new_item){        
    
    let arr_items = getSaleItems();
    //console.log("*** arr_items: ", arr_items);

    //
    arr_items.map((item, idx) => {
        //
        if ( item.id === item_id ){
            console.log("***Item Index "+idx+" with Id: "+item.id+" MATCH OK", item);
            arr_items[idx] = new_item;
        } else {
            console.log("***Item Index "+idx+" with Id: "+item.id+" DO NOT MATCH");
        }
    });

    //
    updateSaleItems(arr_items);
}



export function isSeller(customer_data){
    //console.log("***customer_data isSeller: ", customer_data);
    if (customer_data && customer_data.id && (parseInt(customer_data.customer_type_id) === 2) ){
        return true;
    }
    return false;
}



/**
 *
 * Verifica si esta auth, si es tipo business y ademas si tiene permitido credito
 */
export function allowCredit(customer_data){
    //console.log("***customer_data allowCredit: ", customer_data);
    if (customer_data && customer_data.allow_credit && isSeller(customer_data)){
        return true;
    }
    return false;
}


export function setAuthAndClear(context, data){

    // clonamos el objeto
    let new_context_data = { ...context.data };

    //
    if ( new_context_data.reg ){
        delete new_context_data.reg;
    }
    if ( new_context_data.activation_code ){
        delete new_context_data.activation_code;
    }

    // pasamos el nuevo obj con su valor
    new_context_data.auth = data;

    // set new values
    context.updateData(new_context_data);

}


export function setOrderIdAndClear(context, new_order_id){
    // clonamos el objeto
    let new_context_data = { ...context.data };

    //
    if ( new_context_data.selected_store ){
        delete new_context_data.selected_store;
    }
    if ( new_context_data.order ){
        delete new_context_data.order;
    }

    // pasamos el nuevo obj con su valor
    new_context_data.new_order_id = new_order_id;

    // set new values
    context.updateData(new_context_data);
}




export function fmtTimeStamp(datetime){

    return moment(datetime).format("YYYY-MM-DD-HH:mm");
}

//
export function fmtDateSpanish(field_value, inc_time){

    var dt = moment(field_value);
    if (field_value && dt){
        if (inc_time){
            return dt.format("DD MMM YYYY - h:mm A");
        } else {
            return dt.format("DD MMMM YYYY");
        }
    }
    return "";
}


//
export function toFormData(data){
    //
    const formData = new FormData();
    //
    Object.entries(data).forEach(([key, value]) => {
        // es archivo
        if (value instanceof FileList) {
            // agregar cada archivo
            for (let i = 0; i < value.length; i++) {
                formData.append(key, value[i]);
            }
        } else {
            // es otro
            formData.append(key, value);
        }
    });
    //
    return formData;
}



// 
export function getComisionInfo(customer_data){
    //
    let strComision = '';
    let strCredito = '';
    //
    if (isSeller(customer_data)){

        if (customer_data.allow_credit) {
            strCredito = (<small style={{ color: 'green' }}>&nbsp; ( <i className="fas fa-check" style={{ color: 'green' }}></i> Credito Autorizado ) </small>);
        }
    
        if (customer_data.comision_tipo === 'm' && customer_data.comision_valor > 0 ) {
            strComision = (<span> Comision por Monto <strong>${customer_data.comision_valor}</strong> </span>);
        } else if (customer_data.comision_tipo === 'p' && customer_data.comision_valor > 0 ) {
            strComision = (<span> Comision por Porcentaje <strong>{customer_data.comision_valor}%</strong> </span>);
        } else {
            strComision = (<small style={{color:"orangered"}}>--sin comision asignada</small>);
        }
    
        return (
            <div className='my-0'>
                {strComision} {strCredito}
            </div>
        );

    }
};



//
export function getCAuth(auth, endpoint, callback, callName){
    //
    axios.get(config.auth_cust_url + endpoint, {
        headers: {
            'Authorization': auth.token,
            'Utype': auth.sale_type_id
        }
    })
        .then(response => {
            //console.log("--- get cust " + endpoint + " data: ", response.data);
            //
            if ( response && response.data && (response.data.id || (response.data.data && response.data.data.length)) ){
                //
                callback(true, response.data);
            }
            //
            else if (response.data && response.data.error) {
                //
                let err = response.data.error;
                NotificationManager.info(err);
                callback(false, err);
            }
            //
            else if (callName) {
                let err = "No se pudieron obtener los datos " + callName;
                NotificationManager.info(err);
                callback(false, err);
            } else {
                callback(false);
            }
        })
        .catch(error => {
            //console.log(error);
            let err = (error && error.message) ? error.message: "Network error or no internet connection";
            NotificationManager.info(err);
            callback(false, err);
        });
}


//
export function postCAuth(auth, endpoint, postData, callback, callName){
    //
    axios.post(config.auth_cust_url + endpoint, postData, {
        headers: {
            'Authorization': auth.token,
            'Utype': auth.sale_type_id
        },
    })
        .then(response => {
            //console.log("--- post cust " + endpoint + " data: ", response.data);
            //
            if (response.data && response.data.id){
                //
                callback(true, response.data);
            }
            //
            else if (response.data && response.data.error) {
                //
                let err = response.data.error;
                NotificationManager.info(err);
                callback(false, err);
            }
            //
            else if (callName) {
                let err = "No se pudieron enviar los datos " + callName;
                NotificationManager.info(err);
                callback(false, err);
            } else {
                callback(false);
            }
        })
        .catch(error => {
            //console.log(error);
            let err = (error && error.message) ? error.message: "Network error or no internet connection";
            NotificationManager.info(err);
            callback(false, err);
        });
}



//
export function getBAuth(auth, endpoint, callback, callName){
    //
    axios.get(config.auth_store_url + endpoint, {
        headers: {
            'Authorization': auth.token,
            'Utype': auth.sale_type_id
        },
    })
        .then(response => {
            //console.log("--- get store " + endpoint + " data: ", response.data);
            //
            if ( response && response.data && (response.data.id || (response.data.data && response.data.data.length)) ){
                //
                callback(true, response.data);
            }
            //
            else if (response.data && response.data.error) {
                //
                let err = response.data.error;
                NotificationManager.info(err);
                callback(false, err);
            }
            //
            else if (callName) {
                let err = "No se pudieron obtener los datos " + callName;
                NotificationManager.info(err);
                callback(false, err);
            } else {
                callback(false);
            }
        })
        .catch(error => {
            //console.log(error);
            let err = (error && error.message) ? error.message: "Network error or no internet connection";
            NotificationManager.info(err);
            callback(false, err);
        });
}


//
export function postBAuth(auth, endpoint, postData, callback, callName){
    //
    axios.post(config.auth_store_url + endpoint, postData, {
        headers: {
            'Authorization': auth.token,
            'Utype': auth.sale_type_id
        },
    })
        .then(response => {
            //console.log("--- post cust " + endpoint + " data: ", response.data);
            //
            if (response.data && response.data.id){
                //
                callback(true, response.data);
            }
            //
            else if (response.data && response.data.error) {
                //
                let err = response.data.error;
                NotificationManager.info(err);
                callback(false, err);
            }
            //
            else if (callName) {
                let err = "No se pudieron enviar los datos " + callName;
                NotificationManager.info(err);
                callback(false, err);
            } else {
                callback(false);
            }
        })
        .catch(error => {
            //console.log(error);
            let err = (error && error.message) ? error.message: "Network error or no internet connection";
            NotificationManager.info(err);
            callback(false, err);
        });
}



//
export function getApi(endpoint, callback, callName){
    //
    axios.get(config.api_url + endpoint)
        .then(response => {
            //console.log("--- get public " + endpoint + " data: ", response.data);
            //
            if ( response && response.data && (response.data.id || (response.data.data && response.data.data.length)) ){
                //
                callback(true, response.data);
            }
            //
            else if (response.data && response.data.error) {
                //
                let err = response.data.error;
                NotificationManager.info(err);
                callback(false, err);
            }
            //
            else if (callName) {
                let err = "No se pudieron obtener los datos " + callName;
                NotificationManager.info(err);
                callback(false, err);
            } else {
                callback(false);
            }
        })
        .catch(error => {
            //console.log(error);
            let err = (error && error.message) ? error.message: "Network error or no internet connection";
            NotificationManager.info(err);
            callback(false, err);
        });
}


//
export function postPublic(endpoint, postData, callback, options, callName){
    //
    let headers = {}
    //
    if (options){
        // 
        if (options.conteTypeForm){
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        //
        if (options.auth && options.auth.token){
            headers['Authorization'] = options.auth.token;
            headers['Utype'] = options.auth.sale_type_id;
        }
    }
    //
    axios.post(config.public_url + endpoint, postData, {headers})
        .then(response => {
            //console.log("--- post public " + endpoint + " data: ", response.data);
            //
            if (response.data && ( response.data.id || response.data.success  || response.data.length )){
                //
                callback(true, response.data);
            }
            //
            else if (response.data && response.data.error) {
                //
                let err = response.data.error;
                NotificationManager.info(err);
                callback(false, err);
            }
            //
            else if (callName) {
                let err = "No se pudieron enviar los datos " + callName;
                NotificationManager.info(err);
                callback(false, err);
            } else {
                callback(false);
            }
        })
        .catch(error => {
            //console.log(error);
            let err = (error && error.message) ? error.message: "Network error or no internet connection";
            NotificationManager.info(err);
            callback(false, err);
        });
}





//
export function postApi(endpoint, postData, callback, options, callName){
    //
    let headers = {}
    //
    if (options){
        //
        if (options.conteTypeForm){
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
    }
    //
    axios.post(config.api_url + endpoint, postData, {headers})
        .then(response => {
            console.log("--- post public " + endpoint + " data: ", response.data);
            //
            if (response.data && ( response.data.id || response.data.success )){
                //
                callback(true, response.data);
            }
            //
            else if (response.data && response.data.error) {
                //
                let err = response.data.error;
                NotificationManager.info(err);
                callback(false, err);
            }
            //
            else if (callName) {
                let err = "No se pudieron enviar los datos " + callName;
                NotificationManager.info(err);
                callback(false, err);
            } else {
                callback(false);
            }
        })
        .catch(error => {
            //console.log(error);
            let err = (error && error.message) ? error.message: "Network error or no internet connection";
            NotificationManager.info(err);
            callback(false, err);
        });
}



//
export function post(auth, endpoint, postData, options, callback, callName){
    //
    let headers = {
        'Authorization': auth.token,
        'Utype': auth.sale_type_id
    }
    //
    if (options){
        //
        if (options.conteTypeForm){
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
    }
    //
    axios.post(config.api_url + endpoint, postData, {headers})
        .then(response => {
            console.log("--- post public " + endpoint + " data: ", response.data);
            //
            if (response.data && ( response.data.id || response.data.success )){
                //
                callback(true, response.data);
            }
            //
            else if (response.data && response.data.error) {
                //
                let err = response.data.error;
                NotificationManager.info(err);
                callback(false, err);
            }
            //
            else if (callName) {
                let err = "No se pudieron enviar los datos " + callName;
                NotificationManager.info(err);
                callback(false, err);
            } else {
                callback(false);
            }
        })
        .catch(error => {
            //console.log(error);
            let err = (error && error.message) ? error.message: "Network error or no internet connection";
            NotificationManager.info(err);
            callback(false, err);
        });
}



//
export function get(auth, endpoint, callback, callName){
    //
    let headers = {
        'Authorization': auth.token,
        'Utype': auth.sale_type_id
    }
    //
    axios.get(config.api_url + endpoint,{headers})
        .then(response => {
            console.log("--- get public " + endpoint + " data: ", response.data);
            //
            if (response.data && ( response.data.id || response.data.success )){
                //
                callback(true, response.data);
            }
            //
            else if (response.data && response.data.error) {
                //
                let err = response.data.error;
                NotificationManager.info(err);
                callback(false, err);
            }
            //
            else if (callName) {
                let err = "No se pudieron obtener los datos " + callName;
                NotificationManager.info(err);
                callback(false, err);
            } else {
                callback(false);
            }
        })
        .catch(error => {
            //console.log(error);
            let err = (error && error.message) ? error.message: "Network error or no internet connection";
            NotificationManager.info(err);
            callback(false, err);
        });
}




export function IsCustomerLogged(app_ctx) {
    //
    //console.log("---IsCustomerLogged: ", app_ctx)
    if (app_ctx.getAuth() && app_ctx.data.auth.sale_type_id === config.PROD_TYPE_CUSTOMER_ID ){
        return true;
    }
    return false;
}

export function IsStoreLogged(app_ctx) {
    //
    //console.log("---IsStoreLogged: ", app_ctx)
    if (app_ctx.getAuth() && app_ctx.data.auth.sale_type_id === config.PROD_TYPE_STORE_ID ){
        return true;
    }
    return false;
}


// current date time
export function  dT(){
    return new Date().getTime();
}

// dynamic url
export function dynUrl(qSign){
    return qSign+"d="+dT();
}


export function bodyClass(type, class_name){
    if (type==="add"){
        document.body.classList.add(class_name);
    } else if (type==="remove"){
        document.body.classList.remove(class_name);
    }
}


//
export function socketSend(soc, post_data, action_type){
    //
    if (soc && soc.socket){
        //
        let { socket } = soc;
        //
        var msg_data = {
            action: "message",
            action_type: action_type,
            data: post_data
        }
        //
        console.log("---sending for " + action_type + ": ", msg_data);
        socket.send(JSON.stringify(msg_data));
    }
}


//
export function validateRating(value){
    const rating = parseInt(value);
    if (isNaN(rating)) {
        return "La calificación debe ser un número";
    }
    if (rating < 1 || rating > 5) {
        return "La calificación debe estar entre 1 y 5";
    }
    return true;
}




export const DivAlert = ({ type, msg, icon, className, hideCloseBtn, timeout, onClose }) => {

    //
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    /*
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    * */

    return (
        <Alert variant={type} className={className || ''}>
            {icon ? icon : <span className="fas fa-info-circle"></span>}
            &nbsp; {msg}
            {!(hideCloseBtn) && (
                <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            )}
        </Alert>
    );
};


export function strEqual(str, value) {
    if ( (str && value) && (str === value) ){
        return true;
    }
    return false;
}

export function arrContains(arr, str_value){
    return (arr.indexOf(str_value) !== -1);
}

export function strContains(str, value) {
    if ( str && str.includes(value) ){
        return true;
    }
    return false;
}


export function scrollToTop(){
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // Opciones posibles: 'auto', 'smooth', 'instant'
    });
};


export function unfocusAll(){
    //
    document.activeElement.blur();
};



export const SubscriptionItem = ({item}) => {

    const getTotal = (item) => {
        //
        let el_discount;
        if ( item.lst_itm_discount_percent > 0 ){
            el_discount = (<li><strong>Discount %</strong> {item.lst_itm_discount_percent}: -{item.lst_itm_discount_amount} </li>)
        } else if ( item.lst_itm_discount_amount > 0 ){
            el_discount = (<li><strong>Discount -</strong> {item.lst_itm_discount_amount} </li>)
        }
        //
        let el_tax;
        if ( item.tax_amount > 0 ){
            el_tax = (<li><strong>Tax %</strong> {item.tax_percent}: {item.tax_amount} </li>)
        }
        //
        return (
            <ul>
                <li><strong>Subtotal: </strong>  {item.sub_total } </li>
                {el_discount}
                {/*{el_tax}*/}
                <li><strong>Total: </strong> {item.grand_total} </li>
            </ul>
        );
    }


    const getSaleItems = (item) => {
        //
        if (item.sale_items){
            return item.sale_items.map((the_item, idx) => {
                //
                let str_text_style = "text-info";
                if ( item.status_id === 1 ){
                    str_text_style = "text-success";
                }
                //
                return (<h5 key={idx} className={str_text_style}> Plan {the_item.item_info} {the_item.periodicidad} </h5>);
            });
        }
        return null;
    }


    //
    const getSalenotes = (item) => {
        //
        let str_notes = null;
        if (item.status_notes){
            str_notes = (<><br /><span style={{fontSize:'14px', color:'orangered', fontWeight: 'bold'}}> {item.status_notes} </span></>)
        }
        return (<>
            {item.status_title}
            {str_notes}
        </>)
    }



    const getPayments = (item) => {
        //
        let pmnts = null;
        //
        if (item.sale_payments) {
            pmnts = item.sale_payments.map((item, idx) => {
                //
                let str_text_style = "text-info";
                if ( item.payment_status_id === 1 ){
                    str_text_style = "text-success";
                }
                //
                return (
                    <li key={idx}>
                        <strong className={str_text_style}> {item.payment_type} </strong> {item.amount}
                        <small style={{color:"gray"}}> {item.tipo_moneda} ({item.payment_status}) </small>
                    </li>
                )
            });
        }
        //
        return(
            <ul>
                {pmnts}
            </ul>
        );
    }

    const getValidez = () => {
        //
        if ( item.status_id === 1 ){
            return (<h4 className={"badge badge-success"} style={{fontSize:16}}> Valida hasta: <strong>{fmtDateSpanish(item.lst_itm_datetime_expiry.date)}</strong> </h4>)
        } else if ( item.status_id === 5 ){
            return (<h4 className={"badge badge-secondary"}> Suscripcion Cancelada </h4>)
        } else {
            return (
                <>
                    <h4 className={"badge badge-warning text-light"}> {item.status_title.toUpperCase()} </h4>
                    <div className={"text-info"}> Valida hasta: <strong>{fmtDateSpanish(item.lst_itm_datetime_expiry.date)}</strong> </div>
                </>
            )
        }
    }

    //
    return (
        <li className="item" style={{padding: "10px", borderBottom:"1px solid #eee", minHeight:"100px", marginTop:"15px", width:"100%", backgroundColor:"#fff" }}>
            <div className="row">
                <div className="col-md-8 col-12">

                    <div style={{padding:10}}>
                        {getSaleItems(item)}

                        {(item.lst_itm_cant_busquedas) ? (<>
                            <div className={"mb-2 text-dark"}> {item.lst_itm_cant_busquedas} Busquedas Diarias </div>
                            <div className={"mb-2 text-dark"}> Busquedas Simultaneas </div>
                        </>) : null}

                        {getTotal(item)}
                        {getPayments(item)}
                        {/*<div> {getSalenotes(item)} </div>*/}
                        <small style={{color:"gray"}}> folio: {item.id} / {fmtDateSpanish(item.datetime_created.date, true)} </small>
                    </div>

                </div>
                <div className="col-md-4 col-12 text-center text-right">
                    {getValidez(item)}
                </div>
            </div>
        </li>
    )
}


export const SubscriptinListItem = ({item, tipo, btnPayment}) => {

    //
    var header_bg_color = "bg-white";
    var header_fa_trophy = "";
    var header_text_color = "text-danger";
    var header_text_color2 = "text-gray-900";
    var header_text_color3 = "text-secondary";
    var header_btn_style = "btn-outline-primary";
    var header_btn_style2 = "btn-outline-primary";
    //
    if ( item.id === 291 ){
        header_bg_color = "bg-primary";
        header_fa_trophy = (<span className='fa fa-trophy'></span>);
        header_text_color = "text-warning";
        header_text_color2 = "text-white";
        header_text_color3 = "text-white-50";
        header_btn_style = "btn-primary";
        header_btn_style2 = "btn-primary";
    }

    let str_cols = 'col-xl-4 col-lg-4 col-md-4 col-12 mb-4';
    if (tipo==="store"){
        str_cols = 'col-12 mb-4';
    }

    return(
        <div className={str_cols}>
            <div className='shadow-sm bg-white rounded'>
                <header className={'card-header ' + header_bg_color + ' border-bottom text-center py-2'}>
                    <h4 className={'h6 ' + header_text_color + ' mb-2'}> {header_fa_trophy} {ucFirst(item.nombre)} </h4>
                    <div className='d-block'>
                        <span className={'display-5 ' + header_text_color2 + ' font-weight-bold'}>

                            {(parseInt(item.precio) === 0 ) ? (
                                <>
                                    <span className='small'>GRATIS</span>
                                </>
                            ) : (
                                <>
                                    <span className='small'>$</span>{item.precio}
                                </>
                            )}

                        </span>
                        <span className={'d-block ' + header_text_color3}>
                            {(item.description) && <div dangerouslySetInnerHTML={{ __html: item.description }}></div>}
                            {(parseInt(item.precio) === 0 ) ? (<div>Plan Actual</div>) : null}
                        </span>
                    </div>
                </header>

                {(parseInt(item.precio) > 0 ) ? (
                    <div className='card-body p-4'>

                        <small className='text-primary' style={{display:"block"}}> Metodo de pago: </small>


                        <div className={"btn-group"}>

                            <button type="button" className={'btn btn-sm ' + header_btn_style} onClick={() => {btnPayment(item, "card_anet")}}
                                    tabIndex={0}><span className='fa fa-credit-card'></span> Tarjeta Authorize Net </button>

                            <button type="button" className={'btn btn-sm ' + header_btn_style} onClick={() => {btnPayment(item, "card_stripe")}}
                                    tabIndex={0}><span className='fa fa-credit-card'></span> Tarjeta Stripe </button>

                            <button type="button" style={{marginLeft: "10px"}} className={"btn btn-sm " + header_btn_style2} onClick={() => btnPayment(item, "oxxo")}
                                    tabIndex={1}><span className='fa fa-money-bill'></span> Oxxo </button><br />

                            <button type="button" style={{marginLeft: "10px"}} className={"btn btn-sm " + header_btn_style2} onClick={() => btnPayment(item, "transf")}
                                    tabIndex={2}><span className='fa fa-exchange-alt'></span> Transferencia/Efectivo </button>
                        </div>


                    </div>
                ) : null}



            </div>
        </div>
    )
}