import React, { useContext, useState, useRef, useEffect } from 'react';
import {withAppCtx} from "../../Helpers/withAppCtx";
import {DivAlert, getCAuth, IsCustomerLogged, isSeller, mDateFormat, post, postCAuth, readFileToSrc, socketSend, toFormData} from "../../Helpers/Helper";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {withNavigate} from "../../Helpers/withNavigate";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FrontPageVentasParser } from "../../Helpers/FormatParser";




const SaleDetails = ({ context, navigate, params, token, geo }) => {




    
    const [show_price, setShowPrice] = useState(true);

    const [selected_sale, setSelectedSale] = useState(null);



    //
    let auth = context.getAuth();
    let is_seller = isSeller(auth);



    const getVenta = (sale_id) => {


        //
        getCAuth(auth, `/sales/` + sale_id, function(success, response){
            if (success){
                //
                setSelectedSale(response);
            }
        });
    };




    
    //
    useEffect(() => {
        

        //console.log("params: ", params);

        getVenta(params.sale_id);

    }, []);

    
    

    const getSelectedSale = () => {
        //
        return (<div style={{border:"1px solid gray", padding:25, margin:25, borderRadius:10}}>
            {(selected_sale) ? (<>
                <div>{FrontPageVentasParser().parseCustomerData(selected_sale)}</div>
                <hr />
                <div>{FrontPageVentasParser().parseItems(selected_sale)}</div>
                <div>{FrontPageVentasParser().parseTotalAndPayments(selected_sale)}</div>
                <div>Fecha: {FrontPageVentasParser().parseDate(selected_sale)}</div>
                <hr />
                <div>Pagado: {FrontPageVentasParser().parsePaid(selected_sale)}</div>                
                {is_seller ? (<>
                    <hr />
                    <div>A Credito: {FrontPageVentasParser().parseCredito(selected_sale)}</div>
                    <hr />
                    Comision: {FrontPageVentasParser({display_type: "inline"}).parseComision(selected_sale)} <br />
                    Venta: {FrontPageVentasParser({display_type: "inline"}).parseVenta(selected_sale)}
                </>) : null}

                </>) : "Sale not found"}
        </div>)
    }
    

    //
    let company_name = "",
        ventas_title = "Mis Compras",
        ventas_text = "listado de compras realizadas";

    //
    if (is_seller){
        company_name = " - " + auth.company_name;
        ventas_title = "Mis Ventas";
        ventas_text = "listado de ventas realizadas";
    }
    //
    return (<div className="pt-4 pb-4">


        <div className="row">
            <div className="col-12 text-left">


                <h4 className={"mb-4"}><span className="fas fa-list"></span> {ventas_title} - {auth.first_name} {auth.last_name}{company_name} </h4>
            

                {getSelectedSale()}


            </div>
        </div>



    </div>)


}


export default withAuthCtx(withAppCtx(withNavigate(SaleDetails)));