import React, { useContext, useImperativeHandle, useState, useRef, useEffect } from 'react';
import {config} from "../Config";
import axios from "axios";
import {AppCtx} from "../AppCtx";
import {isMobile} from "react-device-detect";
import {IsCustomerLogged, IsStoreLogged} from "./Helper";
import {NavLink} from "react-router-dom";

//
const Notific = React.forwardRef(({}, ref) => {


    //
    const [is_loading, setLoading] = useState(true);
    const [arr_records, setArrRecords] = useState([]);
    const [qty_recs, setQuantytyRecords] = useState(6);
    const [next_recs, setNextRecs] = useState(1);
    const [page_num, setPageNumber] = useState(1);
    const [reached_end, setEndReached] = useState(false);


    //
    const sidePanelRef = useRef(null);
    //
    const app_ctx = useContext(AppCtx);



    //
    useImperativeHandle(ref, () => ({
        //
    }));








    useEffect(() => {
        // Función para obtener la nueva lista de elementos con axios.get
        const fetchData = async () => {
            try {

                //
                setLoading(true);


                //
                let options = {}

                //
                let { auth } = app_ctx.data;
                options.headers = {
                    'Authorization': auth.token,
                    'Utype': auth.sale_type_id
                }


                //
                let endpoint_url = null;
                //
                if ( IsStoreLogged(app_ctx) ){
                    endpoint_url = config.auth_store_url + "/n/s?s=" + next_recs + "&n=" + qty_recs;
                }
                //
                else if ( IsCustomerLogged(app_ctx) ){
                    endpoint_url = config.auth_cust_url + "/n/s?s=" + next_recs + "&n=" + qty_recs;
                }


                //
                options.timeout = 5000;
                const response = await axios.get(endpoint_url, options);



                //
                const newItems = response.data.data;
                if (newItems && newItems.length){
                    setArrRecords((prevItems) => [...prevItems, ...newItems]);
                    setNextRecs(response.data.next_start_record);
                } else {
                    setEndReached(true)
                }

                //
                setLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();

    }, [page_num]);





    const handleScroll = () => {
        //
        const sidePanel = sidePanelRef.current;
        console.log(sidePanel.scrollTop, sidePanel.clientHeight, sidePanel.scrollHeight)
        //
        let qty_offset = 5;
        let height_reached = (sidePanel.scrollTop + sidePanel.clientHeight) + qty_offset;
        //
        if ( height_reached >= sidePanel.scrollHeight && !is_loading && !reached_end) {
            console.log("has llegadl al fin");
            setPageNumber(page_num+1);
        }
    };


    return (
        <div className={"side-panel prev_close"} style={{width: (isMobile ? "100%" : "350px") }} ref={sidePanelRef} onScroll={handleScroll}>
            <ul className="notific-list">

                {arr_records.map((item, index) => (
                    <NavLink key={index} to={(item.dest_url) ? item.dest_url : "#!"} className={""}>
                        <li  className="notific">
                            <img src="https://via.placeholder.com/40" alt="User 1 Avatar" className="avatar" />
                            <div className="notific-content">
                                <span className="notific-text"> {item.message} </span>
                                <small className="notific-time">1 hour ago</small>
                            </div>
                        </li>

                    </NavLink>
                ))}

                {(is_loading) && (
                    <div className="py-2 text-success text-center"> loading records... </div>
                )}

                {(reached_end) && (
                    <div className="py-2 text-success text-center"> no more records </div>
                )}

            </ul>
        </div>
    )


    /*
    return (<>
        {(is_loading) ? (
            <BlockUi blocking={true} style={{width:"100%", height:"50%"}}>
                <div className="py-5 text-center" style={{width:"100%", height:"50%"}}></div>
            </BlockUi>
        ) : (

        )}
    </>)
     */



});



export default Notific;