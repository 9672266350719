import React, { useState, useEffect } from 'react';
import {config} from "../Config";
import {withAppCtx} from "../Helpers/withAppCtx";
import {useForm} from "react-hook-form";
import {NavLink, useNavigate} from 'react-router-dom';
import {postApi, getLogo, setContextData, toFormData} from "../Helpers/Helper";
import SelectLoader from "../Helpers/SelectLoader";
import BlockUi from 'react-block-ui';


const RecoverAccount = ({ context }) => {



    //
    const navigate = useNavigate();
    const {register, setValue, handleSubmit, formState: { errors }} = useForm();
    const [is_loading, setIsLoading] = useState(false);


    //
    useEffect(() => {


        //
        let lang = context.getLang();
        console.log("---lang: ", lang);
        console.log("---RecoverAccount Context: ", context);


        // Seccion que si esta auth redirecciona
        if (context.getAuth()){ navigate("/account");}

        

        // set defaults
        setValue('email', 'ivanjzr@gmail.com');
        setValue('request_identifier', context.visitorId);
        //
        document.title = config.AppName + " - Recuperar Cuenta";

    }, []);





    //
    const onSubmit = (data) => {
        //
        if (is_loading){
            console.log("is loading");
            return;
        }
        //
        setIsLoading(true);
        //
        postApi("/auth/req-ac", toFormData(data), function(success, data){
            //
            if (success){
                //
                let the_data = {
                    type: 'reco-custmr',
                    id: data.id,
                    contact_name: data.first_name + " " + data.last_name,
                    email: data.email,
                };
                //
                setContextData(context, 'reg', the_data);
                //
                navigate("/activate-account");
            }
            //
            setIsLoading(false);
        });
    };







    //
    return (<>


        <a className="login-back" href="/"><span className="mdi mdi-chevron-left"></span></a>

        <div className="text-center mt-5 mb-5 login-main-left-header pt-0 mr-0">
            <NavLink to="/"><img src={process.env.PUBLIC_URL + getLogo()} className="sect-logo"
                                 alt="LOGO" /></NavLink>
            <h5 className="mt-3 mb-3">Recuperacion de Cuenta</h5>
            <p>Introduce tu correo registrado y te enviaremos en mensaje con tu codigo de acceso</p>
        </div>

        <BlockUi blocking={is_loading} message={(<div style={{fontWeight:"bold", color:"green"}}> sending... </div>)}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" role="presentation">

                <div className="row">
                    <div className="col-12">
                        <div className="form-group">

                            
                            <label >Email</label>
                            <input type="email" className="form-control input-lg" name="email" id="email"
                                   placeholder="Correo Electronico"
                                   {...register('email', {
                                       required: 'introduce tu email',
                                       validate: {
                                           matchPattern: (v) =>
                                               /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                               "introduce un email valido",
                                       }
                                   })}
                            />
                            {errors.email && <label className="error">{errors.email.message}</label>}
                            

                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="row">
                        <div className="col-12 pl-2 text-end">

                            <button type="submit" className="btn btn-primary btn-block btn-lg">Enviar</button>

                        </div>
                    </div>
                </div>

                <div className="text-center mt-5">
                    <h5 className="light-gray">Ya cuentas con tus accesos? <NavLink to="/sign-in" style={{textDecoration:"none"}}>Ingresar Aqui <span
                        className="fas fa-arrow-circle-right"></span> </NavLink></h5>
                </div>
                
            </form>
        </BlockUi>

    </>)

}

//
export default withAppCtx(RecoverAccount);