import React, { useContext, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
    DivAlert,
    fmtDateSpanish,
    LoadingImg,
    postBAuth,
    postCAuth,
    setContextData, shortenString,
    socketSend
} from "../../../Helpers/Helper";
import {withAppCtx} from "../../../Helpers/withAppCtx";
import PageScroller from "../../../Helpers/PageScroller";
import {config} from "../../../Config";
import {SocketCtx} from "../../../SocketCtx";
import CustomModal from "../../Common/modals/CustomModal";
import ChatMessages from "../../Common/ChatMessages";
import {confirmAlert} from "react-confirm-alert";
import ClosePartRequest from "./ClosePartRequest";

//
const PartsRequestsResponses = ({ context, selected_item, onClose }) => {


    //
    const socketContext = useContext(SocketCtx);
    const pageScrollerRef = useRef();
    const elementRefs = useRef({});
    //
    const [modal_chat, setModalChat] = useState(false);
    const [modal_close_request, setModalCoseRequest] = useState(false);
    //
    const [selected_store_responses, setSelectedStoreResponses] = useState(false);



    //
    useEffect(() => {
        console.log("---modal customer sel item: ", selected_item);
    }, [selected_item]);





    //
    useEffect(() => {
        //console.log("---modal customer stores requests: ", socketContext.msg_data);


        //
        // Esto va a ser usable en futuras versiones por el momento solo el reload de abajo
        //

        /*
        //
        if ( socketContext.msg_data &&
            socketContext.msg_data.new_item &&
            ( socketContext.msg_data.action_type === "ap-new-response" )
        ){
            //
            if (pageScrollerRef.current) {
                pageScrollerRef.current.appendItem(socketContext.msg_data.new_item);
            }
        }
        //
        else if ( socketContext.msg_data &&
            socketContext.msg_data.updated_item &&
            ( socketContext.msg_data.action_type === "ap-updated-response" )
        ){
            //
            const updated_item = socketContext.msg_data.updated_item;
            //
            if (elementRefs.current[updated_item.id]) {
                //
                const root = ReactDOM.createRoot(document.getElementById(`li-${updated_item.id}`));
                root.render(parseItem(updated_item));
            }
        }
         */


        // Si tenemos send msg solo actualizamos
        if ( socketContext.msg_data){


            //
            if ( socketContext.msg_data.action_type === "ap-send-msg" ){
                resetScroller();
            } else if ( socketContext.msg_data.action_type === "ap-cancel-part-request-self" ){
                onClose(true);
            }

        }



        // remover mensajes el salir
        return () => {
            socketContext.resetMsgData();
        };

    }, [socketContext.msg_data]);






    //
    const resetScroller = () => {
        if (pageScrollerRef.current) { pageScrollerRef.current.resetItems();}
    };




    //
    const btnChat = (item) => {
        console.log("--Customer btnChat: ", item);


        // remover mensajes al abrir
        if ( socketContext.msg_data && socketContext.msg_data.action_type === "ap-send-msg" ){
            //console.log("****** CUSTOMER SI EXISTE ap-send-msg, eliminando: ", socketContext.msg_data);
            socketContext.resetMsgData();
        }

        //
        setSelectedStoreResponses(item);
        setModalChat(true);
    }




    const getBizLogo = (item) => {
        // Aqui se obtiene del llamado al api en php
        if (item.biz_logo){
            return (<img style={{width:25, borderRadius:20}} src={item.biz_logo} />)
        }

        // Aqui se obtiene del onMessage del apiGateway (solo con el img_ext)
        else if (item.store_img_ext){
            let store_biz_url = config.host_url + "/files/stores/" + item.store_id + "/profile/me." + item.store_img_ext;
            return (<img style={{width:25, borderRadius:20}} src={store_biz_url} />)
        }

        // si no tiene ninguno entonces mostramos las iniciales
        else {
            return (<div className="divRoundedSmall">{item.store_initials}</div>)
        }
    }


    //
    const parseResponseItem = (item) => {
        return (
            <button className='btn btn-sm btn-outline-success' onClick={() => {btnChat(item);}}>
                {getBizLogo(item)} {item.store_name} <span style={{}} className={"badge badge-success"}> {item.cant} </span>
            </button>
        )
    }


    //
    const btnCancelar = () => {

        //
        confirmAlert({
            message: 'Cancelar Solicitud #' + selected_item.id + "?",
            buttons: [
                {
                    label: 'Si',
                    onClick: function(){

                        //
                        let postData = {
                            id: selected_item.id
                        }
                        //
                        socketSend(socketContext, postData, "ap-cancel-part-request");
                        //
                        //onClose(true)

                    }
                },
                {
                    label: 'No',
                    onClick: function(){

                    }
                }
            ]
        });

    }



    //
    const btnCerrar = () => {
        setModalCoseRequest(true);
    }


    //
    const parseScrollContent = (item, index) => {
        //
        return (
            <li key={item.id}
                id={`li-${item.id}`}
                ref={element => elementRefs.current[item.id] = element}
                className="list-inline-item" style={{}}>

                {parseResponseItem(item)}

            </li>
        )
    }



    //
    return (<>


        <div className="row">
            <div className="col-sm-6 col-12">
                <div style={{margin:"0", padding:"0", fontWeight:"bold"}}>
                    Respuestas Solicitud #{selected_item.id}
                </div>
            </div>
            <div className="col-sm-6 col-12 text-right">


                <a href="#" className="btn btn-secondary" onClick={() => onClose(false)} style={{marginLeft:"10px"}}>
                    <span className="fa fa-times"></span>
                </a>

            </div>
        </div>

        <hr className="mt-2 mb-4"/>





        <div className="row">
            <div className="col-12 text-center">
                <h5 className={"text-primary"}> {selected_item.marca}/{selected_item.submarca}/{selected_item.modelo_anio}/{selected_item.cilindros} Cilindros </h5>
            </div>
            <div className="col-12">

                <div><span className={"fas fa-wrench"} style={{fontSize:"12px"}}></span> { shortenString(selected_item.parts_info)} </div>
                <small style={{color:"gray"}}> folio: {selected_item.id}, fecha: {fmtDateSpanish(selected_item.datetime_created.date, true)} </small>

            </div>
            <div className="col-12 text-center">

                <div className="mt-2 mb-4">
                    <button type="button" className="btn btn-sm btn-outline-warning mr-2" onClick={() => btnCancelar()}>
                        <span className="fa fa-times"></span> Cancelar
                    </button>
                    <button type="button" className="btn btn-sm btn-success" onClick={() => btnCerrar()}>
                        <span className="fa fa-check"></span> Cerrar Solicitud
                    </button>
                </div>

            </div>
        </div>



        { !(context.data && context.data.msg_2_dismissed) && (
            <DivAlert
                type={"info"}
                className={"mt-2 mb-4"}
                onClose={() => {
                    setContextData(context, "msg_2_dismissed", true)
                }}
                msg={"Si ya Obtuviste tus Piezas recuerda cerrar tu solicitud para poder hacer mas busquedas"}
            />
        )}


        <PageScroller
            ref={pageScrollerRef}
            listClassName={"list-inline"}
            containerPadding={10}
            zero_records_text={(
                <div style={{marginTop:25}}> <LoadingImg /> solicitud enviada, esperando respuestas...</div>
            )}
            num_recs={4}
            height={250}
            parseScrollContent={(item, index) => parseScrollContent(item, index)}
            url={config.auth_cust_url + "/parts-requests/" + selected_item.id + "/responses"}
        />



        <CustomModal
            modalSize={"modal-md"}
            showModal={modal_chat}
            content={<ChatMessages
                selected_item={selected_store_responses}
                onCloseSuccess={() => {
                    onClose(true);
                    setModalChat(false)
                }}
                onClose={() => {
                    setModalChat(false);
                }}
            />}
        />



        <CustomModal
            modalSize={"modal-md"}
            showModal={modal_close_request}
            content={<ClosePartRequest
                selected_item={selected_item}
                onCloseSuccess={() => {
                    onClose(true);
                    setModalCoseRequest(false)
                }}
                onClose={() => {
                    setModalCoseRequest(false);
                }}
            />}
        />




    </>)

}


export default withAppCtx(PartsRequestsResponses);