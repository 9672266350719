import React, { useCallback, useState, useRef, useEffect } from 'react';
import {NavLink} from "react-router-dom";
import {withGeoLocationCtx} from "../../../Helpers/withGeoLocationCtx";
import {withAuthCtx} from "../../../Helpers/withAuthCtx";
import {DivAlert, getLogo} from "../../../Helpers/Helper";

//
const ConfirmRequest = ({ token, request_data, onConfirm, geo }) => {




    //
    useEffect(() => {
        console.log("---modal confirmar solicitud: ", request_data);

    }, []);







    //
    return (<>
        <div style={{}} className="text-left p-3">


            <div style={{ display: 'flex', justifyContent: 'center',alignItems: 'center', margin: 0}} className={"text-center text-dark"}>
                <img style={{fontSize:18}} src={process.env.PUBLIC_URL + '/images/loc-marker2.gif'} className="place-srch" alt="searching..." />
                <div style={{}}>
                    Se Buscara en Yonkes y tiendas de AutoPartes cercanos en <strong>{geo.city}</strong>
                </div>
            </div>

            <hr />


            {(token.userData && !token.userData.has_valid_subs) ? (
                <DivAlert
                    type={"info"}
                    className={"mt-4 mb-4"}
                    icon={(<span className={"fas fa-gift"}></span>)}
                    hideCloseBtn={true}
                    msg={(<span>
                         Incrementa tu Radio de Busqueda y envia Mensaje de Texto a todos los Lugares <NavLink className={"btn btn-sm btn-primary"} to="/account/subscribe"> Suscribete Aqui <span className={"fas fa-arrow-right"}></span></NavLink>
                    </span>)}
                />
            ) : null}



            <div className={"mt-4"}><strong>Buscando:</strong></div>
            <div> {request_data.parts_info} </div>

            <div className={"mt-2"}><strong>Para Vehiculo:</strong></div>
            <div> {request_data.sel_marca} / {request_data.sel_submarca} / {request_data.sel_modelo} / {request_data.sel_cilindraje} </div>


            <div className={"mt-4 mb-4 text-center"}>


                {/*AQUI VA EL MAPA*/}


                <div className={"mt-4 mb-3"}>
                    <button type="button" className="btn btn-success" onClick={onConfirm}><span className={"fas fa-check-circle"}></span> Enviar Solicitud </button>
                </div>


            </div>




        </div>

    </>)

}


export default withGeoLocationCtx(withAuthCtx(ConfirmRequest));