import React from 'react';
import { AppCtx } from '../AppCtx';

export const withAppCtx = (Component) => {
    return class extends React.Component {
        render() {
            return (
                <AppCtx.Consumer>
                    {(contextData) => <Component {...this.props} context={contextData} />}
                </AppCtx.Consumer>
            );
        }
    };
};
