import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

//
const PoliticaDePrivacidad = ({}) => {



    //
    return (<>

        <p>En YonkeParts, nos preocupamos por tu privacidad. Aquí te proporcionamos información clave sobre cómo gestionamos tus datos personales:</p>

        <ol>
            <li><strong>Datos Básicos:</strong> Solo mostramos tu nombre y foto de perfil con los negocios de autopartes en nuestra plataforma.</li>
            <li><strong>Elección del Usuario:</strong> Tienes el control total sobre qué información adicional decides compartir con los negocios. No lo hacemos por ti.</li>
            <li><strong>Uso Limitado de Datos:</strong> Tus datos son utilizados exclusivamente para tu acceso y autenticación en la plataforma. No realizamos análisis de tus datos ni los compartimos con terceros.</li>
            <li><strong>Seguridad:</strong> Mantenemos medidas de seguridad para proteger tus datos y garantizar su confidencialidad.</li>
            <li><strong>Consentimiento:</strong> Obtendremos tu consentimiento antes de recopilar cualquier información adicional.</li>
            <li><strong>Derechos del Usuario:</strong> Tienes derecho a acceder, corregir o eliminar tus datos personales en nuestra plataforma en cualquier momento.</li>
            <li><strong>Almacenamiento Seguro:</strong> Tus datos se almacenan de manera segura y solo el personal autorizado tiene acceso a ellos.</li>
            <li><strong>Contacto:</strong> Si tienes preguntas o preocupaciones sobre tu privacidad, no dudes en contactarnos a través de nuestro correo electronico <strong>Info@YonkeParts.com</strong></li>
        </ol>

        <p>Agradecemos tu confianza en YonkeParts y estamos comprometidos a proteger tu privacidad en todo momento.</p>

    </>)

}


export default PoliticaDePrivacidad;