import React from 'react';
import { AuthCtx } from '../AuthCtx';

export const withAuthCtx = (Component) => {
    return class extends React.Component {
        render() {
            return (
                <AuthCtx.Consumer>
                    {(contextData) => <Component {...this.props} token={contextData} />}
                </AuthCtx.Consumer>
            );
        }
    };
};
