import React, { useContext, useState, useRef, useEffect } from 'react';
import {withAppCtx} from "../../Helpers/withAppCtx";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import {withGeoLocationCtx} from "../../Helpers/withGeoLocationCtx";
import {NavLink, useLocation} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {delContextData, getCAuth, getComisionInfo, isSeller, post} from "../../Helpers/Helper";
import {NotificationManager} from "react-notifications";
import {config} from "../../Config";



//
const Settings = ({ context, token, geo }) => {




    const [customer_data, setCustomerData] = useState(null);





     //
     const getCustomerData = () => {
        //
        getCAuth(context.data.auth, "",  function(success, data){
            console.log("---customer data: ", data);
            if (success){
                //
                setCustomerData(data);
            }
        });
    }

     


     //
     useEffect(() => {

        //
        getCustomerData();

    }, []);





   


    //
    const doPostLogout = () => {
        //
        post(context.data.auth, "/auth/logout", {}, null, function(success, data){
            console.log("---post logout res: ", data);
            //
            delContextData(context, 'auth');
            // Aqui es necesario el href para actualizar la app y que se redefinian los routes en app.js
            window.location.href = "/"
        });
    }



    //
    const doLogout = () => {
        //
        confirmAlert({
            message: 'Deseas cerrar sesion?',
            buttons: [
                {
                    label: 'Si',
                    onClick: function(){
                        //
                        doPostLogout();
                    }
                },
                {
                    label: 'No',
                    onClick: function(){

                    }
                }
            ]
        });
    };



    //
    let {auth} = context.data;
    //
    if (auth && auth.id){

        //
        let is_seller = isSeller(auth);
        //
        let customer_seller = "",
            company_name = "",
            ventas_title = "Mis Compras",
            ventas_text = "listado de compras realizadas";

        //
        if (is_seller){
            company_name = " - " + auth.company_name;
            customer_seller = "Vendedor - ";
            ventas_title = "Mis Ventas";
            ventas_text = "listado de ventas realizadas";
        }
        
        //
        return (<div className="pt-1 pb-4">

                <h4>{customer_seller}{auth.first_name} {auth.last_name}{company_name}</h4>
                <small> Registered in <i>{auth.acct_app_name}</i> on <i>{auth.fecha_creado}</i></small>
                <hr />
                {(customer_data && customer_data.id) ? getComisionInfo(customer_data) : null}

                

                <div className="list-group mt-5">
                    
                    <NavLink to="/" className="list-group-item list-group-item-action">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1"><span className={"fas fa-ticket"}></span> Nuevo Ticket </h5>
                            <span className={"fas fa-arrow-right"}></span>                            
                        </div>
                        <small className="mb-1"> Reserva nuevo boleto </small>
                    </NavLink>

                    <NavLink 
                        to="/account" 
                        className={({ isActive }) => isActive ? "list-group-item list-group-item-action" : "list-group-item list-group-item-action"}
                        >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1"><span className={"fas fa-list"}></span> {ventas_title} </h5>
                            <span className={"fas fa-arrow-right"}></span>
                        </div>
                        <small className="mb-1">{ventas_text}</small>
                    </NavLink>

                    <NavLink to="/account/profile" className="list-group-item list-group-item-action">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1"><span className={"fas fa-user"}></span> Perfil </h5>
                            <span className={"fas fa-arrow-right"}></span>
                        </div>
                        <small className="mb-1">Actualiza tu nombre, correo e imagen de perfil</small>
                    </NavLink>
                    
                    <NavLink to="/account/credentials" className="list-group-item list-group-item-action">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1"><span className={"fas fa-unlock"}></span> Credenciales </h5>
                            <span className={"fas fa-arrow-right"}></span>
                        </div>
                        <small className="mb-1">Actualiza tus credenciales de acceso</small>
                    </NavLink>

                </div>

                <div className="list-group mt-2" onClick={() => doLogout()}>
                    <button className="list-group-item list-group-item-action list-group-item-warning">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1"><span className={"fas fa-sign-out"}></span> Cerrar Sesion </h5>
                            <span className={"fas fa-arrow-right"}></span>
                        </div>
                    </button>
                </div>


        </div>)

    } else {
        return(<div style={{marginTop:"25%", textAlign:"center"}}><img style={{width:25}} src={process.env.PUBLIC_URL + '/images/loading.gif'} /> cerrando sesion...</div>)
    }


}




//
export default withAppCtx(withAuthCtx(withGeoLocationCtx(Settings)));