import React, { useContext, useState, useRef, useEffect } from 'react';
import {config} from "../../Config";
import {withAppCtx} from "../../Helpers/withAppCtx";
import {withAuthCtx} from "../../Helpers/withAuthCtx";
import CustomModal from "../Common/modals/CustomModal";
import PageScroller from "../../Helpers/PageScroller";
import {withGeoLocationCtx} from "../../Helpers/withGeoLocationCtx";
import {SocketCtx} from "../../SocketCtx";
import PartsRequestsResponses from "./Forms/PartsRequestsResponses";
import {CustomerRequestItem} from "../../Helpers/APHelper";
import {NavLink, useLocation} from "react-router-dom";
import {fmtDateSpanish, getCAuth, resetUrl, setContextData} from "../../Helpers/Helper";
import {NotificationManager} from "react-notifications";
import ChatMessages from "../Common/ChatMessages";
import BlockUi from "react-block-ui";
import {Form} from "react-bootstrap";


//
const MisBusquedas = ({ context, token, geo }) => {



    //
    const socketContext = useContext(SocketCtx);
    const location = useLocation();
    const pageScrollerRef = useRef();
    //
    const [loading_request, setLoadingRequest] = useState(false);
    //
    const [modal_respuestas, setModalRespuestas] = useState(false);
    const [selected_item, setSelectedItem] = useState(false);
    //
    const [modal_chat, setModalChat] = useState(false);
    const [selected_store_responses, setSelectedStoreResponses] = useState(false);



    //
    let filtr_type = (context.data && context.data.customer_requests_filter_type) ? context.data.customer_requests_filter_type : "new";
    const [filter_type, setFilterType] = useState(filtr_type);




    //
    useEffect(() => {

        //
        const queryParams = new URLSearchParams(location.search);
        const qs_rid = queryParams.get('rid');
        //
        if (qs_rid){
            //
            loadPartRequestInfo(qs_rid);
        }

        //
        document.title = config.AppName + " - Mis Solicitudes";
        //console.log("------------CustomerIndex: ", qs_rid);

        //
        token.tokenUserData();

    }, []);






    //
    useEffect(() => {
        //console.log("---message received for Customer MyPartsRequests: ", socketContext.msg_data);
        //
        if ( socketContext.msg_data && socketContext.msg_data.new_item ){
            //
            if ( socketContext.msg_data.action_type === "ap-send-msg" ){
                reloadPager();
            }
        }

        //
        return () => {
            //socketContext.resetMsgData();
        };

    }, [socketContext.msg_data]);



    //
    const reloadPager = () => {
        if (pageScrollerRef.current) { pageScrollerRef.current.resetItems();}
    };

    const setQueryString = (query_string) => {
        if (pageScrollerRef.current) { pageScrollerRef.current.setQueryString(query_string);}
    }


    //
    const btnReload = () => {
        reloadPager();
    }





    const handleChangeFilterType = (event) => {
        //console.log(event.target);
        let self = this;
        let evt_target = event.target;
        let filter_type = evt_target.value;

        //
        setContextData(context, "customer_requests_filter_type", filter_type);

        //
        setFilterType(filter_type);

        // filtramos solo si no es todos
        if ( filter_type !== "all" ){
            setQueryString("&t="+filter_type);
        } else {
            setQueryString("");
        }

        //
        reloadPager();
    }




    const loadPartRequestInfo = (id) => {

        //
        if (loading_request){
            console.log("request is loading..");
            return;
        }

        //
        setLoadingRequest(true);

        //
        getCAuth(context.data.auth, "/parts-requests/" + id,  function(success, data){
            console.log("---get part request id " + id  + ":", data);
            if (success){
                //
                resetUrl();
                //
                doTaskAccordingly(data)

            }
            //
            setLoadingRequest(false);
        }, "de Solicitud #" + id);
    }


    const loadPartRequestResponseInfo = (data) => {

        //
        if (loading_request){
            console.log("request is loading..");
            return;
        }

        //
        setLoadingRequest(true);

        //
        getCAuth(context.data.auth, "/parts-requests/" + data.id + "/responses/" + data.deal_with_store_id,  function(success, data){
            console.log("---get part request id " + data.id  + " for store " + data.deal_with_store_id + ": ", data);
            if (success){
                //
                resetUrl();
                //
                setSelectedStoreResponses(data);
                setModalChat(true);
            }

            //
            setLoadingRequest(false);

        }, "de Solicitud #" + data.id);
    }




    //
    const doTaskAccordingly = (data) => {
        console.log("-----------doTaskAccordingly: ", data);
        //
        if (data.cancel_datetime){
            NotificationManager.info('Solicitud ya fue cancelada');
        }
        //
        else if (data.deal_datetime){
            //
            if (data.deal_with_store_id){
                //
                loadPartRequestResponseInfo(data);
            } else {
                NotificationManager.info('Solicitud ya fue cerrada');
            }
        }
        //
        else {
            //
            setSelectedItem(data);
            setModalRespuestas(true);
        }
    }




    //
    return (<div className="pt-2 pb-4">



        <div className="row mt-2 mb-4">
            <div className="col-md-3 col-12">
                <h5><span className="fa fa-car"></span> Mis Solicitudes </h5>
            </div>
            <div className="col-md-7 col-12">
                <span>Mostrar: </span> &emsp;

                <Form.Check
                    type="radio"
                    id="filter_type_new"
                    label="Nuevas"
                    name="filter_type"
                    value="new"
                    inline
                    style={{ marginRight: '10px' }}
                    checked={filter_type === 'new'}
                    onChange={handleChangeFilterType}
                />
                <Form.Check
                    type="radio"
                    id="filter_type_all"
                    label="Todas"
                    name="filter_type"
                    value="all"
                    inline
                    style={{ marginRight: '10px' }}
                    checked={filter_type === 'all'}
                    onChange={handleChangeFilterType}
                />
                <Form.Check
                    type="radio"
                    id="filter_type_others"
                    label="Cerradas"
                    name="filter_type"
                    value="others"
                    inline
                    style={{ marginRight: '10px' }}
                    checked={filter_type === 'others'}
                    onChange={handleChangeFilterType}
                />

            </div>
            <div className="col-md-2 col-12 text-right">

                <button type="button" className="btn btn-outline-info" onClick={btnReload}><i
                    className="fas fa-sync"></i></button>

            </div>
        </div>



        <BlockUi blocking={loading_request}>

            <div className={"mt-2"}>
                <PageScroller
                    ref={pageScrollerRef}
                    num_recs={4}
                    height={400}
                    initialQueryString={(filter_type === "all") ? "" : "&t="+filter_type}
                    // prevent_first_load={true}
                    listClassName={"clear-list"}
                    zero_records_text={(
                        <>
                            <div className={"mt-4 mb-2"}> Aun no tienes solicitudes de partes </div>
                            <NavLink to="/account" className="btn btn-sm btn-outline-primary"><span className={"fas fa-rocket"}></span> Nueva Busqueda </NavLink>
                        </>
                    )}
                    parseScrollContent={(item, index) => (
                        <CustomerRequestItem
                            key={index}
                            item={item}
                            onSelect={() => loadPartRequestInfo(item.id)}
                        />
                    )}
                    url={config.auth_cust_url + "/parts-requests"}
                />
            </div>
        </BlockUi>










        {/* ---------------- MODAL DE RESPUESTAS ---------------- */}
        <CustomModal
            modalSize={"modal-lg"}
            showModal={modal_respuestas}
            content={<PartsRequestsResponses
                selected_item={selected_item}
                onSubmit={() => {
                    setModalRespuestas(false)
                }}
                onClose={(do_reload) => {
                    if (do_reload){
                        reloadPager();
                    }
                    setModalRespuestas(false);
                }}
            />}
        />



        {/* ---------------- MODAL DE CHAT ---------------- */}
        <CustomModal
            modalSize={"modal-md"}
            showModal={modal_chat}
            content={<ChatMessages
                selected_item={selected_store_responses}
                onCloseSuccess={(do_reload) => {
                    if (do_reload){
                        reloadPager();
                    }
                    setModalChat(false)
                }}
                onClose={() => {
                    setModalChat(false);
                }}
            />}
        />




    </div>)

}




//
export default withAppCtx(withAuthCtx(withGeoLocationCtx(MisBusquedas)));