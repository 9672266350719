import React, { useContext, useState, useRef, useEffect } from 'react';
import {DivAlert, fmtDateSpanish, postBAuth, postCAuth, setContextData, socketSend} from "../../../Helpers/Helper";
import {withAppCtx} from "../../../Helpers/withAppCtx";
import PageScroller from "../../../Helpers/PageScroller";
import {config} from "../../../Config";
import {SocketCtx} from "../../../SocketCtx";
import {confirmAlert} from "react-confirm-alert";

//
const ClosePartRequest = ({ context, selected_item, onCloseSuccess, onClose }) => {


    //
    const socketContext = useContext(SocketCtx);
    const pageScrollerRef = useRef();
    const elementRefs = useRef({});
    //
    const [modal_respuestas, setModalRespuestas] = useState(false);
    const [modal_close_request, setModalCoseRequest] = useState(false);
    //
    const [selected_store_responses, setSelectedStoreResponses] = useState(false);



    //
    useEffect(() => {
        console.log("---modal customer sel item: ", selected_item);


        //
        if (pageScrollerRef.current) {
            setTimeout(function(){
                pageScrollerRef.current.appendItem({
                    id: 0,
                    store_name: "Lo Adquiri en otro lugar"
                });
            }, 250)
        }


    }, [selected_item]);




    const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);






    //
    useEffect(() => {
        console.log("---message received ClosePartRequest: ", socketContext.msg_data);

        //
        if ( socketContext.msg_data && socketContext.msg_data.action_type && socketContext.msg_data.action_type === 'ap-update-part-request-self' ){
            onCloseSuccess();
        }

        /*
        return () => {
            socketContext.resetMsgData();
        };
         */

    }, [socketContext.msg_data]);





    const handleSelectItem = (item, index) => {
        console.log(item, index);

        //
        setSelectedButtonIndex(index);

        //
        confirmAlert({
            message: (<>
                <div className={"font-weight-bolder"}>
                    {(item.id > 0 ) ? (<>
                        Adquiriste tus productos con {item.store_name}?
                    </>) : (<>
                        Adquiriste tus productos en otro lugar?
                    </>)}
                </div>
                <small className={"text-primary"}>Al cerrar esta solicitud solo podras ver el historial de mensajes.</small>
            </>),
            buttons: [
                {
                    label: 'Si',
                    onClick: function(){

                        //
                        let postData = {
                            part_request_id: selected_item.id,
                            store_part_request_id: item.id,
                            update_type: 'close'
                        }
                        //
                        if (item.store_id){
                            postData.store_id = item.store_id;
                        }
                        //
                        socketSend(socketContext, postData, "ap-update-part-request");
                    }
                },
                {
                    label: 'No',
                    onClick: function(){

                    }
                }
            ]
        });
    };





    //
    const parseScrollContent = (item, index) => {
        //
        return (
            <button
                key={item.id}
                id={`b-${item.id}`}
                style={{}}
                ref={element => elementRefs.current[item.id] = element}
                className={`mr-2 mt-2 btn btn-sm ${selectedButtonIndex === index ? 'btn-success' : 'btn-outline-primary'}`}
                onClick={() => handleSelectItem(item, index)}> {item.store_name} </button>
        )
    }



    //
    return (<>


        <div className="row">
            <div className="col-sm-6 col-12">
                <div style={{margin:"0", padding:"0", fontWeight:"bold"}}>
                    Solicitud #{selected_item.id}
                </div>
            </div>
            <div className="col-sm-6 col-12 text-right">


                <a href="#" className="btn btn-secondary" onClick={() => onClose()} style={{marginLeft:"10px"}}>
                    <span className="fa fa-times"></span>
                </a>

            </div>
        </div>

        <hr className="mt-2 mb-4"/>



        <h4 className={"mb-4"}>
            Selecciona con quien hiciste el trato
        </h4>


        <PageScroller
            ref={pageScrollerRef}
            listClassName={"list-inline"}
            containerPadding={10}
            num_recs={4}
            height={250}
            parseScrollContent={(item, index) => parseScrollContent(item, index)}
            url={config.auth_cust_url + "/parts-requests/" + selected_item.id + "/responses"}
        />



    </>)

}


export default withAppCtx(ClosePartRequest);