import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    fmtAmount,
    ucFirst,
    SqrGenUUID,
    allowCredit,
    toFormData,
    postPublic,
} from "../../Helpers/Helper";
import {NotificationManager} from "react-notifications";
import InputMask from 'react-input-mask';
import {withAppCtx} from "../../Helpers/withAppCtx";
import {config} from "../../Config";
import BlockUi from "react-block-ui";
import SelectLoader from "../../Helpers/SelectLoader";
import {useNavigate} from "react-router-dom";
import {
    ApplePay,
    GooglePay,
    CreditCard,
    PaymentForm,
} from "react-square-web-payments-sdk";

import {Form} from "react-bootstrap";





//
const PayCardSquare = ({ context, customer_data, squareConfig, arr_items, onClose, onPaymentSuccess }) => {

    //
    const [loading, setLoading] = useState(false);
    //
    const {register, getValues, setValues, handleSubmit, setValue, setFocus, trigger, formState: { errors }, clearErrors } = useForm();
    const [phone_country_id, setPhoneCountryId] = useState('');
    const navigate = useNavigate();
    const [token_data, setTokenData] = useState(null);
    const [metodo_pago, setMetodoPago] = useState(config.SQR_CARD);
    const cardNumberRef = useRef(null);






    //
    useEffect(() => {

        //console.log("---modal pay card square");
        //console.log(squareConfig);
        //console.log("---arr_items: ", arr_items);

        let lang = context.getLang();

        //
        setFocus("cardholderName");
        setPhoneCountryId(lang.lang_country_id);

        //
        setValue("cardholderName", "ivan o juarez");
        setValue("phone_number", "6567923954");
        setValue("email", "ivanjzr@gmail.com");
        

    }, []);


   
    


     //
     // 41111111111111111111
     //
     const handlePayment = async (post_data) => {
        //console.log("---handle square payment: ", post_data);

        
        // items
        post_data.arr_items = arr_items;
        
        // info pago tarjeta
        if (metodo_pago === config.SQR_CARD){

            //            
            post_data.type = config.SQR_CARD;
            //
            if (token_data && token_data.details){                
                post_data.payment_details = token_data.details;
                post_data.location_id = squareConfig.locationId;
                post_data.idempotency_key = SqrGenUUID();
            } else {
                //
                NotificationManager.info("se requiere el token para continuar");
                return false;
            }

        }
        // info pago credito
        else if (metodo_pago === config.CREDIT){
            post_data.type = config.CREDIT;
        }        
        


        //
        let post_options = {};
        const auth = context.getAuth()
        if (auth){ post_options.auth = auth; }

        
        //
        setLoading(true);

        //
        // MissionExpress = 11, Plabuz = 13, Tickets4Buses = 16
        //
        postPublic("/pay/", post_data, function(success, data){
            //console.log("---pay card post results: ", data);
            //
            if (success){
                
                //
                onPaymentSuccess();
            }
            //
            setTimeout(function(){
                setLoading(false);
            }, 500);

        }, post_options);

    };


    
    //
    useEffect(() => {

        console.log("---token_data updated, calling handleSubmit");
        if (token_data){
            handleSubmit(handlePayment)();
        }

    }, [token_data]);




    //
    const cardTokenizeResponseReceived = async (token_data, verifiedBuyer) => {
        //console.log("***square results: ", token_data);    
        setTokenData(token_data);
        setValue("token_id", token_data.token);
    }



    //
    const handleSelectChange = (event) => {
        //console.log("---onChange: ", event.target.value);
        setPhoneCountryId(event.target.value);
    };



    const handleChangeFilterType = (event) => {
        //console.log(event.target);
        setMetodoPago(event.target.value);
    }


    


    const onError = (errors, e) => {
        console.log(errors);

        if (errors && errors.token_id && errors.token_id.message){
            NotificationManager.info("Introduce los datos de pago y presiona pagar");
        }
    };




    const getSquarePaymentType = () => {
        return (<>

            {errors.token_id && <label className="my-4 error">{errors.token_id.message}</label>}

            <div className="mt-0">
                <PaymentForm
                    applicationId={squareConfig.applicationId}
                    cardTokenizeResponseReceived={cardTokenizeResponseReceived}
                    locationId={squareConfig.locationId}
                >
                    <CreditCard
                        ref={cardNumberRef}
                        buttonProps={{
                            css: {
                            backgroundColor: "#771520",
                            fontSize: "14px",
                            color: "#fff",
                            "&:hover": {
                                backgroundColor: "#530f16",
                            },
                            },
                        }}
                        />

                </PaymentForm>
            </div>
        
        </>)
    }


    /**
     * 
     * Establece el metodo de pago solo si el customer esta auth 
     * y ademas tiene permitido el credito
     * en caso contrario no muestra nada y el metodo por default es card
     * @returns 
     * 
     */
    const getMetodoPago = () => {
        //
        if (allowCredit(customer_data)){
            //
            var company_name = (customer_data.company_name) ? customer_data.company_name + " - " : "";
            //
            return (<>
                <div className="row mt-2 mb-4">                    
                    <div className="col-12 text-center">
                        
                        <Form.Check
                            type="radio"
                            label="Credito"
                            id="metodo_pago_credit"
                            name="metodo_pago"
                            value={config.CREDIT}
                            inline
                            style={{  }}
                            checked={metodo_pago === config.CREDIT}
                            onChange={handleChangeFilterType}
                        />
                        <Form.Check
                            type="radio"
                            label="Tarjeta"
                            id="metodo_pago_card"                            
                            name="metodo_pago"
                            value={config.SQR_CARD}
                            inline
                            style={{ marginLeft: 50 }}
                            checked={metodo_pago === config.SQR_CARD}
                            onChange={handleChangeFilterType}
                        />

                    </div>
                </div>

                {(metodo_pago === config.SQR_CARD) ? getSquarePaymentType() : (<div className='text-center'> Credito a nombre de: {company_name} {customer_data.first_name} </div>)}

            </>)
        } 
        
        //
        return getSquarePaymentType();
    };




    //
    return (
        <BlockUi blocking={loading}>            
            <div className="p-2">


               
             
                <form onSubmit={handleSubmit(handlePayment, onError)}>

                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                                <label htmlFor="cardholder-name">Nombre del titular de la tarjeta</label>
                                <input type="text" className="form-control"
                                    id="cardholderName"
                                    name="cardholderName"
                                    placeholder="Nombre del titular de la tarjeta"
                                    {...register('cardholderName', {
                                        required: "Se requiere el nombre del titular"
                                    })}
                                />
                                {errors.cardholderName && <label className="error">{errors.cardholderName.message}</label>}
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                                <label >Email</label>
                                <input type="email" className="form-control input-lg" name="email" id="email"
                                        placeholder="Correo Electronico"
                                        {...register('email', {
                                            required: 'introduce tu email',
                                            validate: {
                                                matchPattern: (v) =>
                                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                    "introduce un email valido",
                                            }
                                        })}
                                />
                                {errors.email && <label className="error">{errors.email.message}</label>}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-sm-5 col-md-5">
                            <div className="form-group">

                                <label className="">Codigo Pais</label>
                                <SelectLoader
                                    url={config.api_url +"/public/paises/list"}
                                    name_id={"phone_country_id"}
                                    empty_value={"--selecciona"}
                                    value={phone_country_id}
                                    onChange={handleSelectChange}
                                    parse={(item) => "+" + item.phone_cc + " (" + item.abreviado + ")"}
                                    register={{...register("phone_country_id", {
                                            required: 'selecciona el pais',
                                            onChange: handleSelectChange
                                        })}}
                                />
                                {errors.phone_country_id && <label className="error">{errors.phone_country_id.message}</label>}


                            </div>
                        </div>
                        <div className="col-sm-7 col-md-7">
                            <div className="form-group">
                                <label className="">Telefono</label>
                                <input type="number" className="form-control no-spinners" name="phone_number" id="phone_number"
                                        data-rule-required="true" data-rule-number="true" data-rule-minlength="10"
                                        data-rule-maxlength="12" placeholder="Telefono" autoComplete="off"
                                        {...register('phone_number', { required: 'introduce tu telefono' })}
                                />
                                {errors.phone_number && <label className="error">{errors.phone_number.message}</label>}
                            </div>
                        </div>
                    </div>


                    {(metodo_pago === config.SQR_CARD) ? (<>
                        <input type="hidden" name="token_id" id="token_id" {...register('token_id', { required: 'Se requiere llenar datos de pago' })} />
                    </>) : null}


                    <div className="mt-4 text-end" style={{visibility: (metodo_pago===config.SQR_CARD) ? "hidden" : "visible" }}>
                        <button type="submit" className="btn btn-primary"><span className={"fas fa-check"}></span> Continuar </button>
                    </div>


                </form>


                {getMetodoPago()}

                

            </div>


        </BlockUi>
    )

}


export default withAppCtx(PayCardSquare);